import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer, FormControlLabel, Checkbox, } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LabelValueCard, RevisionHistory, PageLoader } from '../../../../shared_elements';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { capitalizeFirstLetter, checkApiStatus, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { addEditReturnConApi, getReturnConApi } from '../apiServices';
import { fieldValidation } from '../../../../utils_v2/formValidation';
import { errorCodeReturnCondition, addReturnCondition } from '../../';
class ReturnCondCRU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      formSubmitLoader: false,
      pageLoader: false,
      mode: '',
      returnCond: {},
      error: {}
    }
    this.addEditReturnConApi = addEditReturnConApi.bind(this);
    this.getReturnConApi = getReturnConApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      returnCond: {
        ...prevState.returnCond,
        [keyParam]: data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  addEditReturnFn = () => {
    const { returnCond } = this.state;
    let validationInputs = {
      asset_conditions_for_contract: errorCodeReturnCondition['asset_conditions_for_contract'][fieldValidation({ ...errorCodeReturnCondition['asset_conditions_for_contractObj'], fieldval: returnCond.asset_conditions_for_contract && returnCond.asset_conditions_for_contract.id ? returnCond.asset_conditions_for_contract.id.toString() : '' })],
      terms: errorCodeReturnCondition['terms'][fieldValidation({ ...errorCodeReturnCondition['termsObj'], fieldval: returnCond.terms })],
    };
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      this.setState({ formSubmitLoader: true });
      let payload = Object.assign({}, returnCond);

      payload = {
        ...payload,
        asset_conditions_for_contract_id: returnCond.asset_conditions_for_contract.id
      }
      this.addEditReturnConApi(this.props, payload)
        .then(response => {
          if (checkApiStatus(response)) {
            this.setState({ modal: false, mode: '', returnCond: {}, error: {} });
            this.props.getResponseBack();
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        })
    } else {
      this.setState({ error: validationInputs });
    }
  }
  render() {
    const { modal, formSubmitLoader, mode, returnCond, error, pageLoader } = this.state;
    const { id, m, contractClauseList, contractInfo } = this.props;
    return (
      <Fragment>
        {pageLoader ? <PageLoader /> : null}
        {id ?
          <span onClick={() => this.getReturnConApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset ?
          null:
          <Button onClick={() => this.setState({ modal: true, returnCond: addReturnCondition, mode: 'add' })} variant="contained" color="primary"><AddIcon fontSize='small' />Add Return Condition</Button>
        }
        {modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({ modal: false, mode: '', returnCond: {}, error: {} })}
          >
            <div style={{ width: '670px' }} className="drawer-container">
              <div className="drawer-header" alignItems='center'>
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Return Condition`}</span>
                <CloseIcon className="close-icon" onClick={() => this.setState({ modal: false })} />
                {mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctreturnconditionitems/'
                        queryParams={{ action: 1, object_id: id }}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      { contractInfo?.hdlineInfo?.archived_asset == false && checkPermission('contracts','return_conditions', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({ mode: 'edit' })}><EditIcon className="edit-icon" />Edit</span>}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({ mode: 'view' })}><VisibilityIcon className="view-icon" />View</span>}
                    </li>
                  </ul> : null
                }
              </div>
              <div className="drawer-body">
                <Grid container spacing={2}>
                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Clause' value={returnCond.asset_conditions_for_contract && returnCond.asset_conditions_for_contract.name ? returnCond.asset_conditions_for_contract.name : '--'} /> :
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        options={contractClauseList}
                        getOptionLabel={option => option.name}
                        value={returnCond.asset_conditions_for_contract ? returnCond.asset_conditions_for_contract : null}
                        id="asset_conditions_for_contract"
                        onChange={(e, value) => { this.onFieldChange(e, 'asset_conditions_for_contract', value); }}
                        renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('asset_conditions_for_contract', '')} error={error.asset_conditions_for_contract ? true : false} helperText={error.asset_conditions_for_contract ? error.asset_conditions_for_contract : ''} {...params} label="Clause" placeholder="Select Clause" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Terms' value={returnCond.terms ? returnCond.terms : '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="terms"
                        label="Terms(Upto 500 character limits)"
                        fullWidth
                        margin="normal"
                        multiline
                        inputProps={{ maxLength: 500 }}
                        value={returnCond.terms}
                        error={error.terms ? true : false}
                        InputLabelProps={{ shrink: true }}
                        helperText={error.terms ? error.terms : ''}
                        onChange={(e) => this.onFieldChange(e, 'terms', e.target.value)}
                        onFocus={(e) => this.updateErrorField('terms', '')}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Exceptions' value={returnCond.exceptions_done ? returnCond.exceptions_done : '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="exceptions_done"
                        label="Exceptions"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 255 }}
                        value={returnCond.exceptions_done}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.onFieldChange(e, 'exceptions_done', e.target.value)}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Remarks' value={returnCond.remarks ? returnCond.remarks : '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 255 }}
                        value={returnCond.remarks}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Flag this Return Condition' value={returnCond.flags ? 'Yes' : 'No'} /> :
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox color="primary" checked={returnCond.flags} onChange={(e) => this.onFieldChange(e, 'flags', e.target.checked)} value="Flag this Return Condition" />
                        }
                        label="Flag this Return Condition"
                      />
                    </Grid>
                  }
                </Grid>
              </div>
              <div className="drawer-footer">
                {mode !== 'view' ?
                  <Button onClick={this.addEditReturnFn} color="primary" variant="contained" >Save Changes</Button> : null
                }
                <Button onClick={() => this.setState({ modal: false, mode: '', returnCond: {}, error: {} })} color="primary" style={mode == 'view' ? { marginLeft: 'auto', paddingLeft: '0px' } : { marginLeft: '8px' }}>Cancel</Button>
              </div>
            </div>
          </Drawer> : null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(ReturnCondCRU));
