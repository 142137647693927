import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';
export default class InvoiceFilter extends Component {
  constructor(props){
    super(props);
  }
  render(){
    return(
      <div className="filter-block row">
        <Col md="2" xs="2">
          <h6>
            <span>
              Invoice Number
            </span>
          </h6>
        </Col>
        <Col md="1" xs="1">
          <h6>
            <span>
              Type
            </span>
          </h6>
        </Col>
        <Col md="2" xs="2">
          <h6>
            <span>
              Invoice Period
            </span>
          </h6>
        </Col>
        <Col md="1" xs="1">
          <h6>
            <span>
              Invoice Date
            </span>
          </h6>
        </Col>
        <Col md="2" xs="2">
          <h6>
            <span>
              Payment Due Date
            </span>
          </h6>
        </Col>
        <Col md="2" xs="2" style={{paddingLeft:'25px'}}>
          <h6>
            <span>
              Amount
            </span>
          </h6>
        </Col>
        <Col md="2" xs="2">
          <h6>
            <span>
              Status
            </span>
          </h6>
        </Col>
      </div>
    )
  }
}
