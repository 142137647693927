import { regexConstants, errorMsg1 } from '../../constants/regEx';
import { getLocalStorageInfo } from '../../utils_v2';
export const leaseTypes = [{label: 'Dry Lease', value: '1'}, {label: 'Finance Lease', value: '4'}, {label: 'Operating Lease', value: '3'}, {label: 'Wet Lease', value: '2'}]
export const addContactObj = {
  asset_type:null,
  lease_type:null,
  lessee_id:null,
  same_operator_lessee:false,
  operator_id: null,
  contractual_start_date:null,
  contractual_end_date:null,
  lease_term:'',
  mr_account_number:null,
  rental_account_number:null,
}
export const contactHd = [
  { label: 'Lease Type', sortOption: false },
  { label: 'Lessee', sortOption: false },
  { label: 'Lease Duration ', sortOption: false },
  { label: 'Status', sortOption: false }
]
export const contactFleetHd = [
  { id:'asset_name', label: 'Asset(s)', sortOption: true },
  { id:'lessor', label: 'Lessor', sortOption: false },
  { id:'owner', label: 'Owner ', sortOption: false },
  { id:'lessee_id', label: 'Lessee', sortOption: false },
  { label:'Lease Duration', sortOption: false },
  { id:'lease_type', label: 'Lease Type', sortOption: true },
  {  label: 'Lease Status', sortOption: false }
]
export const jscLeaseListHd = [
  { label: 'Lease Type', sortOption: false },
  { label: 'Lessee', sortOption: false },
  { label: 'Rent', sortOption: false},
  { label: 'Rent ', sortOption: false },
  { label: 'Utilization ', sortOption: false },
  { label: 'Status', sortOption: false }
]
export const fleetLeaseHd = [
  { label: 'Asset', sortOption: false },
  { label: 'Lessee', sortOption: false },
  { label: 'Owner/Lessor', sortOption: false },
  { label: 'Lease Period', sortOption: false },
  { label: 'Lease Type', sortOption: false },
  { label: 'Status', sortOption: false }
]
export const errorCode = {
  lease_type:{
    0:'',
    1:'Please select Lease Type'
  },
  lease_typeObj:{
    required: true
  },
  lessee_id:{
    0:'',
    1: 'Please select Lessee',
  },
  lessee_idObj:{
    required:true
  },
  lessee:{
    0:'',
    1: 'Please select Lessee',
  },
  lesseeObj: {
    required: true
  },
  operator_id: {
    0:'',
    1: 'Please select Operator',
  },
  operator_idObj: {
    required: true
  },
  operator: {
    0:'',
    1: 'Please select Operator',
  },
  operatorObj: {
    required: true
  },
  tsn: {
    0:'',
    1: 'Please enter Start TSN',
  },
  tsnObj: {
    required: true
  },
  time_between_overhaul: {
    0:'',
    1: 'Please enter Time between OverHaul',
  },
  time_between_overhaulObj: {
    required: true
  },
  last_overhaul_tsn: {
    0:'',
    1: 'Please enter last OverHaul TSN',
  },
  last_overhaul_tsnObj: {
    required: true
  },
  lease_duration_hours: {
    0:'',
    1: 'Please enter Lease Duration in Hours',
  },
  lease_duration_hoursObj: {
    required: true
  },
  lease_duration_type: {
    0:'',
    1:'Please select Lease Duration Type'
  },
  lease_duration_typeObj:{
    required: true
  },
  contractual_start_date: {
    0:'',
    1:'Please enter Start Date'
  },
  contractual_start_dateObj:{
    required: true
  },
  contractual_end_date: {
    0:'',
    1:'Please enter End Date'
  },
  contractual_end_dateObj:{
    required: true
  },
  lessor_name: {
    0: '',
    1: 'Please enter Lessor Name',
    4:errorMsg1
  },
  lessor_nameObj: {
    required:true,
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  lessor_jurisdiction: {
    0:'',
    4:errorMsg1
  },
  lessor_jurisdictionObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  lessor_special_purpose_vehicle: {
    0:'',
    4:errorMsg1
  },
  lessor_special_purpose_vehicleObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  lessee_jurisdiction: {
    0:'',
    4:errorMsg1
  },
  lessee_jurisdictionObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  habitual_base: {
    0:'',
    4:errorMsg1
  },
  habitual_baseObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  contractual_definition_of_lease_period: {
    0:'',
    4:errorMsg1
  },
  contractual_definition_of_lease_periodObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  }
}

export const leasesFilterOps = {
  asset_type : {
    inputType: 'dropdown',
    placeholder: 'Select Asset Type',
    title: 'Asset Type',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  asset_model: {
    inputType: 'dropdown',
    placeholder: 'Select Asset Model',
    title:'Asset Model',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  serial_number: {
    inputType: 'dropdown',
    placeholder: 'Select Serial No.',
    title:'Serial No.',
    options:  [],
    labelKey: 'serial_number',
    valueKey: 'id',
    multiple: true
  },
  lessee_id : {
    inputType: 'dropdown',
    placeholder: 'Select lessee',
    title: 'Lessee',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
   },
   lessor_id : {
    inputType: 'dropdown',
    placeholder: 'Select lessee',
    title: 'Lessor',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
   },
   owner_id : {
    inputType: 'dropdown',
    placeholder: 'Select lessee',
    title: 'Owner',
    options:  [],
    labelKey: 'name',
    valueKey: 'id',
    multiple: true
  },
  lease_from: {
    inputType: 'date',
    title: 'Lease Start'
  },
  lease_till: {
    inputType: 'date',
    title: 'Lease End'
  },
  lease_type : {
    inputType: 'dropdown',
    placeholder: 'Select lease type',
    title: 'Lease Type',
    options:  leaseTypes,
    labelKey: 'label',
    valueKey: 'value',
    multiple: true
  },
  lease_status : {
    inputType: 'dropdown',
    placeholder: 'Select lease status',
    title: 'Lease Status',
    options:  [],
    labelKey: 'label',
    valueKey: 'value',
    multiple: true
  }
}

export const leasesTypeGraphFilterOps = {
  lease_from: {
    inputType: 'date',
    title: 'Lease Start'
  },
  lease_till: {
    inputType: 'date',
    title: 'Lease End'
  },
  lease_status : {
    inputType: 'dropdown',
    placeholder: 'Select lease status',
    title: 'Lease Status',
    options:  [],
    labelKey: 'label',
    valueKey: 'value',
    multiple: true
  }
}

export const leasesStatusGraphFilterOps = {
  lease_from: {
    inputType: 'date',
    title: 'Lease Start'
  },
  lease_till: {
    inputType: 'date',
    title: 'Lease End'
  },
  lease_type : {
    inputType: 'dropdown',
    placeholder: 'Select lease type',
    title: 'Lease Type',
    options: [{label: 'Dry Lease', value: '1'}, {label: 'Finance Lease', value: '4'}, {label: 'Operating Lease', value: '3'}, {label: 'Wet Lease', value: '2'}],
    labelKey: 'label',
    valueKey: 'value',
    multiple: true
  },
}

export const options1 = {
  chart: {
    type: 'column'
  },
  title: {
      text: 'Lease based on Type'
  },
  xAxis: {
      categories: ['Dry Lease', 'Operating Lease', 'Wet Lease']
  },
  credits: {
      enabled: false
  },
  series: [{name:'Lease',data: [5, 3, 4]}]
}
export const options2 = {
  chart: {
        type: 'bar'
    },
    title: {
        text: 'Lease by Owner/Lessor'
    },
    xAxis: {
        categories: ['Owner 1', 'Owner 2', 'Owner 3', 'Owner 4', 'Owner 5'],
        title: {
            text: null
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Lease',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        }
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true
            }
        }
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        shadow: true
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'Draft',
        data: [10, 14, 18, 28, 3]
    }, {
        name: 'On Lease',
        data: [11, 16, 22, 30, 1]
    }, {
        name: 'Off Lease',
        data: [8, 14, 21, 29, 5]
    }, {
        name: 'Written Off',
        data: [22, 26, 24, 38, 21]
    }]
};

export const options3 = {
  chart: {
        type: 'column'
    },
    title: {
        text: 'Lease by Lessee'
    },
    xAxis: {
        categories: [
          '9 Air',
          'Air Asia',
          'Azul',
          'Bek Air',
          'Cape Air',
          'Daallo Airlines',
          'Easyjet',
          'Air France',
          'GestAir',
          'PenAir',
          'ASL',
          'Dc Aviation'
        ],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Lease'
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [{
        name: 'Draft',
        data: [40, 42, 45, 48, 38, 53, 34, 44, 50, 48, 38, 32]

    }, {
        name: 'On Lease',
        data: [40, 38, 41, 46, 38, 50, 34, 44, 50, 48, 38, 32]

    }, {
        name: 'Off Lease',
        data: [4, 10, 8, 6, 3, 5, 12, 4, 11, 2, 20, 12]

    }, {
        name: 'Stored',
        data: [2, 5, 2, 7, 9, 4, 7, 3, 6, 2, 8, 12]

    }]
}
export const options4 = {
  chart: {
        type: 'column'
    },

    title: {
        text: 'Lease based on Status'
    },

    xAxis: {
        categories: ['Draft', 'On Lease', 'Off Lease', 'Stored', 'Written Off']
    },

    yAxis: {
        allowDecimals: false,
        min: 0,
        title: {
            text: 'Number of Lease'
        }
    },

    plotOptions: {
        column: {
            stacking: 'normal'
        }
    },

    series: [
      {
        name: 'Lese',
        data: [5, 3, 4, 7, 2],
      }
    ]
}
export const options5 = {
  chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: 'Lease Distribution by Aircraft Type'
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
    series: [{
        name: 'Lease(%)',
        colorByPoint: true,
        data: [{
            name: 'A320-Neo',
            y: 61.41,
            sliced: true,
            selected: true
        }, {
            name: 'B737-800',
            y: 11.84
        }, {
            name: 'A318',
            y: 10.85
        }, {
            name: 'CRJ 700',
            y: 4.67
        }, {
            name: 'DHC-8-100',
            y: 4.18
        }, {
            name: 'EMB-135BJ',
            y: 1.64
        }, {
            name: 'Q400-DH4',
            y: 1.6
        }, {
            name: 'SAAB-DH4',
            y: 1.2
        }, {
            name: 'Other',
            y: 2.61
        }]
    }]
}
export const options6 = {
  chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: 'Lease Distribution by Engine Type'
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
    series: [{
        name: 'Lease(%)',
        colorByPoint: true,
        data: [{
            name: 'CFM56-5B2/P',
            y: 61.41,
            sliced: true,
            selected: true
        }, {
            name: 'CFM56-5C2/F4',
            y: 11.84
        }, {
            name: 'AE 2100A',
            y: 10.85
        }, {
            name: 'LEAP-1A30',
            y: 4.67
        }, {
            name: 'PW1133G-JM',
            y: 4.18
        }, {
            name: 'Trent-1000-A',
            y: 1.64
        }, {
            name: 'GE90-77B',
            y: 1.6
        }, {
            name: 'CF34-10E7-B',
            y: 1.2
        }, {
            name: 'Other',
            y: 2.61
        }]
    }]
}
