import { globalGetService, globalPostService, globalPutService, globalExportService, } from '../../globalServices';
import checkApiStatus, { toastFlashMessage } from '../../utils'
import { downloadFileType } from '../../utils';
export function getAssetDistribustionApi(query = {}) {
  this.setState({ assetDistribustionLoader: true });
  globalGetService(`contracts/v2/dashboard/asset-summary/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          assetDistribustion: response.data.data
        })
      }
      this.setState({ assetDistribustionLoader: false });
    })
}
export function getLeaseDistribustionApi(query = {}) {
  this.setState({ leaseDistribustionLoader: true });
  globalGetService(`contracts/v2/dashboard/lease-summary/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          leaseDistribustion: response.data.data
        })
      }
      this.setState({ leaseDistribustionLoader: false });
    })
}
export function getInvoiceSummaryApi(query = {}) {
  this.setState({ invoiceSummaryLoader: true });
  globalGetService(`contracts/v2/dashboard/invoice-summary/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          invoiceSummary: response.data.data
        })
      }
      this.setState({ invoiceSummaryLoader: false });
    })
}
export function getInvoiceTypeSummaryApi(query = {}) {
  this.setState({ invoiceTypeSummaryLoader: true });
  globalGetService(`contracts/v2/dashboard/invoice-type-summary/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          invoiceTypeSummary: response.data.data
        })
      }
      this.setState({ invoiceTypeSummaryLoader: false });
    })
}
export function getExpirationCertificateApi(query = {}) {
  this.setState({ certificateTableLoader: true });
  globalGetService(`contracts/dashboard/expiring-certificates/`, query)
    .then(response => {
      this.setState({ certificateTableLoader: false });
      if (checkApiStatus(response)) {
        this.setState({
          listOfCertificates: response.data.data
        })
      }
    })
}
export function exportExpirationCertificateApi(query, filter){
  this.setState({ pageLoader: true });
  globalExportService(`contracts/dashboard/expiring-certificates/?download=xls`,filter)
  .then(response => {
    this.setState({ pageLoader: false });
    downloadFileType(response, `Certificate_Expiration_list.`, 'xls');
  })
}
export function lowUtilsListApi(query){
  this.setState({ pageLoader: true });
  globalGetService(`contracts/v2/dashboard/low-utilization-list/`,query)
  .then(response=>{
    this.setState({ pageLoader: false });
    if (checkApiStatus(response)) {
      this.setState({lowUtilsList:response.data.data})
    }
    else{
      toastFlashMessage('Successfully Exported', 'error')
    }
  })
}
export function exportLowUtilsListApi(query){
  this.setState({ pageLoader: true });
  globalExportService(`contracts/v2/dashboard/low-utilization-list/`, {...query, download:'xlsx'})
  .then(response=>{
    this.setState({ pageLoader: false});
       downloadFileType(response, `Low_Utilisation_List.`, 'xlsx');
  })
}
