import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { SIDEBAR_TOGGLE } from '../actions';
import { Link, IndexLink } from 'react-router';
import { getLocalStorageInfo, eraseGlobalCookie } from '../../utils';
import { checkPermission } from '../../utils_v2';
import { trackLogOut } from '../../utils/mixpanel'
import { imgStoragePath } from '../../constants';
import { licenseListAc } from '../../phase2/licenses/actionCreators'
import ToolTipHover from './ToolTipHover';
import dashboardIcon from '../../shared/assets/img/dashbord_icon.svg';
import homeIcon from '../../shared/assets/img/home_icon.png';
import leaseIcon from '../../shared_elements/assets/img/lease_icon.svg';
import invoiceIcon from '../../shared_elements/assets/img/invoices_icon.svg';
import cashflowIcon from '../../shared_elements/assets/img/cashflow_icon.svg';
import utlizationIcon from '../../shared_elements/assets/img/utilization_icon.svg';
import config from '../../config';
class PrySidebarExp extends Component {
  constructor(props) {
    super(props);
  }
  logout = () => {
    trackLogOut(getLocalStorageInfo())
    localStorage.clear();
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('redirectURILease')
    eraseGlobalCookie('domain')
    this.props.sidebarToggleFn(false)
    browserHistory.push('/login');
  }

  componentDidMount() {
    if (getLocalStorageInfo() && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.license) {
      this.props.fetchLicenses()
    }
  }

  checkTracker() {
    if (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('R')) {
      return '/technical/projects/tracker';
    } else if (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR')) {
      return '/technical/projects/tracker';
    } else if (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('MR')) {
      return '/technical/man-power/tracker';
    } else if (getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('CR')) {
      return '/technical/cor/tracker';
    }
  }

  primaryNavLink = (imgIcon, imgWidth, url, activeClassName, flag, targetId, placement, title, linkAccess, isBeta) => {

    if (linkAccess) {
      return (
        <li>
          <Link to={url} onClick={() => this.props.sidebarToggleFn(false)} style={(title == 'Co') ? { cursor: 'not-allowed' } : {}} className={(targetId == 'userManagement' ? ' ' : ' ') + (targetId == 'usersManager' ? 'utility-block ' : ' ') + (activeClassName ? 'flex-centered active' : 'flex-centered')}>
            <img id={targetId} src={imgStoragePath + imgIcon} alt="nav-icon" width={imgWidth} />
            <br />
            <span className="sideBarSubheading">{title}</span>
            {
              isBeta ? <sup style={{ color: '#ff0000', fontSize: '10px', fontFamily: 'Conv_IBMPlexSans-Bold', padding: '0 2px' }}>BETA</sup> : null
            }
            {
              //this.showToolTip(flag, targetId, placement, title)
            }
          </Link>
        </li>
      )
    } else {
      return null
    }
  }
  showToolTip = (flag, targetId, placement, title) => {
    if (!flag) {
      return (
        <ToolTipHover tagetId={targetId} placement={placement}> {title} </ToolTipHover>
      )
    } else { return null }
  }
  render() {
    var { sideBarStatus, licenses, notificationOpen, dashSw } = this.props;
    sideBarStatus = false;
    const pathname = this.props.pathName;
    const userDetail = JSON.parse(localStorage.getItem('userInfo'));
    let is_saas = false
    Object.keys(userDetail.user.permission).map(permission => {
      if (userDetail.user.permission[permission].is_saas == true) {
        is_saas = true
      }
      return permission
    })
    return (
      <div className="prySidebarExp" style={notificationOpen || this.props.topNotice ? { marginTop: '30px' } : null}>
        <div className="dashboard-block">
          {
            <ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0px' }}>
            { getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.security_groups[0] && getLocalStorageInfo().user.security_groups[0].id === 3 ? null :
              <li>
                <Link className="flex-centered" to={config.domain.subDomian} target="_blank">
                  <img id="home" src={homeIcon} alt="img" width='25' />
                  <br />
                  <span className="sideBarSubheading ">
                    Home
                  </span>
                </Link>
              </li> }
              {checkPermission('contracts','dashboard','R') ?
                <li>
                  <IndexLink onClick={() => this.props.sidebarToggleFn(false)} activeClassName="active" className={pathname === '' || pathname === '/' ? "flex-centered active" : "flex-centered"} to="/fleet/contracts/dashboard">
                    <img id="dashboard" src={dashboardIcon} alt="img" width='20' />
                    <br />
                    <span className="sideBarSubheading ">
                      Dashboard
                    </span>
                  </IndexLink>
                </li>:null 
              }
              {this.primaryNavLink('phase-2/asset_list_icon.png', 20, '/assets-listing', ((pathname.includes('assets-listing') || pathname.includes('contract')) && !pathname.includes('fleet/contracts/dashboard')), sideBarStatus, 'assetList', 'right', 'Assets', true)}
              <li>
                <Link onClick={() => this.props.sidebarToggleFn(false)} activeClassName="active" to="/leases">
                  <img id="dashboard" src={leaseIcon} alt="img" width='20' />
                  <br />
                  <span className="sideBarSubheading ">
                    Leases
                  </span>
                </Link>
              </li>
              {checkPermission('contracts','utilization','R') ?
                <li>
                  <Link onClick={() => this.props.sidebarToggleFn(false)} activeClassName="active" to="/utilizations">
                    <img id="dashboard" src={utlizationIcon} alt="img" width='20' />
                    <br />
                    <span className="sideBarSubheading ">
                      Utilization
                    </span>
                  </Link>
                </li>:null
              }
              {checkPermission('contracts','invoices','R') ?
                <li>
                  <Link onClick={() => this.props.sidebarToggleFn(false)} activeClassName="active" to="/invoices">
                    <img id="dashboard" src={invoiceIcon} alt="img" width='20' />
                    <br />
                    <span className="sideBarSubheading ">
                      Invoices
                    </span>
                  </Link>
                </li>:null
              }
              {checkPermission('contracts','cashflow','R') ?
              <li>
                <Link onClick={() => this.props.sidebarToggleFn(false)} activeClassName="active" to="/cashflows">
                  <img id="dashboard" src={cashflowIcon} alt="img" width='20' />
                  <br />
                  <span className="sideBarSubheading ">
                    Cashflows
                  </span>
                </Link>
              </li>:null
            }
              
              { checkPermission('reports', 'bulk_download', 'R') || checkPermission('reports', 'custom_reports', 'R') ?
                <li>
                  <Link className="flex-centered" to={`${config.domain.subDomian}export/reports`} target="_blank">
                    <img id="settings" src={imgStoragePath + 'report_icon.png'} alt="img" width='20' />
                    <br />
                    <span className="sideBarSubheading ">
                      Reports
                    </span>
                  </Link>
                </li> : null
              }
              { checkPermission('console', 'lessor_configuration', 'R') || checkPermission('console', 'distribution_emails', 'R') || checkPermission('console', 'introduction', 'R') || checkPermission('console', 'favourite_lessee', 'R') || checkPermission('console', 'banking_details', 'R') || checkPermission('console', 'owner_details', 'R')?
                <li>
                  <Link className="flex-centered" to={`${config.domain.subDomian}configure`} target="_blank">
                    <img id="settings" src={imgStoragePath + 'settings.png'} alt="img" width='20' />
                    <br />
                    <span className="sideBarSubheading ">
                      Configure
                    </span>
                  </Link>
                </li> : null
              }
            </ul>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  licenses: state.LicenseReducer.licensList,
  notificationOpen: state.sharedReducers.notificationOpen,
  dashSw: state.sharedReducers.dashSw,
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sidebarToggleFn: (statusFlag) => dispatch({
      type: SIDEBAR_TOGGLE,
      payload: false
    }),
    fetchLicenses: () => dispatch(licenseListAc())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrySidebarExp);
