import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link , browserHistory} from 'react-router';
import { Row, Col, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,Modal, ModalBody} from 'reactstrap';
import { Button } from '@material-ui/core';
import Select from 'react-select';
import { AssetsFilter, AssetsList, AssetsPagination, AssetsTabs, AddEditAsset, AddEditEngine, ImportAsset, AddEditApu, AddEditLg, AddEditPropeller, AddEditModule } from '../components';
import { EditSidebar } from '../../applications/contracts/Elements';
import { addEditPropellerAc, assetsListAc, downLoadAssetAc, AddAssetAc, AddEngineAc, AddModuleAc, importAssetAc, getLessorListAc, getApuTypesAc, addEditApuAc, addEditLgAc, exportMarkeTechSummeryAc, filteredLesseeListAc, filteredAircraftTypeListAc, filteredEngTypeListAc, filteredApuTypeListAc } from '../actionCreators';
import { sManufacturerAc, sGlobalConstantAc, sRegionListAc } from '../../shared/actionCreators';
import { aircraftMaintenanceAc, getAssetTranferUsageAc } from '../../applications/console/TechSummary/actionCreators'
import { ASSET_FILTER_EMPTY, ASSET_FILTER_UPDATE, ASSET_SORT_UPDATE, ASSET_TAB_UPDATE, LIST_LOADER, TOGGLE_LICENCE_MODAL} from '../actions';
import { NoRecordFound, LicenceModal  } from '../../shared';
import { getOwnerAc } from '../../shared/actionCreators';
import { assetViewAc } from '../../User/actionCreators'
import { imgStoragePath } from '../../constants';
import { removeEmptyKey, getLocalStorageInfo } from '../../utils'
import { checkPermission } from '../../utils_v2'
import { trackActivity } from '../../utils/mixpanel';
import '../assets/styles/assetListing.scss';
import { BUY_LICENCE } from '../../shared/actions'
import DeploymentMessage from '../../shared_elements/components/DeploymentMessage';
class AssetsListing extends Component {
    constructor(props){
      super(props);
      this.state = {
        placeholderLoader: false,
        addForm: false,
        addApu: false,
        addLg: false,
        activeTab: 'all',
        addEngine: false,
        importAsset: false,
        asset: {
          aircraft_type: '',
          msn: '',
          date_of_manufacture: null,
          credit_classification: '',
          maintenance_event_program_group: '',
          location: '',
          latitude: '',
          longitude: '',
          country: '',
          status: '',
          same_operator_lessee: false,
          ownership_type: 1
        },
        engine: {
          esn: "",
          engine_type_id: "",
          average_monthly_cycles: null,
          average_monthly_hours: null,
          constructed_model: "",
          location: '',
          latitude: '',
          longitude: '',
          country: '',
          status: '',
          position: '',
          csn: null,
          tsn: null,
          manufacturing_date: '',
          credit_classification: '',
          operator_id: '',
          operating_thrust: '',
          purchase_thrust: '',
          region: '',
          lessee_id: '',
          match_csn_to_llp:false,
          same_operator_lessee: false,
        },
      }
      this.updateAssetSort = this.updateAssetSort.bind(this);
      this.toggle = this.toggle.bind(this);
    }

    componentWillMount(){
      this.props.setLoader();
      trackActivity('Page Visited', {title: 'Asset Listing'})
    }

    updateAssetTab = (tab) =>{
      if(this.props.listLoader){
        return
      }
      let location = browserHistory.getCurrentLocation()
      this.props.emptyAssetFilter()
      switch (tab) {
        case 'all':
          {
            this.setState({activeTab: 'all'})
            location.query = {page: 1, per_page: 50}
          }
        break;
        case '1':
          {
            this.setState({activeTab: '1'})
            location.query = {asset_type: 1, page: 1, per_page: 50}
          }
        break;
        case '2':
          {
            this.setState({activeTab: '2'})
            location.query = {asset_type: 2, page: 1, per_page: 50}
          }
        break;
        case '3':
          {
            this.setState({activeTab: '3'})
            location.query = {asset_type: 3, page: 1, per_page: 50}
          }
        break;
        case '4':
          {
            this.setState({activeTab: '4'})
            location.query = {asset_type: 4, page: 1, per_page: 50}
          }
        break;
        case '5':
          {
            this.setState({activeTab: '5'})
            location.query = {asset_type: 5, page: 1, per_page: 50}
          }
        break;
        case '6':
          {
            this.setState({activeTab: '6'})
            location.query = {asset_type: 6, page: 1, per_page: 50}
          }
        break;
        case '7':
          {
            this.setState({activeTab: '7'})
            location.query = {asset_type: 7, page: 1, per_page: 50}
          }
        break;
        case '8':
          {
            this.setState({activeTab: '8'})
            location.query = {asset_type: 8, page: 1, per_page: 50}
          }
        break;
        case '9':
          {
            this.setState({activeTab: '9'})
            location.query = {asset_type: 9, page: 1, per_page: 50}
          }
        break;
        default:
          location.query = {page: 1, per_page: 50}
      }
      browserHistory.push(location)
      setTimeout(() => this.props.fetchAssetLists(location.query, true), 500)
    }

    componentDidMount(){
        const filterParams = removeEmptyKey(this.props.location.query);
        this.setState({activeTab: filterParams.asset_type ? filterParams.asset_type : 'all'})
    		this.props.fetchManufacturer('apu','apucraftManufacturerList');
        this.props.fetchAssetLists({...filterParams, per_page:50}, true);
        this.props.fetchTechConstant({constant_types:["asset_status","interior_location","credit_classification","off_wing_status","weight_unit","aircraft_operation","lg_position","engine_position", "shop_visit_type", "asset_status", 'off_lease_status', "asset_sub_status"]});
        this.props.fetchRegionList();
        this.props.getLessorList();
        this.props.getOwnersList();
        this.props.getApuTypes();
        if(getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.is_saas){
    			this.props.getAssetTranferUsage();
    		}
        trackActivity('Page Visited', {
          application_module: 'Console',
          asset: 'All',
          page_title: 'Assets Listing'
        })
    }
    updateAssetQueryUrl = (filterParams) => {
      const location = Object.assign({}, browserHistory.getCurrentLocation());
      location.query= filterParams;
      browserHistory.push(location);
    }
    updateAssetSort(type) {
      let queryFilter = this.props.assetFilter;
      let sortBy = 'asc'
      if(type == queryFilter.sort){
        sortBy = (queryFilter.sort_by == 'asc') ? 'desc': 'asc';
      }
      queryFilter = {
        ...queryFilter,
        sort: type,
        sort_by: sortBy
      };
      const filterParams = removeEmptyKey(queryFilter);
      this.props.setLoader();
      this.props.updateAssetSort( type);
      this.props.fetchAssetLists(filterParams, false);
      this.updateAssetQueryUrl(filterParams);

    }
    updateAssetPageNo = (page) => {
      let queryFilter = this.props.assetFilter;
      queryFilter = {
        ...queryFilter,
        page: page,
        per_page: this.props.paginationInfo.per_page
      };
      const filterParams = removeEmptyKey(queryFilter);
      this.props.updateAssetFilter(page, 'page');
      this.props.setLoader();
      this.props.fetchAssetLists(filterParams, false);
      this.updateAssetQueryUrl(filterParams);
    }
    updateAssetRows = (page) => {
      let location = browserHistory.getCurrentLocation()
      let queryFilter = this.props.assetFilter;
      queryFilter = {
        ...queryFilter,
        per_page: page,
        page: 1
      };
      const filterParams = removeEmptyKey(queryFilter);
      this.props.updateAssetFilter(page, 'per_page');
      this.props.updateAssetFilter(1, 'page');
      this.props.setLoader();
      this.props.fetchAssetLists(filterParams, false, {asset_type: this.state.activeTab});
      this.updateAssetQueryUrl(filterParams);
    }
    toggle() {
      this.setState({
        dropdownOpen: !this.state.dropdownOpen
      });
    }
    toggleEditSideBar = (type) => {
      if(getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.is_saas && type == 'importAsset' && this.state.importAsset == false){
        if(this.props.assetTransferUsage.available == this.props.assetTransferUsage.used){
          this.props.buyLicence({
            flag: true,
            action: '/licenses/asset-data-transfer',
            content: "You don't have license to transfer asset. Please buy new license to transfer asset."
          })
          return;
        }
      }
      this.setState(prevState => ({
        ...prevState,
        [type]: !prevState[type]
      }));
    }
    render(){
        const { apuCrud,apuTypesList, portfolioList, assetLists, assetFilter, clients, paginationInfo, aircraftTypes, engineTypes, listLoader, techConstants, aircraftManufacturerList, maintenanceProgGrp, sManufacruerList, operators, sRegionList, moduleTypes, licenseModalFlag, licenceModalMessage, ownersList, filteredLesseeList, filteredAircraftTypeList, filteredEngTypeList, filteredApuTypeList } = this.props;
        const userInfo = getLocalStorageInfo().user.permission;
        var showTabs = true
        let lessorIds = []
    		 if(window.location.href.includes('qa')){
    			 lessorIds = [241, 86]
    		 }else if(window.location.href.includes('dev')){
    			 lessorIds = [289,89]
    		 }else{
    			 lessorIds = [86]
    		 }
        // Object.keys(userInfo).map(key => {
        //   showTabs = userInfo[key].is_saas ? false : showTabs
        //   return key
        // })
        if(lessorIds.includes(getLocalStorageInfo().defaultLessor.id)){
          showTabs = true
        }

        return(
            <div className="asset-listing-wrap" style={{marginLeft:'55px'}}>
              <DeploymentMessage/>
                <div className="title-block" style={{marginLeft:'0px'}}>
                    <h1>Assets</h1>
                    <p>Offers a ‘LIST’ view of all assets created in SPARTA</p>
                </div>
                <h1 style={{ marginTop: '-45px' }}><span style={{ opacity: '0' }}>Asset Listing</span>
                    { checkPermission('technical_specs','aircraft', 'C') || checkPermission('technical_specs','engine', 'C') || checkPermission('technical_specs','apu', 'C') || checkPermission('technical_specs','landing_gears', 'C') || checkPermission('technical_specs','propeller', 'C') ?
                        <ButtonDropdown  className="add-asset-dd"  isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle caret size="sm" className="drop-down">
                                Add Asset
                            </DropdownToggle>
                            <DropdownMenu right>
                                {checkPermission('technical_specs','aircraft', 'C') ?
                                  <DropdownItem onClick = {() => this.toggleEditSideBar('addForm')} >Aircraft (MSN)</DropdownItem>:null
                                }
                                { checkPermission('technical_specs','engine', 'C') ?
                                  <DropdownItem onClick = {() => this.toggleEditSideBar('addEngine')} >Engine (ESN)</DropdownItem>:null
                                }
                                
                                { showTabs && checkPermission('technical_specs','apu', 'C') ?
                                    <DropdownItem onClick = {() => this.toggleEditSideBar('addApu')} >APU</DropdownItem>
                                  :null
                                }
                                {
                                  showTabs && checkPermission('technical_specs','landing_gears', 'C') ?
                                    <DropdownItem onClick = {() => this.toggleEditSideBar('addLg')} >Landing Gear</DropdownItem>
                                  :null
                                }
                                {
                                  showTabs && checkPermission('technical_specs','apu', 'C') ?
                                    <DropdownItem onClick = {() => this.toggleEditSideBar('addPropeller')} >Propeller</DropdownItem>
                                  :null
                                }
                                {
                                   getLocalStorageInfo()?.defaultLessor?.id === 442 ? null : showTabs && checkPermission('technical_specs','engine', 'C') ?
                                    <DropdownItem onClick = {() => this.toggleEditSideBar('addModule')} >Module</DropdownItem>
                                  :null
                                }
                            
                            </DropdownMenu>
                        </ButtonDropdown>:null
                    }
                </h1>
                <AssetsTabs
                   tabType={assetFilter.tabType}
                   updateAssetTab={this.updateAssetTab}
                   downLoadAsset = {this.props.downLoadAsset}
                   total = {paginationInfo ? paginationInfo.total : 0}
                   activeTab={this.state.activeTab}
                   assetFilter = {assetFilter}
                   assetCounts={this.props.assetCounts}
                   pagintionInfo={paginationInfo}
                   updateAssetRows={this.updateAssetRows}
                />
                <div className="asset-listing-wrapper">
                  <div className="mobile-asset-list">
                  <AssetsFilter
                  techConstants={techConstants}
                    filterData={assetFilter}
                    clients={clients}
                    aircraftTypeList={filteredAircraftTypeList.aircraftTypes}
                    lesseeList={filteredLesseeList.lessees}
                    engTypeList={filteredEngTypeList.engineTypes}
                    apuTypeList={apuTypesList}
                    lessorList={this.props.lessorList}
                    operators={operators}
                    aircraftTypes = {aircraftTypes}
                    engineTypes = {engineTypes}
                    activeTab={this.state.activeTab}
                    updateAssetSort={this.updateAssetSort}
                    updateAssetQueryUrl = {this.updateAssetQueryUrl}
                    moduleTypes={moduleTypes}
                  />
                  { listLoader ?
                      <h3 className="text-center" style={{padding: '20px 0'}}>
                        <img src={imgStoragePath+"page-loader.gif"} width="100px" alt="Loading..."/>
                    </h3> : !listLoader && assetLists && assetLists.length ?
                    <div style={{overflow:'auto',overflowX:'hidden', height:(window.innerHeight - 350) + 'px'}}>{assetLists.map((assetList,index) => <AssetsList assetView={() => this.props.assetView(assetList)} exportMarkeTechSummery={() => this.props.exportMarkeTechSummery(assetList)} key={index} assetList={assetList} activeTab={this.state.activeTab} />)} </div>:
                    !listLoader && assetLists && assetLists.length == 0 ?
                      <NoRecordFound description=" " />
                    :null
                  }
                 </div>
                 { listLoader ? '' : assetLists && assetLists.length && paginationInfo ?
                    <AssetsPagination
                        updateAssetPageNo={this.updateAssetPageNo}
                        pagintionInfo={paginationInfo}
                        currentPage={assetFilter.page}
                    />: ''
                 }
                </div>
                <EditSidebar
                    title="Add Aircraft"
                    editSideBar={this.state.addForm}
                    toggleEditSideBar={() => this.toggleEditSideBar('addForm')}
                >
                    { this.state.addForm ?
                        <AddEditAsset
                            aircraftTypes={aircraftTypes}
                            clients={ clients }
                            techConstants = {techConstants}
                            type = 'add'
                            portfolioList={portfolioList}
                            createAsset = {this.props.createAsset}
                            aircraftManufacturerList = {aircraftManufacturerList}
                            maintenanceProgGrp = {maintenanceProgGrp}
                            sManufacruerList = {sManufacruerList}
                            toggleEditSideBar={() => this.toggleEditSideBar('addForm')}
                            operators = {operators}
                            fetchMaintenanceGrp = {this.props.fetchMaintenanceGrp}
                            fetchManufacturer = {this.props.fetchManufacturer}
                            assetObj = {this.state.asset}
                            ownersList={ownersList}
                            lessorList={[...this.props.lessorList,{id: 'newlessor',lessor_type: {value: 1, label: "Managed Lessor"},label: "Invite/Create Lessor",value: 'newlessor', name: "Invite/Create Lessor",slug: "newlessor"}]}
                          />:null
                      }
                  </EditSidebar>
                <EditSidebar
                    title="Add APU"
                    editSideBar={this.state.addApu}
                    toggleEditSideBar={() => this.toggleEditSideBar('addApu')}
                >
                    { this.state.addApu ?
                        <AddEditApu
                          techConstants={techConstants}
                          operators = {operators}
                          clients={ clients }
                          sRegionList = {sRegionList}
                          addEditApu={this.props.addEditApu}
                          portfolioList={portfolioList}
                          apuTypesList={apuTypesList}
                          ownersList={ownersList}
                          toggleEditSideBar={() => this.toggleEditSideBar('addApu')}
                          />:null
                      }
                  </EditSidebar>
                <EditSidebar
                    title="Add Propeller"
                    editSideBar={this.state.addPropeller}
                    toggleEditSideBar={() => this.toggleEditSideBar('addPropeller')}
                >
                    { this.state.addPropeller ?
                        <AddEditPropeller
                          techConstants={techConstants}
                          operators = {operators}
                          clients={ clients }
                          portfolioList={portfolioList}
                          sRegionList = {sRegionList}
                          ownersList={ownersList}
                          addEditApu={this.props.addEditPropeller}
                          toggleEditSideBar={() => this.toggleEditSideBar('addPropeller')}
                          />:null
                      }
                  </EditSidebar>
                <EditSidebar
                    title="Add Module"
                    editSideBar={this.state.addModule}
                    toggleEditSideBar={() => this.toggleEditSideBar('addModule')}
                >
                    { this.state.addModule ?
                      <AddEditModule
                      engineObj={this.state.engine}
                      toggleEditSideBar = {this.toggleEditSideBar}
                      createEngine = {this.props.createModule}
                      techConstants = {techConstants}
                      sRegionList = {sRegionList}
                      clients = {clients}
                      portfolioList={portfolioList}
                      operators = {operators}
                      ownersList={ownersList}
                      engineTypes= {engineTypes}
                      lessorList={[...this.props.lessorList,{id: 'newlessor',lessor_type: {value: 1, label: "Managed Lessor"},label: "Invite/Create Lessor",value: 'newlessor', name: "Invite/Create Lessor",slug: "newlessor"}]}
                      />
                      :null
                      }
                  </EditSidebar>
                <EditSidebar
                    title="Add Landing Gear"
                    editSideBar={this.state.addLg}
                    toggleEditSideBar={() => this.toggleEditSideBar('addLg')}
                >
                    { this.state.addLg ?
                        <AddEditLg
                          techConstants={techConstants}
                          operators = {operators}
                          clients={ clients }
                          sRegionList = {sRegionList}
                          portfolioList={portfolioList}
                          addEditApu={this.props.addEditLg}
                          ownersList={ownersList}
                          toggleEditSideBar={() => this.toggleEditSideBar('addLg')}
                          />:null
                      }
                  </EditSidebar>
                <EditSidebar
                    title="Import via Transfer Key"
                    editSideBar={this.state.importAsset}
                    toggleEditSideBar={() => this.toggleEditSideBar('importAsset')}
                    width={300}
                >
                    { this.state.importAsset ?
                        <ImportAsset
                            aircraftTypes={aircraftTypes}
                            clients={ clients }
                            techConstants = {techConstants}
                            type = 'add'
                            createAsset = {this.props.createAsset}
                            aircraftManufacturerList = {aircraftManufacturerList}
                            maintenanceProgGrp = {maintenanceProgGrp}
                            sManufacruerList = {sManufacruerList}
                            toggleEditSideBar={() => this.toggleEditSideBar('importAsset')}
                            operators = {operators}
                            fetchMaintenanceGrp = {this.props.fetchMaintenanceGrp}
                            fetchManufacturer = {this.props.fetchManufacturer}
                            assetObj = {this.state.asset}
                            importAsset = {this.props.importAsset}
                          />:null
                      }
                  </EditSidebar>
                  <EditSidebar
                    title="Add Engine"
                    editSideBar={this.state.addEngine}
                    toggleEditSideBar={() => this.toggleEditSideBar('addEngine')}
                    >
                  {
                    this.state.addEngine ?
                    <AddEditEngine
                        engineObj={this.state.engine}
                        toggleEditSideBar = {this.toggleEditSideBar}
                        createEngine = {this.props.createEngine}
                        activeTab={this.state.activeTab}
                        techConstants = {techConstants}
                        sRegionList = {sRegionList}
                        clients = {clients}
                        portfolioList={portfolioList}
                        operators = {operators}
                        ownersList={ownersList}
                        engineTypes= {engineTypes}
                        lessorList={[...this.props.lessorList,{id: 'newlessor',lessor_type: {value: 1, label: "Managed Lessor"},label: "Invite/Create Lessor",value: 'newlessor', name: "Invite/Create Lessor",slug: "newlessor"}]}
                    />
                    :null
                  }
                </EditSidebar>
                {
                  licenseModalFlag ?
                  <Modal
                    id="buyLicenseModal"
                    size="lg"
                    style={{ minWidth: '500px' }}
                    isOpen={licenseModalFlag}
                    centered={true}
                    toggle={this.props.closeLicenceModal}
                  >
                  <ModalBody>
                    <div align="center">
                      <p style={{marginBottom:'20px', marginTop:'20px'}}>{licenceModalMessage}</p>
                        <p align="center" style={{marginBottom:'20px'}}>Please contact
                          <a href="mailto:sales@acumen.aero" target="_blank"> sales@acumen.aero</a> /
                          <a href="mailto:support@sparta.aero" target="_blank"> support@sparta.aero</a>
                        </p>
                      <Button variant="contained" color="primary" style={{marginBottom:'20px'}} onClick={this.props.closeLicenceModal} >OKAY</Button>
                    </div>
                  </ModalBody>
                  </Modal> : null
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    assetLists: state.userAssetReducer.assetLists,
    apuTypesList: state.userAssetReducer.apuTypesList,
    assetFilter: state.userAssetReducer.assetFilter,
    assetCounts: state.userAssetReducer.assetCounts,
    lessorList: state.userAssetReducer.lessorList,
    clients: state.userAssetReducer.clients,
    operators: state.userAssetReducer.operators,
    aircraftTypes: state.userAssetReducer.aircraftTypes,
    moduleTypes:state.userAssetReducer.moduleTypes,
    engineTypes: state.userAssetReducer.engineTypes,
    paginationInfo: state.userAssetReducer.paginationInfo,
    listLoader: state.userAssetReducer.listLoader,
    licenseModalFlag: state.userAssetReducer.licenseModalFlag,
    licenceModalMessage: state.userAssetReducer.licenceModalMessage,
    techConstants: state.sharedReducers.techConstants,
    aircraftManufacturerList: state.sharedReducers.aircraftManufacturerList,
    maintenanceProgGrp: state.TechSummaryReducer.maintenanceProgGrp,
    sManufacruerList : state.sharedReducers.sManufacruerList,
    sRegionList: state.sharedReducers.sRegionList,
    assetTransferUsage: state.TechSummaryReducer.assetTransferUsage,
  	buyLicenceModal: state.sharedReducers.buyLicenceModal,
    portfolioList: state.userAssetReducer.portfolioList,
    apuCrud: state.TechSummaryReducer.apuCrud,
    apucraftManufacturerList: state.sharedReducers.apucraftManufacturerList,
    ownersList:state.sharedReducers.ownersList,
    filteredAircraftTypeList:state.userAssetReducer.filteredAircraftTypeList,
    filteredLesseeList:state.userAssetReducer.filteredLesseeList,
    filteredEngTypeList:state.userAssetReducer.filteredEngTypeList,
    filteredApuTypeList:state.userAssetReducer.filteredApuTypeList,
});
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setLoader: () => dispatch({
            type: LIST_LOADER,
            payload: true
        }),
        fetchAssetLists: (filterParams, allTypeFlag) => dispatch(assetsListAc(filterParams, allTypeFlag)),
        updateAssetFilter: (value, type) => dispatch({
            type: ASSET_FILTER_UPDATE,
            payload: {value, type},
        }),
        emptyAssetFilter: () => dispatch({
            type: ASSET_FILTER_EMPTY,
            payload: {},
        }),
        updateAssetSort: (type) => dispatch({
            type: ASSET_SORT_UPDATE,
            payload: type
        }),
        closeLicenceModal:() => dispatch({
          type:TOGGLE_LICENCE_MODAL,
          payload:false,
          message:''
        }),
        assetView: (asset) => dispatch(assetViewAc(asset)),
        fetchRegionList: () => dispatch(sRegionListAc()),
        fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
        createAsset: (data) => dispatch(AddAssetAc(data)),
        createEngine: (data) => dispatch(AddEngineAc(data)),
        createModule: (data) => dispatch(AddModuleAc(data)),
        downLoadAsset: (fileType, filterParam) => dispatch(downLoadAssetAc(fileType, filterParam)),
        fetchMaintenanceGrp: (asset_type) => dispatch(aircraftMaintenanceAc(asset_type)),
        fetchManufacturer: (groupType, assetTypeId) => dispatch(sManufacturerAc(groupType, assetTypeId)),
        importAsset: (file) => dispatch(importAssetAc(file)),
        getLessorList: () => dispatch(getLessorListAc()),
        getOwnersList: () => dispatch(getOwnerAc()),
        buyLicence: (modalObj) => dispatch({
          type: BUY_LICENCE,
          payload: modalObj
        }),
        getAssetTranferUsage: () => dispatch(getAssetTranferUsageAc(ownProps.params)),
        getApuTypes: () => dispatch(getApuTypesAc()),
        addEditApu: (apu) => dispatch(addEditApuAc(apu)),
        addEditLg: (lg) => dispatch(addEditLgAc(lg)),
        addEditPropeller: (lg) => dispatch(addEditPropellerAc(lg)),
        exportMarkeTechSummery: (asset) => dispatch(exportMarkeTechSummeryAc(asset))
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetsListing));
