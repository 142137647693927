import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withRouter } from 'react-router';
import { AppCard } from '../../shared';
import { getLocalStorageInfo } from '../../utils'
import '../assets/styles/assetSwitcher.scss';
import projectManagementIcon from '../assets/img/ProjectsIcon.svg';
import recordDataroomIcon from '../assets/img/RecordsIcom.svg';
import maintenanceManagementIcon from '../assets/img/MaintenaceIcon.svg';
import mrCalcIcon from '../assets/img/acumen_commverge.png';
import aircraftValuationIcon from '../assets/img/valuation_new.png';
import config from '../../config';
class ApplicationSwitcher extends Component{
  constructor(props){
    super(props);
    this.state={
        modal: true,
    }
    this.toggle = this.toggle.bind(this);
  }
  toggle(){
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  render(){
    const closeBtn = <button className="close" onClick={this.props.toggle}>&times;</button>;
    return(
      <Modal isOpen={this.props.open} toggle={this.props.toggle} className="app-switcher-modal">
        <ModalHeader toggle={this.props.toggle} close={closeBtn}>Applications</ModalHeader>
        <ModalBody>
        <section className="application-switcher">
          <Row className="app-card-row">
            <AppCard
                  label="Projects Management"
                  content="Comprehensive solution for planning, executing and managing Technical Projects - from Pre-Purchase to Redeliveries."
                  icon={<img width="50" src={projectManagementIcon} alt="Icon"/>}
                  enabled={getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.technical}
                  url={`${config.domain.projects}fleet/technical/dashboard`}
              />
              <AppCard
                  label="Records Dataroom"
                  content="Centralised, secure online Documents Management System (DMS) for digitised aircraft
                  records storage retrieval and collaboration"
                  icon={<img width="50" src={recordDataroomIcon} alt="Icon"/>}
                  enabled={getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.records}
                  url={`${config.domain.records}dashboard`}
              />
              <AppCard
                  label="Maintenance Claim Management"
                  content="Seamlessiy track, execute and manage maintenance events across the fleet, forecast future events and process commercial claims."
                  icon={<img width="50" src={maintenanceManagementIcon} alt="Icon"/>}
                  enabled={getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance}
                  url={`${config.domain.maintenance}`}
                  />
              <AppCard
                  label="CommVerge"
                  content="A potent decision application for computing commercial exposure and simulating complex scenarios on ~200 aircraft - engine combinations."
                  icon={<img width="50" src={mrCalcIcon} alt="Icon"/>}
                  enabled={getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.mr_calculator}
                  url={`${config.domain.commverge}logs`}
              />
              <AppCard
                  label="fin-S"
                  content="Access values for a wide variety of aircraft, powered by expertise and experience of ISTAT and ASA Certified Appraisers."
                  icon={<img width="50" src={aircraftValuationIcon} alt="Icon"/>}
                  enabled={getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.valuation}
                  url={`${config.domain.valuation}logs`}
              />
          </Row>
        </section>
        </ModalBody>
      </Modal>
    )
  }
}
export default withRouter(ApplicationSwitcher);
