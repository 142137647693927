
import React, { Component, useState } from 'react';
import { TableRow, TableCell, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Tooltip, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { displayDateFormat, displayDateFormatShort, statusLabel } from '../../../constants';
import { checkPermission, getLocalStorageInfo } from '../../../utils_v2';
import { PageLoader } from '../../../shared_elements';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat } from '../../../constants';

export default class ContractList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: {
        dateChange: false,
        lease_end_date: '',
        value: { status: 1, label: 'On Lease' },
        status_change_reason: ''
      }
    }
  }
  handleClickOpen = () => {
    if(this.props?.contract.asset?.status == 5){
      console.log('');
    }
    else{
      this.setState({ open: true, dateChange: false, data: { value: { status: 1, label: 'On Lease' }, status_change_reason: '' } });
    }
  }
  handleClose = () => {
    this.setState({ open: false });
  };
  updateForm = (type, value) => {
    this.setState(prevState => ({
      ...prevState, data: {
        ...prevState.data,
        [type]: value
      }
    }))
    if (type == 'lease_end_date') {
      this.setState({ dateChange: true })
    }
  }
  render() {
    const { contract, viewContract, changeStatusApi, formSubmitLoader } = this.props
    const { open, data, dateChange } = this.state
    return (
      <TableRow hover tabIndex={-1} style={{ cursor: 'pointer' }}>
        {formSubmitLoader ? <PageLoader /> : null}
        <TableCell onClick={viewContract}>{contract.lease_type.label}</TableCell>
        <TableCell>
          {
            contract.lessee.logo ?
              <img style={{ maxWidth: '75px' }} src={contract.lessee.logo} alt={contract.lessee.name} />
              : contract.lessee.name
          }
        </TableCell>
        {
          getLocalStorageInfo().defaultLessor.id === 442 ?
            <Tooltip title="MM/DD/YYYY" placement='top' arrow >

              <TableCell onClick={viewContract}>{`${moment(contract.contractual_start_date).format('L')} - ${moment(contract.contractual_end_date).format('L')}`}
                <p>{contract.lease_term_phrase}</p>
              </TableCell>
            </Tooltip>
            :
            <TableCell onClick={viewContract}>{`${moment(contract.contractual_start_date).format(displayDateFormatShort)} to ${moment(contract.contractual_end_date).format(displayDateFormatShort)}`}</TableCell>
        }
        {
          getLocalStorageInfo().defaultLessor.id === 442 ?
            <>
              <TableCell onClick={viewContract}>{contract.rental_invoice_due_day_of_month}</TableCell>
              <TableCell onClick={viewContract}>{contract.mr_invoice_due_day_of_month}</TableCell>
            </>
            : null
        }
        <TableCell>
          { contract.lease_status.value == 1 ?
                checkPermission('contracts','lease_details','U')?
                  <span onClick={this.handleClickOpen} className={`status-label status-1`}>
                    {contract.lease_status.label}<i class="fa fa-caret-down" aria-hidden="true"></i>
                  </span>
                  :
                  <span className={`status-label status-1`}> {contract.lease_status.label} </span>
              :
              contract.lease_status.value == 2 ?
                <span className={`status-label status-2`}>
                  {contract.lease_status.label}
                </span> :
                contract.lease_status.value == 3 ?
                  <span className={`status-label status-4`}>
                    {contract.lease_status.label}
                  </span> :
                  contract.lease_status.value == 0 ?
                  <span className={`status-label status-0`}>
                    {contract.lease_status.label}
                  </span> :
                  <span className={`status-label status-3`}>
                    {contract.lease_status.label}
                  </span>
          }
        </TableCell>
        <Dialog
          aria-labelledby="change-status-modal"
          open={open}
          maxWidth='sm'
          fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Change Lease Status
          </DialogTitle>
          <DialogContent dividers>
            <Grid className='lease-status-change' container spacing={2}>
              <Grid item xs={data.value && data.value.status != 1 ? 6 : 12}>
                <Autocomplete
                  disableClearable={true}
                  options={[{ status: 2, label: 'Off Lease' }, { status: 3, label: 'In Storage' }, { status: 4, label: 'Written Off/Sold/Part out' }]}
                  getOptionLabel={option => option.label}
                  id="value"
                  value={data.value}
                  defaultValue={{ status: 1, label: 'On Lease' }}
                  renderInput={params => <TextField   {...params} label="Lease Status" placeholder="Select Lease Status" margin="normal" />}
                  onChange={(e, value) => this.updateForm('value', value)}
                />
              </Grid>
              {
                data.value && data.value.status != 1 ?
                  <>
                    <Grid item xs={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          required
                          margin="normal"
                          id="lease_end_date"
                          label="Lease End Date"
                          format={fieldDateFormat}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ readOnly: true }}
                          clearable={true}
                          value={data.lease_end_date ? data.lease_end_date : contract.contractual_end_date}
                          onChange={(e) => { this.updateForm('lease_end_date', moment(e).format(backendDateFormat)); }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item sm={12}>
                      {
                        dateChange ?
                          <div className='notice-info' style={{ marginLeft: '0px', marginTop: '5px' }}>
                            Note: Both Rental End Date and MR End date will be changed to <b style={{ color: '#0963b0' }}>{moment(data.lease_end_date).format(displayDateFormat)} </b>
                          </div>
                          : null
                      }
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="status_change_reason"
                        label="Reason of Status Change"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={2}
                        inputProps={{ maxLength: 255 }}
                        value={data.status_change_reason}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => this.updateForm('status_change_reason', e.target.value)}
                      />
                    </Grid>
                  </>
                  : null
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { changeStatusApi(contract, data); this.handleClose() }} color="primary" variant="contained" disabled={data.value && data.value.status == 1} >Save</Button>
            <Button autoFocus onClick={this.handleClose} color="primary" variant="outlined">Cancel</Button>
          </DialogActions>
        </Dialog>
      </TableRow>

    )
  }
}