
import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { checkPermission } from '../../../../utils_v2';
import ReturnCondCRU from './ReturnCondCRU';
export default function ReturnCndList({item, previewDetail, getResponseBack, contractClauseList, contractInfo,deleteReturnCon}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell style={{maxWidth:'150px'}}>{item.asset_conditions_for_contract.name}</TableCell>
      <TableCell style={{maxWidth:'400px'}}>{item.terms ? item.terms:'--'}</TableCell>
      <TableCell>{item.flags ? 'Yes':'No'}</TableCell>
      <TableCell>
        <ReturnCondCRU contractInfo={contractInfo} id={item.id} contractClauseList={contractClauseList} getResponseBack={getResponseBack} m="V" />
        {checkPermission('contracts','return_conditions', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deleteReturnCon(item.id)}/>: null}
        </TableCell>
    </TableRow>
  )
}
