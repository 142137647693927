import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import { checkPermission } from '../../../../utils_v2';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import { displayDateFormatShort } from '../../../../constants';
import QfdMntEventCRU from './QfdMntEventCRU'
export default function QfdMntEventList({item, previewDetail, reserveAccounts, getResponseBack, contractInfo,deleteQfdMntEvents}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.reserve_account.name}</TableCell>
      <TableCell>{item.event ? item.event:'--'}</TableCell>
      <TableCell style={{maxWidth:'400px'}}>{item.qualified_items}</TableCell>
      <TableCell>
        {item.start_date ? moment(item.start_date).format(displayDateFormatShort): ''} - {item.end_date ? moment(item.end_date).format(displayDateFormatShort):''}
      </TableCell>
      <TableCell>
        <QfdMntEventCRU contractInfo={contractInfo} id={item.id} m="V" reserveAccounts={reserveAccounts} getResponseBack={getResponseBack}  />
        {checkPermission('contracts','qualified_maintenance_event', 'D') ? <DeleteIcon fontSize='small' color='secondary' style={{cursor:'pointer'}} onClick={()=>deleteQfdMntEvents(item.id)}/>: null}
      </TableCell>
    </TableRow>
  )
}
