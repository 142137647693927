import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { getLocalStorageInfo } from '../../../utils'
import InvFields from '../../../applications/contracts/Invoice/components/InvFields'

export default class LessorInfo extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const { type, invoiceInfo } = this.props
    if(type === 'view'){
      return(
        <Col md="6" className="padding-right-0">
          <Row className="inner-wrap"
            style={{background: '#fafafa', padding: '0 15px', marginRight: '15px'}}
            >
            <Col md="12" className="heading-block padding-left-0" style={{color:'#9439e4', padding:'15px'}}>
              <h4 className="para-ui">From (Lessor)</h4>
            </Col>
            <InvFields label = "Name" colSize = "12" padding = 'padding-left-0' value={invoiceInfo.from_company_name ? invoiceInfo.from_company_name:'--'}  />
            <InvFields label = "Address" colSize = "12" padding = 'padding-left-0' value={invoiceInfo.from_company_address ? invoiceInfo.from_company_address:'--'}  />
            {![14, 242].includes(getLocalStorageInfo().defaultLessor.id) ?
              <InvFields label = "Contact (Email and Phone)"  colSize = "12" padding = 'padding-left-0' value={invoiceInfo.from_company_contact? invoiceInfo.from_company_contact:'--'}  />:null
            }

          </Row>
        </Col>
      )
    }else{
      return(
        <Col md={{ size: 5 }} className="padding-right-0">
          <Row className="inner-wrap">
            <Col md={12} className="heading-block padding-left-0" style={{color:'#9439e4', padding:'15px'}}>
              <h4 className="para-ui">From (Lessor) </h4>
            </Col>
            <Col md="12 " className="padding-left-0">
              <div className="form-group">
                <label className="label"> Name</label>
                <input className='assembly-invoice-lessor-input' type="text" value={invoiceInfo.from_company_name}
                   onChange={(e) => this.props.updateInvoiceField(e.target.value, 'from_company_name')}
                />
              </div>
            </Col>
            <Col md="12" className="padding-left-0">
              <div className="form-group">
                <label className="label"> Address</label>
                <textarea className='assembly-invoice-lessor-input' type="text" value={invoiceInfo.from_company_address}
                onChange={(e) => this.props.updateInvoiceField(e.target.value, 'from_company_address')}
                ></textarea>
              </div>
           </Col>
           <Col md="12" className="padding-left-0">
            <div className="form-group">
              <label className="label"> Contact (Email and Phone)</label>
              <input className='assembly-invoice-lessor-input' type="text" value={invoiceInfo.from_company_contact}
               onChange={(e) => this.props.updateInvoiceField(e.target.value, 'from_company_contact')}
              />
            </div>
          </Col>
          </Row>
        </Col>
      )
    }
  }
}
