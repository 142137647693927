import React from 'react';
import { Paper,Divider } from '@material-ui/core';
import { FilterComponent } from '../../../shared_elements';
import Skeleton from '@material-ui/lab/Skeleton';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ownerGraphFilterOps } from '../'
import { removeEmptyKey } from '../../../utils_v2'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
Highcharts.setOptions({
    lang: {
      numericSymbols: ["k", "M", "B", "T"],
      thousandsSep: ","
    }
});
const CashflowLessorGraph = ({cashflowlessorGraphData, skeletonLoader, filter, getResponseBack, callExternalFunction}) => {
    let filterOptions = Object.assign({}, ownerGraphFilterOps);
    const options = {
        chart: {
            zoomType: 'xy',
            panning: true,
            panKey: 'shift'
        },
        title: {
            text: 'Based on Lessor',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        lang: {
            noData: filter && Object.keys(removeEmptyKey(filter)).length ? '' : 'No Data to Display'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b><br/>',
            valuePrefix: 'US$',
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px'
            }
        },
        subtitle: {
            text: `${cashflowlessorGraphData.title ? cashflowlessorGraphData.title : ''}`,
            align: 'left'
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Amount(US$)'
            }
        },
        xAxis: {
            categories: cashflowlessorGraphData && cashflowlessorGraphData.cashflow_categories && cashflowlessorGraphData.cashflow_categories.x_axis && cashflowlessorGraphData.cashflow_categories.x_axis.categories
        },
        legend: {
            enabled: true
        },
        plotOptions:{
            series:{
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        callExternalFunction(event.point.series.name, event.point.category, 'basedOnLessor');
                    }
                }
            }
        },
        series: cashflowlessorGraphData && cashflowlessorGraphData.cashflow,
        credits: false,
        exporting: {
            buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadPDF'],
                },
            }
         }
    }

    return (
        skeletonLoader ? <Skeleton variant="rect" height={440} width='100%' /> : 
        <Paper style={{minHeight:'460px'}}>
            <div style={{padding:'0 5px'}}>
                <FilterComponent 
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => getResponseBack(applyFilter)}
                />
            </div>
            <Divider />
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </Paper>
    )
}
export default CashflowLessorGraph
