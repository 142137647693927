import React from 'react';
import { TableRow, TableCell, Tooltip, IconButton } from '@material-ui/core';
import { checkPermission, getLocalStorageInfo } from '../../../../utils_v2';
import HistoryIcon from '@material-ui/icons/History';
import DeleteIcon from '@material-ui/icons/Delete';
import { RevisionHistory } from '../../../../shared_elements';
import ObligationCRU from './ObligationCRU';
import ChangeStatusModal from './ChangeStatusModal';
export default function ObligationList({item, previewDetail, approvedUser, pltConstants, getResponseBack, contractInfo,deleteObligation}){
  return(
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.obligation_type ? item.obligation_type.label : '--'}</TableCell>
      <TableCell>{item.details ? item.details : '--'}</TableCell>
      <TableCell>{item.value ? item.value : '--'}</TableCell>
      <TableCell className="flex-centered">
        {item.status ? item.status.label : '--'}
        { item.status && item.status.value === 0 && (getLocalStorageInfo().user.id == item.to_be_approved_by.id) && checkPermission('contracts','obligations', 'U') ?
          <span>
            <ChangeStatusModal id={item.id} m="V" approvedUser={approvedUser} pltConstants={pltConstants} getResponseBack={getResponseBack}/>
          </span>:null
        }
      </TableCell>
      <TableCell>{item.created_by ? item.created_by.name : '--'}</TableCell>
      <TableCell>{item.to_be_approved_by ? item.to_be_approved_by.name : '--'}</TableCell>
      <TableCell>
        <ObligationCRU id={item.id} m="V" approvedUser={approvedUser} pltConstants={pltConstants} getResponseBack={getResponseBack} contractInfo={contractInfo} />
        {checkPermission('contracts','obligations', 'D') ? <DeleteIcon fontSize='small' color='secondary' onClick={()=>deleteObligation(item.id)}/>: null}
        </TableCell>
    </TableRow>
  )
}
