import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip, IconButton, Drawer } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { LabelValueCard, RevisionHistory } from '../../../../shared_elements';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { capitalizeFirstLetter, checkApiStatus, getLocalStorageInfo, checkPermission } from '../../../../utils_v2';
import { fieldValidation, dateRangeValidation } from '../../../../utils_v2/formValidation';
import { addEditQfdMntEventsApi, getQfdMntEventsApi } from '../apiServices';
import { errorCode, addQfdMntEvent } from '../';
class QfdMntEventCRU extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      formSubmitLoader:false,
      mode:'',
      qfdMntEvent: {},
      error:{}
    }
    this.addEditQfdMntEventsApi = addEditQfdMntEventsApi.bind(this);
    this.getQfdMntEventsApi = getQfdMntEventsApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      qfdMntEvent: {
        ...prevState.qfdMntEvent,
        [keyParam]:data
      }
    }))
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:message
      }
    }))
  }
  addEditQfdMntEventFn = () => {
    const { qfdMntEvent } = this.state;
    let validationInputs = {
      reserve_account:errorCode['reserve_account'][fieldValidation({...errorCode['reserve_accountObj'], fieldval: qfdMntEvent.reserve_account && qfdMntEvent.reserve_account.id ? qfdMntEvent.reserve_account.id.toString():'' })],
      qualified_items:errorCode['qualified_items'][fieldValidation({...errorCode['qualified_itemsObj'], fieldval: qfdMntEvent.qualified_items})],
    };
    if(qfdMntEvent.start_date && qfdMntEvent.start_date){
      validationInputs = {
        ...validationInputs,
        start_date: dateRangeValidation(qfdMntEvent.start_date, qfdMntEvent.end_date, 30)
      }
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.setState({formSubmitLoader:true});
      let payload = Object.assign({}, qfdMntEvent)
      payload = {
        ...payload,
        'reserve_account_id': qfdMntEvent.reserve_account.id
      }
      this.addEditQfdMntEventsApi(this.props, payload)
      .then(response => {
        this.setState({formSubmitLoader:false});
        if(checkApiStatus(response)){
          this.setState({modal:false, mode:'', qfdMntEvent:{}, error:{}});
          this.props.getResponseBack();
          this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
    }else{
      this.setState({error:validationInputs});
    }
  }
  render(){
    const { modal, formSubmitLoader, mode, qfdMntEvent, error } = this.state;
    const { id, m, reserveAccounts, contractInfo } = this.props;
    return(
      <Fragment>
        { id ?
          <span onClick={() => this.getQfdMntEventsApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>:
          contractInfo?.hdlineInfo?.archived_asset ?
          null:
          <Button onClick={() => this.setState({modal:true, qfdMntEvent:addQfdMntEvent, mode:'add'})} variant="contained" color="primary"><AddIcon fontSize='small'/>Add Qualified Maintenance Event</Button>
        }
        { modal ?
          <Drawer
            open={modal}
            anchor="right"
            onClose={() => this.setState({modal:false, mode:'', qfdMntEvent:{}, error:{}})}
          >
            <div style={{width:'670px'}} className="drawer-container">
              <div className="drawer-header" alignItems='center'>
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Qualified Maintenance Event`}</span>
                <CloseIcon className="close-icon" onClick={()=> this.setState({modal:false})}/>
                { mode !== 'add' ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item">
                      <RevisionHistory
                        url='audit/contracts/ctqualifiedmaintenanceevents/'
                        queryParams={{action:1, object_id:id}}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      {contractInfo?.hdlineInfo?.archived_asset == false && checkPermission('contracts','qualified_maintenance_event', 'U') && mode === 'view' && <span className="cta" onClick={() => this.setState({mode:'edit'})}><EditIcon className="edit-icon"/>Edit</span>}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({mode:'view'})}><VisibilityIcon className="view-icon"/>View</span>}
                    </li>
                  </ul>:null
                }
              </div>
              <div className="drawer-body">
                <Grid container spacing={2}>
                  { mode === 'view' ?
                    <LabelValueCard md={6} xs={12} label='Reserve Account' value={qfdMntEvent.reserve_account && qfdMntEvent.reserve_account.name ? qfdMntEvent.reserve_account.name:'--'} /> :
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        options = {reserveAccounts}
                        getOptionLabel={option => option.name}
                        id="reserve_account"
                        value={qfdMntEvent.reserve_account ? qfdMntEvent.reserve_account : null}
                        onChange={(e, value) => {this.onFieldChange(e, 'reserve_account', value);}}
                        renderInput={params => <TextField required onFocus={(e) => this.updateErrorField('reserve_account', '')} error={error.reserve_account ? true:false} helperText={error.reserve_account? error.reserve_account:''} {...params} label="Reserve Account" placeholder="Select Reserve Account" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} xs={12} label='Event' value={qfdMntEvent.event ? qfdMntEvent.event:'--'} /> :
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="event"
                        label="Event"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 255 }}
                        value={qfdMntEvent.event ? qfdMntEvent.event:''}
                        onChange={(e, value) => this.onFieldChange(e, 'event', e.target.value)}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={12} xs={12} label='Qualified Items' value={qfdMntEvent.qualified_items ? qfdMntEvent.qualified_items:'--'} /> :
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="qualified_items"
                        label="Qualified Items *"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 255 }}
                        value={qfdMntEvent.qualified_items ? qfdMntEvent.qualified_items:''}
                        error={error.qualified_items ? true:false }
                        onChange={(e, value) => this.onFieldChange(e, 'qualified_items', e.target.value)}
                        InputLabelProps={{shrink: true}}
                        helperText={error.qualified_items ? error.qualified_items: ''}
                        onFocus={(e) => this.updateErrorField('qualified_items', '')}
                      />
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} xs={12} label='Validity	Start Date' value={qfdMntEvent.start_date ? moment(qfdMntEvent.start_date).format(displayDateFormatShort):'--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           margin="normal"
                           id="start_date"
                           label="Validity	Start Date"
                           format={fieldDateFormat}
                           value={qfdMntEvent.start_date ? moment(qfdMntEvent.start_date):null}
                           onChange={(data, value) => this.onFieldChange(value, 'start_date', moment(data).format(backendDateFormat))}
                           fullWidth
                           inputProps={{readOnly: true}}
                           clearable={true}
                           error={error.start_date ? true:false }
                           helperText={ error.start_date ? error.start_date:''}
                           InputLabelProps={{shrink: true}}
                           onFocus={(e) => this.updateErrorField('start_date', '')}
                         />
                        </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} xs={12} label='Validity End Date' value={qfdMntEvent.end_date ? moment(qfdMntEvent.end_date).format(displayDateFormatShort):'--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           margin="normal"
                           id="end_date"
                           label="Validity End Date"
                           format={fieldDateFormat}
                           value={qfdMntEvent.end_date ? qfdMntEvent.end_date:null}
                           onChange={(data, value) => this.onFieldChange(value, 'end_date', moment(data).format(backendDateFormat))}
                           fullWidth
                           inputProps={{readOnly: true}}
                           clearable={true}
                           InputLabelProps={{shrink: true}}
                         />
                        </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  { mode === 'view' ?
                    <LabelValueCard md={6} label='Remarks' value={qfdMntEvent.remarks ? qfdMntEvent.remarks:'--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        inputProps={{ maxLength: 255 }}
                        value={qfdMntEvent.remarks ? qfdMntEvent.remarks:''}
                        onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                        InputLabelProps={{shrink: true}}
                      />
                    </Grid>
                  }
                </Grid>
              </div>
              <div className="drawer-footer">
                { mode !== 'view' ?
                  <Button onClick={this.addEditQfdMntEventFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                    { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                  </Button>:null
                }
                <Button onClick={() => this.setState({modal:false, mode:'', qfdMntEvent:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer>:null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(QfdMntEventCRU));
