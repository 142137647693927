import React from 'react';
import { Paper,Divider, Grid, TextField } from '@material-ui/core';
import { FilterComponent } from '../../../shared_elements';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { utilsGraphFilterOps } from '../'
import { SignalCellularNull } from '@material-ui/icons';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const AnnualAvgUtilsGraph = ({ avgUtilsGraphData, onChangeAssetType, skeletonLoader, filter, getResponseBack, callExternalFunction }) => {
    var colors = ['#6395cf', '#ee7c26', '#beed87', '#00b3e6'];
    let filterOptions = Object.assign({}, utilsGraphFilterOps);
    filterOptions = {
        ...filterOptions,
        utils_from:{
            ...filterOptions.utils_from,
            format:'YYYY',
            views:['year'],
        },
        utils_till:{
            ...filterOptions.utils_till,
            format:'YYYY',
            views:['year'],
        }
    }
    const options = {
        chart: {
            type: 'spline',
            events: {
              drilldown: function(e) {
                if (!e.seriesOptions) {
                  var chart = this,
                    drilldowns = {
                      'Animals': {
                        name: 'Animals',
                        data: [
                          ['Cows', 2],
                          ['Sheep', 3]
                        ]
                      },
                      'Animals2': {
                        name: 'Animals',
                        color: Highcharts.getOptions().colors[1],
                        data: [
                          ['Cows', 22],
                          ['Sheep', 13]
                        ]
                      },
                      'Fruits': {
                        name: 'Fruits',
                        data: [
                          ['Apples', 5],
                          ['Oranges', 7],
                          ['Bananas', 2]
                        ]
                      },
                      'Fruits2': {
                        name: 'Fruits',
                        color: 'red',
                        data: [
                          ['Apples', 15],
                          ['Oranges', 17],
                          ['Bananas', 22]
                        ]
                      },
                      'Cars': {
                        name: 'Cars',
                        data: [
                          ['Toyota', 1],
                          ['Volkswagen', 2],
                          ['Opel', 5]
                        ]
                      },
                      'Cars2': {
                        name: 'Cars',
                        color: '#bada55',
                        data: [
                          ['Toyota', 11],
                          ['Volkswagen', 21],
                          ['Opel', 15]
                        ]
                      }
                    },
                    series = [drilldowns[e.point.name], drilldowns[e.point.name + '2']];
      
                  chart.addSingleSeriesAsDrilldown(e.point, series[0]);
                  chart.addSingleSeriesAsDrilldown(e.point, series[1]);
                  chart.applyDrilldown();
                }
      
              }
            }
          },
        title: {
            text: 'Annual Average Utilizations',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        subtitle: {
            text: `${avgUtilsGraphData.title ? avgUtilsGraphData.title : ''}`,
            align: 'left'
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Utilization'
            }
        },
        xAxis: {
            categories: avgUtilsGraphData && avgUtilsGraphData.yearly_categories && avgUtilsGraphData.yearly_categories.x_axis && avgUtilsGraphData.yearly_categories.x_axis.categories
        },
        plotOptions:{
            series:{
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        callExternalFunction(event.point.series.name, event.point.category, 'basedOnAnnual');
                    }
                }
            }
        },
        colors:colors,
        legend: {
            enabled: true
        },
        series: avgUtilsGraphData && avgUtilsGraphData.utilization_annual,
        drilldown: {
            series: [{
                name: 'Things',
                data: [{
                  name: 'Animals',
                  y: 5,
                  drilldown: true
                }, {
                  name: 'Fruits',
                  y: 2,
                  drilldown: true
                }, {
                  name: 'Cars',
                  y: 4,
                  drilldown: true
                }]
              }]
          },
        credits: false,
        exporting: {
            buttons: {
                contextButton: { menuItems: ['downloadPNG', 'downloadPDF'],
                },
            }
         }
    }
    return (
        skeletonLoader ? <Skeleton variant="rect" height={440} width='100%' /> : 
        <Paper>
            <div style={{padding:'3px 5px'}}>
                <FilterComponent 
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => getResponseBack(applyFilter)}
                />
            </div>
            <Divider/>
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </Paper>
    )
}
export default AnnualAvgUtilsGraph
