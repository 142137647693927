import React , { Component } from 'react';
import { Col } from 'reactstrap';
import VertCardLabel from './VertCardLabel';
import { getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import { imgStoragePath } from '../../../../constants';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
export default class LessorCard extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { lessorInfo } = this.props;
    return(
      <Col md="6" xs="12">
        <div className="vertical-card card-block">
          <h5 className="para">Lessor
            {
            lessorInfo?.archived_asset == false?
            checkPermission('contracts','lease_details', 'U') ?
              <img onClick = {this.props.toggleEditSideBar} width="16" className="editIcon" src={editIcon} alt="img"/>:null:null
            }
          </h5>
          <ul className="list-unstyled">
            <VertCardLabel label="Name" value={lessorInfo.lessor_name ? lessorInfo.lessor_name : '-'} />
            <VertCardLabel label="SPV" value={lessorInfo.lessor_special_purpose_vehicle ? lessorInfo.lessor_special_purpose_vehicle : '-'} />
            <VertCardLabel label="Portfolio"  value={lessorInfo && lessorInfo.asset && lessorInfo.asset.owner_portfolio && lessorInfo.asset.owner_portfolio.name ? lessorInfo.asset.owner_portfolio.name : '--'  } />
            <VertCardLabel label="Jurisdiction" value={lessorInfo.lessor_jurisdiction ? lessorInfo.lessor_jurisdiction : '-'} />
            <VertCardLabel label="Contact Address" value={lessorInfo.lessor_contact_address ? lessorInfo.lessor_contact_address : '-'} />
            <VertCardLabel label="Contact (Email and Phone)" value={lessorInfo.lessor_contact_details ? lessorInfo.lessor_contact_details : '-'} />
            <VertCardLabel label="Owner" value={lessorInfo.owner_name ? lessorInfo.owner_name : '-'} />
          </ul>
         </div>
      </Col>
     )
  }
}
