import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
import { InfoIcon, ToolTipHover } from '../../../../shared';
import addIcon from '../../../../shared/assets/img/add_icon.svg';
export default class LLPStatusCard extends  Component{
  constructor(props){
    super(props);
    this.state = {
      restorationCard: true,
      llpReplacementCard: true
    }
  }
  hideShowFn = (type) => {
    this.setState(prevState => ({
      ...prevState,
      [type]: !prevState[type]
    }));
  }
  changeWorkScopeFn = (wk, action) => {
    let wsArr = this.props.workScope.workscope;
    if(wk == '2' && action == 'del'){
      wsArr = wsArr.filter(val => val != 2);
    }
    if(wk == '2' && action == 'add'){
      wsArr = [...wsArr, 2];
    }
    if(wk == '3' && action == 'del'){
      wsArr = wsArr.filter(val => val != 3);
    }
    if(wk == '3' && action == 'add'){
      wsArr = [...wsArr, 3];
    }
    this.props.changeWorkScopeFn({
      workscopes: wsArr,
      engine_no: this.props.engineNo,
      shop_visit_no:this.props.index,
      log_id:this.props.logId
    });
  }
  render() {
    const  { workScope, engineNo, runType, logId ,index, selectLLPToReplace } = this.props;
    const prettyNumberParams = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: true, commafy: true}
    return(
      <Col md="4" className="col-spacing-wrap">
        <div className="wrapper-block">
          <div className="run-type-blk">
            <Row className="inner-row">
              <Col md="12">
                <h6>{workScope.name} Run</h6>
              </Col>
            </Row>
            <Row className="inner-row">
              <Col md="6">
                <span className="text-label">Life On Wing</span>
                <p className="para-ui">{Math.round(workScope.life_of_wings)} Hrs</p>
              </Col>
              <Col md="6">
                <span className="text-label">Shop Visit Date</span>
                <p className="para-ui">{workScope.nsv ? moment(workScope.nsv).format(displayDateFormat):'--'}</p>
              </Col>
            </Row>
          </div>
          <div className="workscope-blk">
           {workScope.life_limited_by_llp ? <span className="restricted-llp"> {workScope.life_limited_by_llp} </span> : null}

            <Row className="inner-row">
              <Col md="12">
                <span className="text-label">Workscope modules for shop visit</span>

                <Row className="workScope-select-block">
                  <Col md="4">
                    <label><input checked={true} defaultChecked={workScope.workscope.indexOf(1) != -1 ? true:false} type="checkbox"/> Core</label>
                  </Col>
                  <Col md="4">
                    <label><input checked={workScope.workscope.indexOf(2) != -1 ? true:false} onChange={(e) => this.changeWorkScopeFn('2', e.target.checked ? 'add':'del')} type="checkbox"/> LPT</label>
                  </Col>
                  <Col md="4">
                    <label><input type="checkbox" checked={workScope.workscope.indexOf(3) != -1 ? true:false} onChange={(e) => this.changeWorkScopeFn('3', e.target.checked ? 'add':'del')} /> Fan</label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="inner-row">
              <Col md="12">
                <span className="text-label">LLPs to be replaced in shop visit <InfoIcon id={"Workscope-modules-" + index}/> </span>
                <ToolTipHover placement="top" tagetId={"Workscope-modules-" +index}>
                  Life On Wing Collectively Limited by LLPs Life
                </ToolTipHover>
                <p className="para-ui">
                  {Object.keys(workScope.LLP).length + ' '}
                  LLPs Added

                  { selectLLPToReplace.loader && selectLLPToReplace.shop_visit_no == index ? <span style={{padding: '0 5px'}}>Loading...</span>: <img style={{cursor: 'pointer'}} onClick={() => this.props.selectLLPToReplaceFn('open', index, parseInt(logId), parseInt(engineNo), workScope.name)} src={addIcon} alt="icon" />  }
                </p>
              </Col>
            </Row>
          </div>
          <div className="restoration-blk">
            <div className="">
              <Row className="inner-row">
                <Col md="12">
                  <h6>Performance Restoration</h6>
                </Col>
              </Row>
              <Row className="inner-row">
                <Col md="6">
                  <span className="text-label">MR Rate</span>
                  <p className="para-ui">
                    {
                      workScope.engine_mr ?
                      <NumericLabel params={prettyNumberParams}>
                        {workScope.engine_mr}
                      </NumericLabel>:'--'
                    }
                    { workScope.engine_mr? ' / FH':''}
                  </p>
                </Col>
                <Col md="6">
                  <span className="text-label">Shop Visit Cost</span>
                  <p className="para-ui">
                    { workScope.engine_cost ?
                      <NumericLabel params={prettyNumberParams}>
                        {workScope.engine_cost}
                      </NumericLabel>:'--'
                    }
                  </p>
                </Col>
              </Row>
            </div>
            <div className="restoration-extra-info" style={ this.state.restorationCard ? { height: '0px', overflow: 'hidden' } : { height: 'auto', overflow: 'auto'} }>
              <Row className="inner-row">
                <Col md="6">
                  <span className="text-label">Funds Collected</span>
                  <p className="para-ui">
                    { workScope.remaining_engine_fund ?
                      <NumericLabel params={prettyNumberParams}>
                        {workScope.engine_fund}
                      </NumericLabel>: '--'
                    }
                  </p>
                </Col>
              </Row>
              <Row className="inner-row">
                <Col md="12">
                  <span className="text-label">Funds Difference</span>
                  <p className="para-ui">
                    { workScope.remaining_engine_fund?
                      <NumericLabel params={prettyNumberParams}>
                        {workScope.remaining_engine_fund}
                      </NumericLabel>: '--'
                    }
                  </p>
                </Col>
              </Row>
              <Row className="inner-row">
                <Col md="12">
                  <label>
                    <input type="checkbox" name="" checked={workScope.hasOwnProperty('carry_forward_engine_fund_status') ? workScope.carry_forward_engine_fund_status : true} onChange={(e) => this.props.carryOverNSVFn({carry_forward_engine_fund_status: e.target.checked,engine_no: parseInt(engineNo),shop_visit_no: index,log_id: parseInt(logId)})} /> <span>Carry over to NSV</span>
                  </label>
                </Col>
              </Row>
            </div>
            <span className="show-more" onClick={() => this.hideShowFn('restorationCard')}>{ this.state.restorationCard ? 'View More Details': 'View Less Details'}</span>
          </div>
          <div className="llp-replacement-blk">
            <div className="">
              <Row className="inner-row">
                <Col md="12">
                  <h6>LLP Replacement</h6>
                </Col>
              </Row>
              <Row className="inner-row">
                <Col md="6">
                  <span className="text-label">Bulk LLP MR Rate</span>
                  <p className="para-ui">
                    {
                      workScope.llp_bulk_mr ?
                      <NumericLabel params={prettyNumberParams}>
                        {workScope.llp_bulk_mr}
                      </NumericLabel>:'--'
                    }
                    { workScope.llp_bulk_mr ? ' / cycle' :''}
                  </p>
                </Col>

                <Col md="6" style={{paddingRight:'5px'}}>
                  <span className="text-label">LLP Replacement Cost</span>
                  <p className="para-ui">
                    { workScope.llp_replacement_cost ?
                      <NumericLabel params={prettyNumberParams}>
                        {workScope.llp_replacement_cost}
                      </NumericLabel>: '--'
                    }
                  </p>
                </Col>
              </Row>
            </div>
            <div className="llp-extra-info" style={ this.state.llpReplacementCard ? { height: '0px', overflow: 'hidden' } : { height: 'auto', overflow: 'auto' } }>
              <Row className="inner-row">
                <Col md="12">
                  <span className="text-label">Bulk LLP MR Funds Collected</span>
                  <p className="para-ui">
                    { workScope.llp_replacement_fund ?
                      <NumericLabel params={prettyNumberParams}>
                        {workScope.llp_replacement_fund}
                      </NumericLabel>:'--'
                    }
                  </p>
                </Col>
              </Row>
              <Row className="inner-row">
                <Col md="12">
                  <span className="text-label">Funds difference</span>
                  <p className="para-ui">
                    {
                      workScope.llp_replacement_fund_remaining ?
                      <NumericLabel params={prettyNumberParams}>
                        {workScope.llp_replacement_fund_remaining}
                      </NumericLabel>: '--'
                    }
                  </p>
                </Col>
              </Row>
              <Row className="inner-row">
                <Col md="12">
                  <label>
                    <input type="checkbox" name="" checked={workScope.hasOwnProperty('carry_forward_llp_fund_status') ? workScope.carry_forward_llp_fund_status :true} onChange={(e) => this.props.carryOverNSVFn({carry_forward_llp_fund_status: e.target.checked,engine_no: parseInt(engineNo),shop_visit_no: index,log_id: parseInt(logId)})} /> <span>Carry over to NSV</span>
                  </label>
                </Col>
              </Row>
            </div>
            <span className="show-more" onClick={() => this.hideShowFn('llpReplacementCard')}>{this.state.llpReplacementCard ? 'View More Details':'View Less Details' }</span>
          </div>
          <div className="total-amt-blk">
            <Row className="inner-row">
              <Col md="6">
                <span className="text-label">Total Funds Collected</span>
                <p className="para-ui">
                  { workScope.total_fund ?
                    <NumericLabel params={prettyNumberParams}>
                    {workScope.total_fund}
                  </NumericLabel>:'--'
                  }
                </p>
              </Col>

              <Col md="6">
                <span className="text-label">Total Shop Visit Cost</span>
                <p className="para-ui">
                  { workScope.total_cost ?
                    <NumericLabel params={prettyNumberParams}>
                    {workScope.total_cost}
                  </NumericLabel>:'--'
                  }
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    )
  }
}
