import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import { Button, Grid, TextField, CircularProgress, Drawer, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { LabelValueCard, RevisionHistory, PageLoader } from '../../../../shared_elements';
import NumericLabel from 'react-pretty-numbers';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../../constants';
import { capitalizeFirstLetter, checkApiStatus, getLocalStorageInfo, getCurrencyFormat, checkPermission } from '../../../../utils_v2';
import { addEditClaimApi, getClaimApi, getremainingIsuranceApi } from '../apiServices';
import { fieldValidation } from '../../../../utils_v2/formValidation';
import { addClaim, errorCode } from '../';
import { regexConstants } from '../../../../constants/regEx';
import AddCircleIcon from '@material-ui/icons/AddCircle';
class ClaimCRU extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      mode: '',
      formSubmitLoader: false,
      pageLoader:false,
      claim: {},
      remainingIsurance: [],
      error: {}
    }
    this.addEditClaimApi = addEditClaimApi.bind(this);
    this.getClaimApi = getClaimApi.bind(this);
    this.getremainingIsuranceApi = getremainingIsuranceApi.bind(this);
  }
  onFieldChange = (event, keyParam, data) => {
    if (keyParam == 'insurance_number') {
      this.setState(prevState => ({
        ...prevState,
        claim: {
          ...prevState.claim,
          insurer: data?.insurer||'',
          insuree: data?.insuree||'',
          policy_start_date: data?.policy_start_date||'',
          policy_end_date: data?.policy_end_date||'',
          insurance_number:data?.insurance_number||null
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        claim: {
          ...prevState.claim,
          [keyParam]: data
        }
      }))
    }
  }
  addButtonClick (){
    this.getremainingIsuranceApi();
    this.setState({ modal: true, claim: addClaim, mode: 'add' })
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  addEditClaimFn = () => {
    const { claim, remainingIsurance } = this.state;
    let validationInputs = {
      insurance_number: errorCode['insurance_number'][fieldValidation({ ...errorCode['insurance_numberObj'], fieldval: claim.insurance_number })],
      insurer: errorCode['insurer'][fieldValidation({ ...errorCode['insurerObj'], fieldval: claim.insurer })],
      claim_summary: errorCode['claim_summary'][fieldValidation({ ...errorCode['claim_summaryObj'], fieldval: claim.claim_summary })],
      claim_number: errorCode['claim_number'][fieldValidation({ ...errorCode['claim_numberObj'], fieldval: claim.claim_number })],
      amount: errorCode['amount'][fieldValidation({ ...errorCode['amountObj'], fieldval: claim.amount })],
      approver: errorCode['approver_id'][fieldValidation({ ...errorCode['approver_idObj'], fieldval: claim.approver })],
      date: errorCode['date'][fieldValidation({ ...errorCode['dateObj'], fieldval: claim.date })],
    };
    if(claim.status && claim.status.value === 4){
      validationInputs ={
        ...validationInputs,
        rejection_reason: errorCode['rejection_reason'][fieldValidation({ ...errorCode['rejection_reasonObj'], fieldval: claim.rejection_reason })],
      }
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      let payload = Object.assign({}, claim), insuranceNumber = '';
      if(remainingIsurance.length){
        insuranceNumber = remainingIsurance.find(item=> item.insurance_number === claim.insurance_number);
      }
      payload = {
        ...payload,
        status: claim.status && claim.status.value,
        remarks: claim.remarks,
        approver_id: claim.approver && claim.approver.id ? claim.approver.id : null ,
        insurance: claim.insurance?claim.insurance : insuranceNumber?.id||null,
      }
      if (this.state.mode !='add'){
        payload={
          ...payload,
          approver: claim.approver && claim.approver ? claim.approver : null ,
        }
      }
      this.addEditClaimApi(this.props, payload)
        .then(response => {
          if (checkApiStatus(response)) {
            this.setState({ modal: false, mode: '', claim: {}, error: {} });
            this.props.getResponseBack();
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            this.props.enqueueSnackbar(response?.data?.message?response.data.message:'', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        })
    } else {
      this.setState({ error: validationInputs });
      if(validationInputs.rejection_reason){
        this.props.enqueueSnackbar('Please enter Reason for Rejection', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
      else{
        this.props.enqueueSnackbar('Please fill all Required Fields', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      }
    }
  }
  render() {
    const { modal, mode, claim, formSubmitLoader, error,remainingIsurance, pageLoader } = this.state;
    const { id, m, pltConstants, contractInfo  } = this.props;
    var approvers = []
    this.props.approvers && this.props.approvers.map(user => {
      approvers.push({ id: user.id, name: user.first_name + ' ' + user.last_name })
    })
    var insurances = []
    this.props.insurances && this.props.insurances.map(insurer => {
      insurances.push({ id: insurer.id, label: insurer.insurance_number })
    })
    return (
      <Fragment>
       { pageLoader ? <PageLoader />:null}
        {id ?
          <span onClick={() => this.getClaimApi(this.props, id, m)}><Tooltip title="View"><VisibilityIcon color='primary' fontSize='small' style={{cursor:'pointer'}}/></Tooltip></span>
          :
          contractInfo?.hdlineInfo?.archived_asset == false?
          <span onClick={() => this.addButtonClick()} style={{ display: 'flex' }}>
            <AddCircleIcon color="primary" variant="contained" />
            Add
          </span>
          :  null
        }
        {modal ?
          <Drawer
            anchor="right"
            open={modal}
            onClose={() => this.setState({ modal: false, mode: '', claim: {}, error: {} })}
            aria-labelledby="scroll-dialog-title"
          >

            <div className="drawer-container" style={{ width: '670px' }}>
              <div className="drawer-header">
                <span className="left-blk">{`${capitalizeFirstLetter(mode)} Insurance Claim`}</span>
                <CloseIcon className="close-icon" onClick={() => this.setState({ modal: false })} />
                {mode !== 'add'  ?
                  <ul className="list-inline right-blk">
                    <li className="list-inline-item" style={{marginTop:'10px'}}>
                      <RevisionHistory
                        url='audit/contracts/ctinsuranceclaim/'
                        queryParams={{ action: 1, object_id: id }}
                        buttonType={false}
                      />
                    </li>
                    <li className="list-inline-item">
                      {  contractInfo?.hdlineInfo?.archived_asset == false? checkPermission('contracts','insurance', 'U') && mode === 'view' && claim.status.value===2 && <span className="cta" onClick={() => {this.setState({ mode: 'edit' });this.getremainingIsuranceApi(claim.insurance)}}><EditIcon className="edit-icon" />Edit</span>: null}
                      {mode !== 'view' && <span className="cta" onClick={() => this.setState({ mode: 'view' })}><VisibilityIcon className="view-icon" />View</span>}
                    </li>
                  </ul> : ''
                }
              </div>
              <div className="drawer-body">
                <Grid container spacing={3}>
                  { claim.status && claim.status.value !== 2 && mode !=='add'?
                    <Grid item xs={12} md={12}>
                      <span className="warning-info-text" style={{background:'#f3dc8b', padding:'4px 8px'}}>Please note Claim is editable only when In-Review</span>
                    </Grid>
                    :
                    null
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Insurance Number' value={claim.insurance_number ? claim.insurance_number : '--'} /> :
                    <Grid item xs={12} md={12}>
                      {(() => {
                        let optionValue = remainingIsurance.find(item => item.insurance_number === claim.insurance_number)
                        return(
                          <Autocomplete
                            options={remainingIsurance}
                            getOptionLabel={option => option.insurance_number}
                            id="insurance_number"
                            value={optionValue?optionValue:null}
                            onChange={(e, value) => this.onFieldChange(e, 'insurance_number', value)}
                            renderInput={params => <TextField required error={error.insurance_number ? true : false} helperText={error.insurance_number ? error.insurance_number : ''} onFocus={() => this.updateErrorField('insurance_number', '')} {...params} label="Insurance Number" placeholder="Select Insurance Number" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                          />
                        )
                      })()}
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Claim Number' value={claim.claim_number ? claim.claim_number : '--'} /> :
                    <Grid item xs={12} md={12}>
                      <TextField
                        required
                        id="claim_number"
                        label="Claim Number"
                        fullWidth
                        margin="normal"
                        value={claim.claim_number ? claim.claim_number : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'claim_number', e.target.value)}
                        error={error.claim_number ? true : false}
                        helperText={error.claim_number ? error.claim_number : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 30 }}
                        onFocus={() => this.updateErrorField('claim_number', '')}
                      />
                    </Grid>
                  }
                  {
                    claim.insurance_number ?
                      <>
                        <Grid item xs={12} md={6}>
                          <LabelValueCard md={12} label='Insurer' value={claim.insurer ? claim.insurer : '--'} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <LabelValueCard md={6} label='Insuree' value={claim.insuree ? claim.insuree : '--'} />
                        </Grid>
                      </>
                      : null}

                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Approver Name' value={claim.approver && claim.approver.name ? claim.approver.name : '--'} /> :
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        options={approvers}
                        getOptionLabel={option => option.name}
                        id="approver"
                        value={mode == 'edit' ? claim.approver: claim.approver }
                        onChange={(e, value) => this.onFieldChange(e, 'approver', value)}
                        renderInput={params => <TextField required error={error.approver ? true : false} helperText={error.approver ? error.approver : ''} onFocus={() => this.updateErrorField('approvers', '')} {...params} label="Approver Name" placeholder="Select Approver Name" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Claim Details' value={claim.claim_summary ? claim.claim_summary : '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        required
                        id="claim_summary"
                        label="Claim Details"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        value={claim.claim_summary ? claim.claim_summary : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'claim_summary', e.target.value)}
                        error={error.claim_summary ? true : false}
                        helperText={error.claim_summary ? error.claim_summary : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 255 }}
                        onFocus={() => this.updateErrorField('claim_summary', '')}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Date of Claim Closure' value={claim.date ? moment(claim.date).format(displayDateFormatShort) : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          required
                          margin="normal"
                          id="date"
                          label="Date of Claim Closure"
                          format={fieldDateFormat}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          error={error.date}
                          helperText={error.date ? error.date : ''}
                          value={claim.date ? claim.date : null}
                          onFocus={() => this.updateErrorField('date', '')}
                          onChange={(data, value) => this.onFieldChange(value, 'date', moment(data).format(backendDateFormat))}
                          onOpen={() => this.updateErrorField('date', '')}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Amount' value={claim.amount ? <NumericLabel params={getCurrencyFormat()}>{claim.amount}</NumericLabel> : '--'} /> :
                    <Grid item xs={12} md={6}>
                      <TextField
                        required
                        id="amount"
                        label="Amount"
                        fullWidth
                        margin="normal"
                        value={claim.amount ? claim.amount : ''}
                        onChange={(e, value) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'amount', e.target.value) : e.preventDefault() }}
                        error={error.amount ? true : false}
                        helperText={error.amount ? error.amount : ''}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 10 }}
                        onFocus={() => this.updateErrorField('amount', '')}
                      />
                    </Grid>
                  }
                  {mode === 'view' ?
                    <LabelValueCard md={6} label='Primary Contact' value={claim.primary_contact ? claim.primary_contact : '--'} /> :
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="primary_contact"
                        label="Primary Contact"
                        fullWidth
                        margin="normal"
                        value={claim.primary_contact ? claim.primary_contact : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'primary_contact', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 30 }}
                      />
                    </Grid>
                  }

                  {mode === 'view' ?
                    <LabelValueCard md={12} label='Remarks' value={claim.remarks ? claim.remarks : '--'} /> :
                    <Grid item xs={12}>
                      <TextField
                        id="remarks"
                        label="Remarks"
                        fullWidth
                        margin="normal"
                        multiline
                        rows="4"
                        value={claim.remarks ? claim.remarks : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'remarks', e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                  }
                  { mode==='view' ? <LabelValueCard md={6} label='Claim Status' value={claim.status && claim.status.label ? claim.status.label : '--'} /> :
                    claim.approver_id === getLocalStorageInfo().user.id ?
                    <Grid item xs={6} >
                      <Autocomplete
                        disableClearable={true}
                        options={[{value: 3, label: 'Approved'}, {value: 4, label: 'Rejected'}]}
                        getOptionLabel={option => option.label}
                        id="status"
                        value={claim.status ? claim.status : null}
                        onChange={(e, value) => this.onFieldChange(e, 'status', value)}
                        renderInput={params => <TextField required error={error.status ? true : false} helperText={error.status ? error.status : ''} onFocus={() => this.updateErrorField('status', '')} {...params} label="Status" placeholder="Select Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                      />
                    </Grid>:
                    null
                  }
                  {
                    mode ==='edit' && claim.status && claim.status.value === 4 ?
                    <Grid item xs={12} >
                      <TextField
                        required
                        id='rejection_reason'
                        label='Reason for Rejection'
                        fullWidth
                        margin='normal'
                        multiline
                        rows={3}
                        value={claim.rejection_reason}
                        helperText={error.rejection_reason ? error.rejection_reason : ''}
                        onChange={(e, value) => this.onFieldChange(e, 'rejection_reason', e.target.value)}
                        error={error.rejection_reason ? true : false}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>
                    : null
                  }
                </Grid>
              </div>
              <div className="drawer-footer">
                { mode !== 'view' ?
                  <Button onClick={this.addEditClaimFn} color="primary" variant="contained" disabled={formSubmitLoader}>
                    { formSubmitLoader ? <CircularProgress color="#ffffff" size={24} />:'Save Changes' }
                  </Button>:null
                }
                <Button onClick={() => this.setState({modal:false, mode:'', claim:{}, error:{}})} color="primary" style={mode == 'view' ? {marginLeft:'auto', paddingLeft:'0px'}:{marginLeft:'8px'}}>Cancel</Button>
              </div>
            </div>
          </Drawer> : null
        }
      </Fragment>
    )
  }
}
export default withRouter(withSnackbar(ClaimCRU));
