import React from 'react';
import * as actions from '../actions';
import { downloadFileType, toastFlashMessage, getLocalStorageInfo } from '../../utils';
import { browserHistory } from 'react-router';
import { globalGetService,globalStaticDownloadGetService, globalPostService,globalExportService, globalUnlimitedDownloadService, globalDownloadService, globalPutService, globalDownloadGetService, globalStaticPostService, globalStaticExportService } from '../../globalServices';
import { USER_CRUD_BAR, GET_USER_CRUD, SET_USER_DATA} from '../../User/actions';
import {setGlobalCookie,getGlobalCookie} from '../../utils';
import { trackLogOut, trackActivity } from '../../utils/mixpanel'
export const sRegionListAc = () => {
  return (dispatch) => {
    globalGetService('console/regions/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.S_REGION_LIST,
          payload: response.data.data.region_list
        });
      }
    })
  }
}
export const sEngineTypeAc = (params) => {
  return (dispatch) => {
    globalGetService('console/engine-types/',params)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.S_ENGINE_TYPE_LIST,
          payload: response.data.data.engineTypes
        });
      }
    })
  }
}
export const downloadInventoryAc = (params,data) => {
  let type = params.type == 'aircraft' ? 'MSN ' : params.type == 'fan' ? 'FAN ' : params.type == 'hpc' ? 'HPC ' : params.type == 'hpt' ? 'HPT ' : params.type == 'lpt' ? 'LPT ' : 'ESN ';
  let msn=params.type == 'aircraft' ? data.msn:data.esn;
  return (dispatch) => {
    dispatch({
      type: actions.DOWNLOAD_LOADER,
      payload: true
    });
    globalStaticDownloadGetService('records/'+params.type+'/'+params.aircraft_slug+'/export-inventory-report/?download=true',{})
    .then(response => {
      dispatch({
        type: actions.DOWNLOAD_LOADER,
        payload: false
      });
      if(response.headers['content-type'] != 'application/ms-excel' && response.headers['content-type'] != 'application/vnd.ms-excel'){
        toastFlashMessage("Can not download file", "error");
      }else{
        downloadFileType(response.data, ['fan', 'hpc', 'hpt', 'lpt'].includes(type) ? type+data.esn + ' Inventory Report.' :type+msn + ' Inventory Report.', 'xls')
      }
    })
  }
}
export const sArctTypesAc = () => {
  return (dispatch) => {
    globalGetService('console/aircraft-types/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.S_ARCT_TYPE_LIST,
          payload: response.data.data.aircraftTypes
        });
      }
    })
  }
}
export const sLessorListAc = () => {
  return (dispatch) => {
    globalGetService('console/lessors/',{})
    .then(response => {

      if(response.data.statusCode == 200){
        dispatch({
          type: actions.S_LESSOR_LIST,
          payload: response.data.data.lessors
        });
      }
    })
  }
}
export const sLesseeListAc = () => {
  return (dispatch) => {
    globalGetService('console/lessees/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.S_LESSEE_LIST,
          payload: response.data.data.lessees
        });
      }
    })
  }
}
export const sOperatorListAc = () => {
  return (dispatch) => {
    globalGetService('console/operators/',{})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.S_OPERATOR_LIST,
          payload: response.data.data.operators
        });
      }
    })
  }
}
// group_type, aircraft_type_id, engine_type_id
export const sManufacturerAc = (groupTypeParam, groupTypeId) => {
  return (dispatch) => {
    globalGetService('console/manufacturers/',{ [groupTypeParam]:groupTypeId})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.S_MANUFACTURER_LIST,
          payload: response.data.data
        });
      }
    })


  }
}
export const sGlobalConstantAc = (constantTypes) => {
  return (dispatch) => {
    globalPostService('console/get-constants/', constantTypes)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.S_GLOBAL_CONSTANTS,
          payload: {
            type: "techConstants",
            res: response.data.data
          }
        })
      }
    })
  }
}
export const sHeaderConstantAc = (constantTypes) => {
  return (dispatch) => {
    globalPostService('console/get-constants/', constantTypes)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.HEADER_GLOBAL_CONSTANTS,
          payload: {
            type: "techConstants",
            res: response.data.data
          }
        })
      }
    })
  }
}
export const sExportContractsAc = (basicInfo) => {
  return (dispatch) => {
    dispatch({
      type: actions.CONTRACT_EXPORT_LOADER,
      payload: true
    });
    globalExportService('/contracts/'+basicInfo.slug+'/export-contract-summary/',{download: 'pdf'})
    .then(response => {
      dispatch({
        type: actions.CONTRACT_EXPORT_LOADER,
        payload: false
      });

      if(basicInfo.asset_type == 2){
        downloadFileType(response, 'ESN'+(basicInfo.asset.asset_name ? basicInfo.asset.asset_name:''+'_'+basicInfo.lessee.name)+'_Lease.', 'pdf')
      }else if(basicInfo.asset_type == 3){
        downloadFileType(response, 'APU'+(basicInfo.asset.serial_number ? basicInfo.asset.serial_number:''+'_'+basicInfo.lessee.name)+'_Lease.', 'pdf')
      }else if(basicInfo.asset_type == 4){
        downloadFileType(response, 'LG'+(basicInfo.asset.serial_number ? basicInfo.asset.serial_number:''+'_'+basicInfo.lessee.name)+'_Lease.', 'pdf')
      }else if(basicInfo.asset_type == 5){
        downloadFileType(response, 'PROPELLER'+(basicInfo.asset.serial_number ? basicInfo.asset.serial_number:''+'_'+basicInfo.lessee.name)+'_Lease.', 'pdf')
      }else if (basicInfo.asset_type == 6) {
        downloadFileType(response, 'ESN' + (basicInfo.asset.asset_name + '_' + basicInfo.lessee.name) + '_Lease.', 'pdf')
      }else if (basicInfo.asset_type == 7) {
        downloadFileType(response, 'ESN' + (basicInfo.asset.asset_name + '_' + basicInfo.lessee.name) + '_Lease.', 'pdf')
      }else if (basicInfo.asset_type == 8) {
        downloadFileType(response, 'ESN' + (basicInfo.asset.asset_name + '_' + basicInfo.lessee.name) + '_Lease.', 'pdf')
      }else if (basicInfo.asset_type == 9) {
        downloadFileType(response, 'ESN' + (basicInfo.asset.asset_name + '_' + basicInfo.lessee.name) + '_Lease.', 'pdf')
      }else if (basicInfo.asset_type == 1) {
        downloadFileType(response, 'MSN' + basicInfo.lessee.name + '_Lease.', 'pdf')
      }
    });
  }
}
export const downLoadTechFormAc = (url,workOderSlug,fileType,fileName,queryParam=null) => {
  let filterParam = {
    download : fileType
  }
  delete filterParam['page'];

  return (dispatch) => {
    dispatch({
      type: actions.TECH_EXPORT_LOADER,
      payload: true
    });
    globalStaticExportService('technical/workorder/'+workOderSlug+'/'+url+'/', queryParam? queryParam: filterParam)
    .then(response => {
      dispatch({
        type: actions.TECH_EXPORT_LOADER,
        payload: false
      });
      downloadFileType(response, fileName+'.', fileType)
      trackActivity('Report Exported', {
        page_title: `Aircraft Forms`,
        application_module: 'Inspection',
        url: url,
        workorder_slug: workOderSlug,
        file_name: fileName,
        file_type: fileType,
      })
    })
  }
}
export const downLoadTechEngineFormAc = (url,workOderSlug,fileType,fileName) => {
  let filterParam ={};
  if(url==='shop-visit-details'){
    if(fileType !='pdf'){
    filterParam={
      download : 'pdf',
      summary_export_type:fileType
    }
  }else{
    filterParam={
      download : 'pdf',
    }
  }
  }else{
   filterParam = {
    download : fileType
  }
}
  delete filterParam['page'];

  return (dispatch) => {
    dispatch({
      type: actions.TECH_EXPORT_LOADER,
      payload: true
    });
    globalExportService('technical/engine-workorder/'+workOderSlug+'/'+url+'/', filterParam)
    .then(response => {
      dispatch({
        type: actions.TECH_EXPORT_LOADER,
        payload: false
      });
      downloadFileType(response, fileName+'.', 'pdf')
      trackActivity('Report Exported', {
        page_title: `Engine Forms`,
        application_module: 'Inspection',
        url: url,
        workorder_slug: workOderSlug,
        file_name: fileName,
        file_type: fileType,
      })
    })
  }
}
export const sAuditLogAc = (applicationUrl, contentType, action, objectId, type, paramValue = null, paramKey=null, workingGroup=null) => {
  return (dispatch) => {
    globalGetService('audit/'+applicationUrl+'/'+contentType+'/',
    { action, object_id : objectId, [paramKey] : paramValue, working_group:workingGroup})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.S_AUDITLOG_ADD_DEL_VIEW,
          payload: {type: type, data: response.data.data, flag: true }
        });
      }
    })
  }
}

export const getMsnSwitcherAc = () =>{
  return (dispatch) => {
    // const switcher = Object.assign([],getLocalStorageInfo().defaultLessor.assets);
    // switcher.map(item => {
    //     item.value = <span><span className="switcher-msn">{ item.asset_type == 1 ? `MSN` : item.asset_type == 2 ? `ESN` : item.asset_type == 3 ? `APU` : item.asset_type == 4 ? `LG` : `PROPELLER`}&nbsp;{item.asset_type == 1 ? item.msn: item.asset_type == 2 ? item.esn : item.serial_number}</span><span className="switcher-model">{(item.model != '' && item.model != null && item.model != undefined) ? ` : ${item.model}` : null}</span></span>;
    //     return item;
    // });
    // dispatch({
    //   type: actions.LOAD_MSN_SWITCHER,
    //   payload: switcher
    // });
    globalGetService('console/list/?switcher=true&all_assets=true',{})
    .then(response => {
      if(response.data.statusCode == 200){
        const switcher = Object.assign([],response.data.data.asset);
        const assetsType = {
          1: 'MSN',
          2: 'ESN',
          3: 'APU',
          4: 'LG',
          5: 'Propeller',
          6: 'Fan',
          7: 'HPC',
          8: 'HPT',
          9: 'LPT'
        }
        switcher.map(item => {
            item.value = <span><span className="switcher-msn">{assetsType[item.asset_type]}&nbsp;{item.asset_type == 1 ? item.msn: [2,6,7,8,9].includes(item.asset_type) ? item.esn : item.serial_number}</span><span className="switcher-model">{(item.model != '' && item.model != null && item.model != undefined) ? ` : ${item.model}` : null}</span></span>;
            return item;
        });
        dispatch({
          type: actions.LOAD_MSN_SWITCHER,
          payload: response.data.data.asset
        });
      }
    })
  }
}
export const emptyBucketAc = (params, files) => {
  const data={
    type: 'file',
    object_uuid:[],

  };
  files.map(item => data.object_uuid.push(item.uuid));
  return (dispatch) => {
        dispatch({
          type: actions.EMPTY_BUCKET,
          payload: {},
        })
      }
}
export const downloadBucketAc = (params, files, msn) => {
  const data={
    type: 'file',
    object_uuid:[]
  };
  files.map(item => data.object_uuid.push(item.uuid));
  return (dispatch) => {
    // dispatch({
    //   type: DIRECTORY_LOADER,
    //   payload: true
    // });
    globalDownloadService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/download/', data)
    .then(response => {
      if(response.data.statusCode){
        toastFlashMessage("Can not download file", "error");
      }else{
        const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/zip"}));
        const link = document.createElement('a');
        link.href = url;
        if(response.headers['content-type'] == 'application/pdf'){
          link.setAttribute('download',(params.type=='engine'?'ESN ':'MSN ')+msn+ '.pdf');
        }else{
          link.setAttribute('download',  (params.type=='engine'?'ESN ':'MSN ')+msn+ '.zip');
        }
        document.body.appendChild(link);
        link.click();
      }
      dispatch({
        type: actions.EMPTY_BUCKET,
        payload: {},
      });
      // dispatch({
      //   type: DIRECTORY_LOADER,
      //   payload: false
      // });
    })
  }
}
export const moveBucketToDestAc = (params, source, destination) => {
  let moveObj={
    type: 'file',
    move_to: destination,
    object_uuid:[]
  };
  source.map(item => moveObj.object_uuid.push(item.uuid));
  return (dispatch) => {
    globalPutService('records/'+params.type+'/'+params.aircraft_slug+'/data-room/move/', moveObj)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.EMPTY_BUCKET,
          payload: {},
        })
      }
    })
  }
}
export const getSharedPreviewAc = (params, filters) => {

  return(dispatch) => {
    dispatch({
      type: actions.PREVIEW_LOADER,
      payload: true
    })
    let url = '';
    if(params.type == "msn"){
      url = 'records/view-shared-items/'+ params.key +'/';
    }else {
      url = 'records/view-shared/'+ params.key +'/';
    }
    let query = {}
    if(params.level == 'nav'){
      query = {...filters, preview: true, parent: params.parent}
    }else {
      query = {...filters, preview: true}
    }
    globalGetService(url, query)
    .then(response => {
      if(response.data.statusCode == 404){
        dispatch({
          type: actions.GET_SHARED_VIEW,
          payload: response.data.data
        })
      }
      dispatch({
        type: actions.PREVIEW_LOADER,
        payload: false
      })
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.GET_SHARED_VIEW,
          payload: response.data.data
        })
      }
    })
  }
}

export const technicalAssetRptAc = (props) => {
  return (dispatch) => {
    dispatch({
      type: actions.PREVIEW_LOADER,
      payload: true
    });
    globalGetService('technical/share/workorder/'+props.params.shareKey+'/')
    .then(response => {
      dispatch({
        type: actions.PREVIEW_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.TECHNICAL_ASSET_RPT,
          payload: response.data.data
        })
      }
    })
  }
}

export const downloadWorkOrderFormRptAc = (props, name, shareKey) => {
  return (dispatch) => {
    dispatch({
      type: actions.PREVIEW_LOADER,
      payload: true
    });
    globalExportService('technical/share/form/'+shareKey+'/download/',{})
    .then(response => {
      dispatch({
        type: actions.PREVIEW_LOADER,
        payload: false
      });
      downloadFileType(response, name+'.', name.includes("T011")?'xls':'pdf')
    })
  }
}
export const downloadSharedViewAc = (asset, folder) =>{
  return (dispatch) =>{
    dispatch({
      type: actions.DOWNLOAD_LOADER,
      payload: true
    })
    const data={
      type: folder.type,
      object_uuid:[folder.uuid]
    };
    globalUnlimitedDownloadService(`records/download-shared/${asset.asset_type}/${asset.slug}/data-room/`, data)
    .then(response => {
      if(response.request.responseType == 'arraybuffer' && response.headers['content-type'] != 'application/json'){
        if(response.data.statusCode){
          toastFlashMessage("Can not download file", "error");
        }else{
          const url = window.URL.createObjectURL(new Blob([response.data]), {type: "application/zip"});
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', asset.msn + '_' + folder.name + '.zip' );
          document.body.appendChild(link);
          link.click();
        }
    }else{
      toastFlashMessage("Use Drive Tool to download bulk data", 'error')
    }
    dispatch({
      type: actions.DOWNLOAD_LOADER,
      payload: false
    })
    })
  }
}
export const inviteUserAc = (data) =>{
  return(dispatch) =>{
    globalPostService('console/invite-user-request/', data)
    .then(response => {
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: GET_USER_CRUD,
          payload:{}
        })
        dispatch({
          type: USER_CRUD_BAR,
          payload:{}
        })
      }
    })
  }
}
export const reportTheBugAc = (data) => {

  return(dispatch) =>{
    dispatch({
      type: actions.BUG_LOADER,
      payload:{}
    })
    const formData= new  FormData();
  formData.append('title', data.title);
  formData.append('description', data.description);
  formData.append('severity', data.severity);
  formData.append('module', data.module);
  formData.append('classification', data.classification);
  formData.append('current_page_url',window.location.href);
  data.attachments.map((file, index) => {
    formData.append('attachments', file);
  });
    globalPostService('console/report-bug/?module=contracts', formData)
    .then(response => {
      dispatch({
        type: actions.BUG_LOADER,
        payload:{}
      })
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.BUG_REPORT,
          payload:{}
        })
      }
    })
  }
}

export const getStatusAc = (work_slug,form_slug,status_id) =>{
  let data={status:status_id}
  return(dispatch) =>{
    globalPostService(`/technical/workorder/${work_slug}/form/${form_slug}/status-update/`, data)
    .then(response => {
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: GET_USER_CRUD,
          payload:{}
        })
        dispatch({
          type: USER_CRUD_BAR,
          payload:{}
        })
      }
    })
  }
}

export const fetchAllFaqsAc = (query) => {
  return(dispatch) => {
    globalGetService('/console/faq/',{...query, application: 3})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.FAQ_DETAILS_LIST,
          payload: response.data.data
        })
      }
    })
  }
}

export const fetchAllFeatures = (id, params) => {
  return(dispatch) => {
    globalGetService('/api/features/',{application: 3})
    .then(response => {
      if(response.data.statusCode == 200){
        if(getGlobalCookie('newFeature')){
          dispatch({
            type: actions.FEATURES_LIST,
            payload: response.data.data
          })
        }
        else{
           setGlobalCookie('newFeature','yes', 40)
          dispatch({
            type: actions.FEATURES_LIST,
            payload: response.data.data
          })
          dispatch({
            type:actions.FEATURE_MODAL,
            payload:true
          })
        }

      }
    })
  }
}


export const getNotificationBarDataAc = () =>{
  return(dispatch =>{
    globalGetService('/console/cart/count/')
    .then(response => {
      if(response.data.statusCode == 200){
        if(response.data.data.overdue == 4){
          trackLogOut(getLocalStorageInfo())
          localStorage.clear();
          sessionStorage.removeItem('initial_pop');
          browserHistory.push('/login');
          toastFlashMessage(response.data.message, 'error')
          return
        }else if(response.data.data.overdue == 2 || response.data.data.overdue == 3){

          dispatch({
            type: actions.NOTIFICATION_FLAG,
            payload: true
          })
        }
        dispatch({
          type: actions.NOTIFICATIONS_COUNT,
          payload: response.data.data
        })
        dispatch({
          type: actions.NOTIFICATIONS_COUNT_GIF,
          payload: true
        })
      }
    })
  })
}
export function uploadLesseeLogoAc(data) {
  return new Promise(
    function(resolve, reject){
      globalPostService(`/console/upload-lessee-logo/`, data)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export const getOwnerAc = () => {
  return(dispatch) => {
    globalGetService(`console/owner/`)
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type:actions.OWNERS_LIST,
          payload:response.data.data
        })
      }
    })
  }
}
