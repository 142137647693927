import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
import { monthsObj, utilizationFormat, imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../shared/assets/img/view_icon.svg';
import { DeleteModal } from '../../../technicalInspection/Elements';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';
import { Button as MuiButton, Chip, Tooltip } from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PublishIcon from '@material-ui/icons/Publish';
import { addUtilsAttachmentApi, deleteUtilsAttachmentApi } from '../apiServices'
import { ListLoader } from '../../../../shared';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { toastFlashMessage } from '../../../../utils';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default class UtilsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addAttachment: 'false',
      attatchedDoc: [],
      pageLoader: false,
      showFullList: false,
      deleteAttachmentmodal: false,
      previewDocs: '',
    }
    this.addUtilsAttachmentApi = addUtilsAttachmentApi.bind(this)
    this.deleteUtilsAttachmentApi = deleteUtilsAttachmentApi.bind(this)
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      [keyParam]: value
    }))
  }
  previewDoc(item) {
    this.setState({ previewDocs: item })
  }
  handleDelete = (item) => {
    this.setState({ deleteAttachmentmodal: !this.state.deleteAttachmentmodal, deleteItem: item })
  }
  deleteAttachment = (item) => {
    const { props, utils } = this.props
    let contractSlug = props.params.contract_slug
    this.setState({ pageLoader: true, deleteAttachmentmodal: false })
    this.deleteUtilsAttachmentApi(this.state.deleteItem)
      .then(response => {
        this.setState({ pageLoader: false })
        if (response.data.statusCode == 200) {
          toastFlashMessage(response.data.message, 'success')
          this.props.getUtilsAttachmentApi(contractSlug)
        }
        else {
          toastFlashMessage(response.data.message, 'error')
        }
      })
  }
  addAttachment(e) {
    if (this.state.attatchedDoc.length) {
      const { props, utils } = this.props
      const { attatchedDoc } = this.state
      let contractSlug = props.params.contract_slug
      let formdata = new FormData()
      attatchedDoc.map(item => {
        formdata.append('files', item)
      })
      formdata.append('asset_type', props.assetViewId.asset_type)
      formdata.append('asset_slug', props.params.aircraft_slug)
      formdata.append('contract_slug', props.params.contract_slug)
      formdata.append('model_type', '1')
      formdata.append('object_id', utils.id)
      this.setState({ pageLoader: true })
      this.addUtilsAttachmentApi(formdata)
        .then(response => {
          this.setState({ pageLoader: false, addAttachment: false })
          if (response.data.statusCode == 200) {
            toastFlashMessage(response.data.message, 'success')
            this.props.getUtilsAttachmentApi(contractSlug)
          }
          else {
            toastFlashMessage(response.data.message, 'error')
          }
        })
    }
    else {
      toastFlashMessage('No file Selected', 'error')
    }
  }
  render() {
    const { index, utils, utilLists, attachedDocList, contractInfo } = this.props;
    const { addAttachment, previewDocs, attatchedDoc } = this.state;
  
    return (
      <div className="asset-list">
        {this.state.pageLoader ? <ListLoader /> : null}
        <Row key={index} className="flex-centered cashflow-row" style={{ alignItems: 'inherit' }}>
          <Col className={index != 0 ? utilLists[index].month != utilLists[index - 1].month ? 'para-ui month-block ' : 'border-col' : 'para-ui month-block'}  >
            <div style={{width:"132px"}}>
              {
                index != 0 ? utilLists[index].month != utilLists[index - 1].month ? monthsObj[utils.month.slice(4, 6)] + ' ' + utils.month.slice(0, 4) : '' : monthsObj[utils.month.slice(4, 6)] + ' ' + utils.month.slice(0, 4)
              }

              { checkPermission('contracts', 'utilization', 'U') && contractInfo?.hdlineInfo?.archived_asset ==false?
                index == 0 || utilLists[index].month != utilLists[index - 1].month ?
                  <span className="hidden-xs" style={{  cursor: attachedDocList.filter(i => i.object_id == utils.id).length >= 5 ? '' : 'pointer', }} onClick={() => attachedDocList.filter(i => i.object_id == utils.id).length >= 5 ? null :this.setState({ addAttachment: true })}>
                    <Tooltip title={attachedDocList.filter(i => i.object_id == utils.id).length >= 5 ? "" : "Add Attachment "} arrow placement='top' >
                      <AttachFileIcon variant='contained' color={attachedDocList.filter(i => i.object_id == utils.id).length < 6 ? 'primary' : ''} style={{ width: '22px', float: 'right', color: attachedDocList.filter(i => i.object_id == utils.id).length >= 5 ? '#d6d6d6' : '' }} />
                    </Tooltip>
                  </span> :
                   null
                   :null
              }
            </div>
            {
              attachedDocList ?
                attachedDocList.filter(i => i.object_id == utils.id).length > 3 && !this.state.showFullList ?
                  <>{
                    attachedDocList.filter(i => i.object_id == utils.id).slice(0, 2).map(i =>
                      i.name.length > 17 ?
                        <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                          <Chip
                            variant="outlined"
                            size="small"
                            onDelete={ checkPermission('contracts', 'utilization', 'U') ? () => this.handleDelete(i):false}
                            label={i.name.substring(0, 14) + '...'}
                            clickable
                            color="primary"
                            onClick={() => this.previewDoc(i)}

                          />
                        </Tooltip>
                        :
                        <Chip
                          variant="outlined"
                          size="small"
                          label={i.name}
                          onDelete={checkPermission('contracts', 'utilization', 'U') ? () => this.handleDelete(i):false}
                          clickable
                          color="primary"
                          onClick={() => this.previewDoc(i)}
                        />
                    )
                  }
                    <Chip
                      style={{ marginTop: '4px' }}
                      size="small"
                      label={'Show all'}
                      clickable
                      color="primary"
                      deleteIcon={<ExpandMoreIcon />}
                      onDelete={() => { this.setState({ showFullList: true }) }}
                      onClick={(e) => this.setState({ showFullList: true })}
                    >
                    </Chip>
                  </>
                  :
                  <>{
                    attachedDocList.filter(i => i.object_id == utils.id).map(i =>
                      i.name.length > 17 ?
                        <Tooltip title={i.name} arrow placement='top' style={{ marginTop: '3px' }}>
                          <Chip
                            variant="outlined"
                            size="small"
                            onDelete={ checkPermission('contracts', 'utilization', 'U') ? () => this.handleDelete(i):false}
                            label={i.name.substring(0, 14) + '...'}
                            onClick={() => this.previewDoc(i)}
                            clickable
                            color="primary"
                          />
                        </Tooltip>
                        :
                        <Chip
                          variant="outlined"
                          size="small"
                          label={i.name}
                          clickable
                          onClick={() => this.previewDoc(i)}
                          color="primary"
                          onDelete={checkPermission('contracts', 'utilization', 'U') ? () => this.handleDelete(i):false}
                        />
                    )
                  }
                    {
                      this.state.showFullList && attachedDocList.filter(i => i.object_id == utils.id).length > 3 ?
                        <Chip
                          style={{ marginTop: '4px' }}
                          size="small"
                          label={'Show Less'}
                          clickable
                          color="primary"
                          deleteIcon={<KeyboardArrowUpIcon />}
                          onDelete={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showFullList: false }) }}
                          onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ showFullList: false }) }}
                        >
                        </Chip>
                        : null
                    }
                  </>
                : null
            }
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            {utils.name}
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            <NumericLabel params={utilizationFormat}>
              {utils.hours}
            </NumericLabel>
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            {utils.cycles}
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            <NumericLabel params={utilizationFormat}>
              {utils.tsn}
            </NumericLabel>
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            {utils.csn}
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            {utils.asset_location ? utils.asset_location :'--'}
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            {utils.serviceability? utils.serviceability:'--'}
          </Col>
          <Col className="cash-blk cash-info para-ui border-col" sm={1}>
            <span className="hidden-xs" style={{ cursor: "pointer" }}>
                { checkPermission('contracts','utilization','U') && this.props.isEditAble && contractInfo?.hdlineInfo?.archived_asset === false ?
                <img onClick={() => this.props.editUtils('edit')} style={{ width: '16px', float: 'right' }} src={editIcon} alt="" /> :null
              }
              <img onClick={() => this.props.editUtils('view')} style={{ width: '16px', float: 'right', marginRight:'5px', marginTop:'3px' }} src={viewIcon} alt="" />
            </span>
          </Col>
       
        </Row>
        {
          this.state.deleteAttachmentmodal ?
            <DeleteModal isOpen={this.state.deleteAttachmentmodal} className="modal-dialog-centered"  >
              <p style={{ textAlign: 'center', marginBottom: '15px' }}>Are you sure, you want to delete this Attachment
                <Tooltip title={this.state.deleteItem.name} arrow placement='top' style={{ marginTop: '3px' }}>
                  <div>
                    <Chip variant="outlined" size="small" label={this.state.deleteItem.name.substring(0, 30) + '...'} color="primary" />
                  </div>
                </Tooltip>
              </p>
              <ul className="list-inline" style={{ textAlign: 'center' }}>
                <li className="list-inline-item">
                  <button className="btn btn-primary btn-sm add-new" onClick={this.deleteAttachment}>Yes</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-danger btn-sm add-new" onClick={() => this.setState({ deleteAttachmentmodal: false })} >No</button>
                </li>
              </ul>
            </DeleteModal> :
            null
        }
        {
          this.state.previewDocs ?
            <Modal isOpen='open' style={{ width: '100vw', maxWidth: '100vw', minWidth: '100vw', maxHeight: '95vh', height: '95vh', marginTop: '0px' }}>
              <ModalHeader>{previewDocs.name}</ModalHeader>
              <ModalBody style={{ height: '91vh', maxHeight: '83vh' }}>
                {previewDocs.extension == 'csv' ?
                  <object data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${encodeURIComponent(previewDocs.url)}`} style={{ height: '78vh', width: '-webkit-fill-available' }} >
                  </object>
                  :
                  ['xls', 'xlsx', 'docx', 'txt'].includes(previewDocs.extension.toLowerCase()) ?
                    <iframe src={'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(this.state.previewDocs.url)} width='100%' height={window.innerHeight} frameborder='0'></iframe>
                    :
                    ['jpg', 'jpeg', 'png'].includes(previewDocs.extension.toLowerCase()) ?
                      <div style={{ backgroundImage: "url(" + previewDocs.url + ")", height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroudPosition: 'center' }}> </div>
                      :
                      previewDocs.extension == 'pdf' ?
                        <object type="text/html" data={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${encodeURIComponent(previewDocs.url)}#search=${window.location.search.substr(window.location.search.indexOf('?searchTerm=') + 12)}`} style={{ width: '100%', height: '100%' }}>
                          <param name="type" value="text/html" />
                        </object> :
                        null
                }
              </ModalBody>
              <ModalFooter>
                <MuiButton variant='contained' color='primary' onClick={(e) => window.open(previewDocs.url)}> Download</MuiButton>
                <MuiButton variant='contained' color='primary' onClick={(e) => this.setState({ previewDocs: '' })} >Okay</MuiButton>
              </ModalFooter>
            </Modal> : null
        }
        {
          addAttachment === true ?
            <Modal isOpen='open'>
              <ModalHeader> Attach Utilization documents </ModalHeader>
              <ModalBody>
                <DropzoneArea
                  filesLimit={5}
                  acceptedFiles={['.xls', '.csv', '.xlsx', '.docx', '.pdf', '.jpg', '.jpeg', '.png']}
                  showPreviewsInDropzone={false}
                  showPreviews={true}
                  useChipsForPreview
                  dropzoneText={<p>Drag & Drop Pictures<br /> OR <br /> Click Here</p>}
                  dropzoneClass="drag-drop-cnt"
                  maxWidth="sm"
                  showAlerts={['error', 'info']}
                  onChange={(files) => this.onFieldChange('', 'attatchedDoc', files)}
                  alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                >
                </DropzoneArea>
                <h6 style={{ marginTop: '5px', fontSize: '13px' }}> <b>Note:</b> Only pdf, docx, csv, xls, xlsx, jpg, jpeg and png files are supported (Max Upto 5 Files )</h6>

              </ModalBody>
              <ModalFooter>
                <MuiButton color='primary' onClick={() => { this.setState({ addAttachment: false, attatchedDoc: [] }) }}>Cancel</MuiButton>
                <MuiButton variant='contained' color='primary' onClick={(e) => this.addAttachment(e)} disabled={!attatchedDoc.length}>Okay</MuiButton>
              </ModalFooter>
            </Modal>
            : null
        }
      </div>
    )
  }
}
