import React, { Component, Fragment } from "react";
import { Row, Col } from "reactstrap";
import { Container, Grid, Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { imgStoragePath } from "../../../constants";
import { Link } from "react-router";
import {
  SignupForm,
  Features,
  Footer,
  LoginForm,
  SelectLessor,
  ForgotPassword,
  EmailVerification,
  OTPVerification,
  ImageSlider,
} from "../components";
import { authorizationAc } from "../../../User/actionCreators";
import { ListLoader } from "../../../shared";
import SpartaLogo from "../../../assets/img/sparta_logo.png";
import { browserHistory } from "react-router";
import config from "../../../config";
import DeploymentMessage from "../../../shared_elements/components/DeploymentMessage";
class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signupType: "1",
      verticalMiddle: false,
    };
  }

  signupTypeChange = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      signupType: value,
    }));
  };

  componentWillMount() {
    let userDetail = JSON.parse(localStorage.getItem("userInfo"));
    if (
      window.location.href.includes("select-lessor") &&
      userDetail &&
      userDetail.defaultLessor
    ) {
      if (
        userDetail.user.permission &&
        userDetail.user.permission.dashboard &&
        userDetail.user.permission.dashboard.indexOf("R") != -1
      ) {
        browserHistory.push("/");
      } else {
        browserHistory.push("/assets-listing");
      }
    }
    if (
      window.location.href.includes("select-lessor") ||
      window.location.href.includes("forgot-password") ||
      window.location.href.includes("/user/activate/:token") ||
      window.location.href.includes("otp-verification")
    ) {
      this.setState((prevState) => ({
        ...prevState,
        verticalMiddle: true,
      }));
    }
  }

  render() {
    return (
      <Fragment>
        <DeploymentMessage/>
        {this.props.loginLoader ? <ListLoader /> : null}
        <section
          className="onboarding-outer-wrapper"
          style={this.state.verticalMiddle ? { height: "100vh" } : {}}
        >
          <Container maxWidth="lg">
            <div className="main-wrapper">
              <Grid container spacing={0}>
                <Grid item md={6}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <img
                        className="sparta-logo"
                        src={SpartaLogo}
                        alt="Sparta Logo"
                      />
                    </Grid>
                    <Grid item md={6}>
                      {

                      !window.location.href.includes('select-lessor') ?

                      <Button
                        className="list-inline-item-solutions"
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          window.open(
                            `${config.domain.subDomian}solutions`, "_blank" ) }>
                        {" "}
                        Solutions
                      </Button>
                      :null}
                    </Grid>
                  </Grid>

                  <div className="form-wrapper">
                    {window.location.href.includes("signup") ? (
                      <SignupForm
                        authorization={this.props.authorization}
                        signupTypeChange={this.signupTypeChange}
                      />
                    ) : null}
                    {window.location.href.includes("login") ? (
                      <LoginForm authorization={this.props.authorization} />
                    ) : null}
                    {window.location.href.includes("select-lessor") ? (
                      <SelectLessor
                        authorization={this.props.authorization}
                        loginLoader={this.props.loginLoader}
                      />
                    ) : null}
                    {window.location.href.includes("forgot-password") ? (
                      <ForgotPassword
                        authorization={this.props.authorization}
                      />
                    ) : null}
                    {window.location.href.includes("user/activate/") ? (
                      <EmailVerification
                        authorization={this.props.authorization}
                      />
                    ) : null}
                    {window.location.href.includes("otp-verification") ? (
                      <OTPVerification
                        params={this.props}
                        authorization={this.props.authorization}
                      />
                    ) : null}
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div
                    className="img-slider-wrapper" style={ window.location.href.includes("signup") ? { minheight: "50vh" }: {} }>
                    <ImageSlider />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div
              className="row flex-centered navbar-row"
              style={{ display: "none" }}
            >
              <Col md="4" xs="2">
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => browserHistory.push("/signup")}
                  className="logo"
                  src={imgStoragePath + "phase-2/sparta_logo.svg"}
                  alt="logo image"
                />
              </Col>
              {!window.location.href.includes("select-lessor") ? (
                <Col md="8" xs="10">
                  {window.location.href.includes("forgot-password") ||
                  window.location.href.includes("otp-verification") ? (
                    <p className="text-right sign-in-btn">
                      <a
                        href="/signup#features" className="pricing-button" style={{ marginLeft: "5px" }}
                      >
                        Features
                      </a>
                      <span style={{ fontSize: "12px" }}>
                        Already have an account ?{" "}
                      </span>
                      <Link
                        to="/login" className="pricing-button" style={{ marginLeft: "5px", fontWeight: "600" }}
                      >
                        Sign In
                      </Link>
                      <Link to="https://sparta.aero/pricing" className="ternary-button"
                        style={{ marginLeft: "0px", borderRadius: "15px", border: "1.5px solid #fff",
                        }}
                      >
                        Buy Now
                      </Link>
                    </p>
                  ) : null}
                  {window.location.href.includes("login") ? (
                    <p className="text-right sign-in-btn" style={{ fontSize: "12px" }}
                    >
                      <a
                        href="/signup#features" className="pricing-button" style={{ marginLeft: "5px" }}
                      >
                        Features
                      </a>
                      <span>Don’t have an account ? </span>
                      <Link to="https://qa.console.sparta.aero/signup" target="_blank" className="pricing-button" style={{ marginLeft: "5px", fontWeight: "600" }}
                      >
                        Sign Up
                      </Link>
                      <Link to="https://sparta.aero/pricing" className="ternary-button"
                        style={{ marginLeft: "0px", borderRadius: "15px", border: "1.5px solid #fff", }} >
                        Buy Now
                      </Link>
                    </p>
                  ) : null}
                </Col>
              ) : null}
            </div>
          </Container>
        </section>

        {window.location.href.includes("signup") ? (
          <Features signupType={this.state.signupType} />
        ) : null}
        {window.location.href.includes("signup") ||
        window.location.href.includes("/login") ? (
          <Footer />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loginLoader: state.userAssetReducer.loginLoader,
});

const mapDispatchToProps = (dispatch) => {
  return {
    authorization: (data) => dispatch(authorizationAc(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
