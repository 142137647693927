import * as actions from '../actions';
function roundBy60(value1, value2, type, name) {
  let number;
  let floatNumber;
  let val1 = parseFloat(value1)
  let val2 = parseFloat(value2)
  if (type == 'add') {
    // if(name.includes('APU') || name.includes('apu')){
    //   number = (parseFloat(val1) + parseFloat(val2)).toFixed(2);
    // }else{
    //   if(val1 === 0){
    //     number = parseFloat((parseFloat(val1) + parseFloat(val2)).toFixed(2));
    //   }else{
    //     number = Math.floor(val1) + Math.floor(val2) ;
    //     if(val2!=0){
    //       if(val2 >= 1){
    //         number = number + parseFloat(parseFloat(parseFloat(parseFloat(parseFloat(val1 % Math.floor(val1)) +  parseFloat(val2 % Math.floor(val2))).toFixed(2)%0.60).toFixed(2)).toFixed(2));
    //         number =  number + parseInt(parseFloat(parseFloat(parseFloat(parseFloat(val1 % Math.floor(val1)) +  parseFloat(val2 % Math.floor(val2))).toFixed(2)/0.60).toFixed(2)).toFixed(2));
    //       }else{
    //         number = number + parseFloat(parseFloat(parseFloat(parseFloat(parseFloat(val1 % Math.floor(val1)) +  parseFloat(val2)).toFixed(2)%0.60).toFixed(2)).toFixed(2));
    //         number =  number + parseInt(parseFloat(parseFloat(parseFloat(parseFloat(val1 % Math.floor(val1)) +  parseFloat(val2)).toFixed(2)/0.60).toFixed(2)).toFixed(2));
    //       }
    //     }else{
    //       number = number + parseFloat(parseFloat(((parseFloat(val1 % Math.floor(val1)) +  0).toFixed(2)%0.60).toFixed(2)).toFixed(2));
    //       number =  number + parseInt(parseFloat(((parseFloat(val1 % Math.floor(val1)) + 0).toFixed(2)/0.60).toFixed(2)).toFixed(2));
    //     }
    //   }
    // }
    number = (parseFloat(val1) + parseFloat(val2)).toFixed(2)
  } else {
    number = (parseFloat(val1) - parseFloat(val2)).toFixed(2)
    // if(name.includes('APU') || name.includes('apu')){
    //   number = (parseFloat(val1) - parseFloat(val2)).toFixed(2)
    // }else{
    //   let diff = parseFloat(parseFloat(parseFloat(val1 % Math.floor(val1)) - parseFloat(val2 % Math.floor(val2))).toFixed(2));
    //   let diffVale = Math.floor(val1) - Math.floor(val2);
    //   number = (diff > 0 ? (diffVale+diff):((diffVale - 1) + (.60 + diff)));
    // }
  }
  if (number > 0) {
    return number
  } else {
    return 0
  }
}

export default function (state = {
  utilsListInfo: {
    list: [],
    pagination: {}
  },
  utilCrud: {
    flag: false,
    type: '',
    data: {}
  },
  utilsInitiate: {
    initial_utilization: false,
    assets: [],
    utilization: {},
    rental_utilization: {},

  },
  // viewUtils: {},
  utilsInfo: {},
  utlisAverage: [],
  utilsTrendsInfo: {
    lgSelected: '',
    engineSelected: '',
    propellerSelected: '',
    data: {}
  },
  rentalUtils: {
    utilization: [],
    pagination: {},
    rental_months: []
  },
  utilsMajorAssembly: [],
  utilsLoader: false,
  activeTab: '0',
}, action) {
  switch (action.type) {
    case actions.ULTILIZATION_BASIC_INFO:
      return {
        ...state,
        utilsInfo: action.payload
      }
    case actions.RENTAL_UTILS_LIST:
      return {
        ...state,
        rentalUtils: action.payload
      }
    case actions.UTILS_LIST:
      return {
        ...state,
        utilsListInfo: {
          ...state.utilsListInfo,
          list: action.payload.utilization,
          pagination: action.payload.pagination
        }
      }
    case actions.UTILS_INITIATE:
      return {
        ...state,
        utilsInitiate: {
          ...action.payload,
          assets: action.payload.assets,
          utilization: action.payload.utilization,
          rental_utilization: action.payload.rental_utilization,
        }
      }
    case actions.IMPORT_UTILS:
      return {
        ...state,
        utilsInitiate: {
          ...state.utilsInitiate,
          assets: action.payload,
        }
      }
    case actions.UPDATE_UTIL_FIELD:
      let assetObj = {};
      if (action.payload.keyParam == 'asset_location' || action.payload.keyParam == 'remarks') {
        return {
          ...state,
          utilsInitiate: {
            ...state.utilsInitiate,
            assets: state.utilsInitiate.assets.map((asset, index) => index == action.payload.index ? { ...asset, [action.payload.keyParam]: action.payload.value } : asset)
          }
        }
      } else {
        if (state.utilsInitiate.assets[action.payload.index].tsn_as_of_last_month >= 0 && action.payload.value >= 0 && action.payload.value != '') {
          switch (action.payload.keyParam) {
            case 'tsn_as_of_last_month':
              assetObj = {
                ...assetObj,
                tsn_as_of_last_month: parseFloat(action.payload.value),
                tsn: roundBy60(state.utilsInitiate.assets[action.payload.index].hours, action.payload.value, 'add', state.utilsInitiate.assets[action.payload.index].name),
              }
              break;
            case 'hours':
              assetObj = {
                ...assetObj,
                hours: action.payload.value,
                cycles: state.utilsInitiate.assets[action.payload.index].cycles ? parseFloat(state.utilsInitiate.assets[action.payload.index].cycles).toFixed(2): 0,
                tsn: roundBy60(state.utilsInitiate.assets[action.payload.index].tsn_as_of_last_month, action.payload.value, 'add', state.utilsInitiate.assets[action.payload.index].name),
                // csn: parseInt(state.utilsInitiate.assets[action.payload.index].csn)
              }
              break;
            case 'csn_as_of_last_month':
              assetObj = {
                ...assetObj,
                csn_as_of_last_month: (parseFloat(action.payload.value)).toFixed(2),
                csn: (parseFloat(state.utilsInitiate.assets[action.payload.index].cycles) + parseFloat(action.payload.value)).toFixed(2)

              }
              break;
            case 'cycles':
              assetObj = {
                ...assetObj,
                hours: state.utilsInitiate.assets[action.payload.index].hours ? parseFloat(state.utilsInitiate.assets[action.payload.index].hours).toFixed(2) : 0 ,
                cycles: parseFloat(action.payload.value).toFixed(2),
                tsn: state.utilsInitiate.assets[action.payload.index].tsn,
                csn: (parseFloat(state.utilsInitiate.assets[action.payload.index].csn_as_of_last_month) + parseFloat(action.payload.value)).toFixed(2)
              }
              break;
            case 'tsn':
              assetObj = {
                ...assetObj,
                hours: roundBy60(action.payload.value, state.utilsInitiate.assets[action.payload.index].tsn_as_of_last_month, 'sub', state.utilsInitiate.assets[action.payload.index].name),
                cycles: state.utilsInitiate.assets[action.payload.index].cycles ?parseFloat(state.utilsInitiate.assets[action.payload.index].cycles) : 0,
                tsn: action.payload.value,
                csn: state.utilsInitiate.assets[action.payload.index].csn
              }
              break;
            case 'csn':
              const cycle = parseFloat(action.payload.value) - parseFloat(state.utilsInitiate.assets[action.payload.index].csn_as_of_last_month);
              assetObj = {
                ...assetObj,
                hours: state.utilsInitiate.assets[action.payload.index].hours,
                tsn: state.utilsInitiate.assets[action.payload.index].tsn,
                csn: (parseFloat(action.payload.value)).toFixed(2)
              }
              break;
            default:
          }
          return {
            ...state,
            utilsInitiate: {
              ...state.utilsInitiate,
              assets: state.utilsInitiate.assets.map((asset, index) => index == action.payload.index ? { ...asset, ...assetObj } : asset)
            }
          }
        } else {
          return {
            ...state,
            utilsInitiate: {
              ...state.utilsInitiate,
              assets: state.utilsInitiate.assets.map((asset, index) => index == action.payload.index ? { ...asset, [action.payload.keyParam]: action.payload.value } : asset)
            }
          }
        }
      }

    case actions.CHANGE_UTIL_GRAPH:
      return {
        ...state,
        utilsTrendsInfo: {
          ...state.utilsTrendsInfo,
          [action.payload.type]: action.payload.value
        }
      }
    case actions.UTILS_TRENDS:
      return {
        ...state,
        utilsTrendsInfo: {
          ...state.utilsTrendsInfo,
          data: action.payload,
          lgSelected: action.payload.landing_gears ? Object.keys(action.payload.landing_gears)[0] : '',
          engineSelected: action.payload.engines ? Object.keys(action.payload.engines)[0] : '',
          propellerSelected: action.payload.propeller ? Object.keys(action.payload.propeller)[0] : '',
        },
        activeTab: action.payload.airframe ? '0' : (action.payload.apu ? '1' : (action.payload.landing_gears ? '2' : (action.payload.engines ? '3' : action.payload.lpt ? '7' : action.payload.hpc ? '5' : action.payload.hpt ? '6' : '4')))
      }
    case actions.UTILS_AVERAGE:
      return {
        ...state,
        utlisAverage: action.payload
      }
    // case actions.UTILS_VIEW:
    //   return {
    //     ...state,
    //     viewUtils: action.payload
    //   }
    case actions.UTILS_EDIT:
      return {
        ...state,
        utilsListInfo: {
          ...state.utilsListInfo,
          list: state.utilsListInfo.list.map(list => list.id == action.payload.id ? action.payload : list)
        },
        // viewUtils: {}
      }
    case actions.CLOSE_UTILS_BAR:
      return {
        ...state,
        viewUtils: {}
      }
    case actions.UTILS_LOADER:
      return {
        ...state,
        utilsLoader: action.payload
      }
    case actions.UTILS_MAJOR_ASSMEBLY:
      return {
        ...state,
        utilsMajorAssembly: action.payload
      }
    case actions.UTIL_CRUD_OPERN:
      return {
        ...state,
        utilCrud: action.payload
      }
    case actions.TOGGLE_VIEW_EDIT:
      return {
        ...state,
        utilCrud: {
          ...state.utilCrud,
          type: (action.payload == 'view' ? 'edit' : 'view')
        }
      }
    case actions.TOGGLE_TAB:
      return {
        ...state,
        activeTab: action.payload
      }
    default:
      return state;
  }
}
