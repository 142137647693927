import React, { Component, useDebugValue } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldInput, FieldSelect, FieldTextarea, FieldDate} from '../../Elements';
import { leaseTypeDrpdwn } from '../../../../constants';
import {checkString} from '../../../../formValidator'
export default class EditLessorType extends Component{
  constructor(props){
    super(props);
    this.state = {
      contract: {},
      error: {}
    }
  }
  componentDidMount(){
    this.updateHdState(this.props.contract);
  }
  componentWillReceiveProps(nextProps){
    this.updateHdState(nextProps.contract)
  }
  updateHdState = (contract) => {
    let data = Object.assign({}, contract);
    data = {
      ...data,
      lessor_id: data.lessor.id,
      asset_id: data.asset.id,
      lessee_id: data.lessee.id,
      operator_id: data.operator.id,
      lessor_portfolio:data.lessor_portfolio && data.lessor_portfolio.id ? data.lessor_portfolio.id: '',
      lease_type : data.lease_type && data.lease_type.value ? data.lease_type.value: null,
      lease_type_accounting : data.lease_type_accounting && data.lease_type_accounting.value ? data.lease_type_accounting.value: null,
      rental_escalation_interval: data.rental_escalation_interval && data.rental_escalation_interval.value ? data.rental_escalation_interval.value:null,
      rental_interval: data.rental_interval && data.rental_interval.value ? data.rental_interval.value:null,
      rental_type: data.rental_type && data.rental_type.value ? data.rental_type.value:null,
      lease_status: data.lease_status && data.lease_status.value ? data.lease_status.value:null
    }
    this.setState({
      contract: data
    });
  }
  updateForm = (type, value) => {
    if(type === 'lessor_name'){
      let lessor = this.props.lessorList.find(item=>item.name==value)
      this.setState(prevState => ({
        ...prevState,
        contract: {
          ...prevState.contract,
          [type]: value,
          lessor_jurisdiction:lessor ?  lessor.jurisdiction : '' ,
          lessor_contact_details: lessor ?  lessor.contact : '',
          lessor_contact_address: lessor ?  lessor.address : '',
        },
        error: {}
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        contract: {
          ...prevState.contract,
          [type]: value
        },
        error: {}
      }))
    }
  }

  updateHdlineInfo =  (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.contract);
    delete data['operator'];
    delete data['lessor'];
    delete data['asset'];
    delete data['lessee'];
    const validateNewInput = {
      lessor_name: checkString({value: this.state.contract.lessor_name, required: true, minLength: '', maxLength: '', message: 'Please enter Lessor Name'})
    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.updateHdlineInfo(data);
    }else{
      this.setState({
        error: validateNewInput

      });
    }


  }
  render(){
    const { type, portfolioList, ownerList, lessorList } = this.props;
    const { contract, error } = this.state;
    if(Object.keys(contract).length){
      return(
        <form onSubmit={(e) => this.updateHdlineInfo(e)}>
          <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
            <Row>
              {/* <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lessor Name <sup className="reqStar"> * </sup></FieldLabel>
                  <FieldInput
                    value={contract.lessor_name}
                    type={type}
                    keyParam="lessor_name"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.lessor_name}</h6>
                </FieldGroup>
              </FieldCol> */}
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Lessor <sup className="reqStar"> * </sup>
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Lessor'
                    keyParam="lessor_name"
                    value={lessorList.find(item=>item.name==contract.lessor_name)}
                    type={type}
                    options={lessorList}
                    updateField={this.updateForm}
                    labelKey='name'
                    valueKey='name'
                  />
                  <h6 className="error-msg">{error.lessor_name}</h6>
                </FieldGroup>
              </FieldCol>
              {/* <Col md="12">
                <h4 style={{fontSize:'13px', color:'#a0a0a0', paddingLeft:'25px', marginBottom:'6px'}}>Lessor Name</h4>
                <p style={{ fontSize: '14px', color: '#000000', paddingLeft: '25px', marginBottom: '8px'}}>{contract.lessor_name}</p>
              </Col> */}
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Jurisdiction</FieldLabel>
                  <FieldInput
                    value={contract.lessor_jurisdiction}
                    type={type}
                    disable={true}
                    keyParam="lessor_jurisdiction"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              {/* <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Owner</FieldLabel>
                  <FieldInput
                    value={contract.owner_name}
                    type={type}
                    keyParam="owner_name"
                    updateField={this.updateForm}
                    disable={true}
                  />
                </FieldGroup>
              </FieldCol> */}

              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">SPV</FieldLabel>
                  <FieldInput
                    value={contract.lessor_special_purpose_vehicle}
                    type={type}
                    keyParam="lessor_special_purpose_vehicle"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Contact Address</FieldLabel>
                  <FieldInput
                    value={contract.lessor_contact_address}
                    type={type}
                    disable={true}
                    keyParam="lessor_contact_address"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Contact (Email and Phone)</FieldLabel>
                  <FieldInput
                    value={contract.lessor_contact_details}
                    type={type}
                    disable={true}
                    keyParam="lessor_contact_details"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Owner
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Owner'
                    keyParam="owner_name"
                    value={ownerList.find(item => item.name == contract.owner_name)}
                    type={type}
                    options={ownerList}
                    updateField={this.updateForm}
                    labelKey='name'
                    valueKey='name'
                  />
                </FieldGroup>
              </FieldCol>
            </Row>
         </div>
         <div className="submit-block">
           <input type="submit" className="primary-btn" value="Save Changes"/>
           <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
         </div>
       </form>
      )
    }else{
      return <h6>Loading...</h6>
    }
  }
}
