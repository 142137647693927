import React, { useState} from 'react';
import { browserHistory } from 'react-router';
import { TableRow, TableCell   } from '@material-ui/core';
const FleetLease = ({item}) => {
  let assetTypeNames = {
    1: 'aircraft',
    2: 'engine',
    3: 'apu',
    4: 'lg',
    5: 'propeller',
    6: 'fan',
    7: 'hpc',
    8: 'hpt',
    9: 'lpt'
  }
  const redirectUrl = () =>{
    if(item.asset.linked_aircraft_slug){
      item.lease_status.label=='Draft' ? browserHistory.push(`/aircraft/${item.asset.linked_aircraft_slug}/contract/list/${item.slug}`) :
                    browserHistory.push(`/aircraft/${item.asset.linked_aircraft_slug}/contract/${item.slug}/dashboard`)
    }else{
      item.lease_status.label=='Draft' ? browserHistory.push(`/${assetTypeNames[item.asset.asset_type]}/${item.asset.slug}/contract/list/${item.slug}`) :
                    browserHistory.push(`/${assetTypeNames[item.asset.asset_type]}/${item.asset.slug}/contract/${item.slug}/dashboard`)
    }
  }
  return(
    <TableRow hover tabIndex={-1} className="lessee-display-listing" onClick={() => redirectUrl()} style={{cursor:'pointer'}}>
      <TableCell className="asset-info">
        <div className="flex-centered asset-wrapper">
            <div className="assetIcon">
                <img style={{ height: '25px', objectFit: 'contain' }} src={"images/" + (item.asset.asset_type == 1 ? "icon_Aircraft.png" : (item.asset.asset_type == 2) ? "icon_engine.png" : item.asset.asset_type == 3 ? "icon_apu.svg" : item.asset.asset_type == 4 ? 'icon_lg.svg' : item.asset.asset_type == 5 ? 'icon_propeller.svg' : 'icon_engine.png')} alt="img" width="32" />
            </div>
            <div className="asset">
              {
                (item.asset.asset_type === 1 || item.asset.asset_type === 2  || item.asset.asset_type === 3  || item.asset.asset_type === 5) ?
                <p>
                    {item.asset.asset_type === 1 ? item.asset.name ? item.asset.name : item.asset.name : ''}{item.asset.asset_type === 1 ? item.asset.model && ' / ' + item.asset.model: ''}{item.asset.asset_type === 2 ? item.asset.name : ''}{item.asset.asset_type === 3 ? item.asset.name : ''} {item.asset.asset_type === 5 ? item.asset?.model ?item.asset.model: null : null}
                </p> : null
              }
                <h6>{item.asset.unique_name}</h6>
            </div>
        </div>
      </TableCell>
      <TableCell style={{width:"150px"}}>{item.lessor_name && item.lessor_name.name ? item.lessor_name.name:'--'}</TableCell>
      <TableCell style={{width:"150px"}}>{item.owner && item.owner.name ? item.owner.name:'--'}</TableCell>
      <TableCell style={{width:"150px"}}>{item.lessee && item.lessee.logo ? <img style={{width:"40px"}} src={item.lessee && item.lessee.logo} alt="Icon"/> : <span>{item.lessee &&  item.lessee.name ? item.lessee.name : '--'}</span>}</TableCell>
      <TableCell style={{width:"200px"}}>{item.contractual_start_date ? item.contractual_start_date : '--'}{' to '}{item.contractual_end_date ? item.contractual_end_date : '--'}</TableCell>
      <TableCell style={{width:"150px"}}>{item.lease_type && item.lease_type.label ? item.lease_type.label:'--'}</TableCell>
      <TableCell style={{width:"150px"}}><p className={"status status-" + item.lease_status.value}>{item.lease_status && item.lease_status.label ? item.lease_status.label.toUpperCase():'--'}</p></TableCell>
    </TableRow>
  )
}
export default FleetLease;
