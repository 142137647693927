import React from 'react';
import { Paper,Divider, Grid } from '@material-ui/core';
import { FilterComponent } from '../../../shared_elements';
import Skeleton from '@material-ui/lab/Skeleton';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { transTypeGraphFilterOps } from '../'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
Highcharts.setOptions({
    lang: {
      numericSymbols: ["k", "M", "B", "T"],
      thousandsSep: ","
    }
});
const CashflowAcTypeGraph = ({cashflowAccTypeGraph, skeletonLoader, filter, getResponseBack, callExternalFunction}) => {
    let filterOptions = Object.assign({}, transTypeGraphFilterOps);
    const options = {
        chart: {
            zoomType: 'xy',
            panning: true,
            panKey: 'shift'
        },
        title: {
            text: 'Based on Reserve Rate Type',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        noData: {
            style: {
                fontWeight: 'bold',
                fontSize: '15px'
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y}</b><br/>',
            valuePrefix: 'US$',
           },
        subtitle: {
            text: `${cashflowAccTypeGraph.title ? cashflowAccTypeGraph.title : ''}`,
            align: 'left',
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Amount(US$)'
            }
        },
        xAxis: {
            categories: cashflowAccTypeGraph && cashflowAccTypeGraph.cashflow_categories && cashflowAccTypeGraph.cashflow_categories.x_axis && cashflowAccTypeGraph.cashflow_categories.x_axis.categories
        },
        legend: {
            enabled: true
        },
        plotOptions:{
            series:{
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        callExternalFunction(event.point.series.name, event.point.category, 'basedOnReserveType');
                    }
                }
            }
        },
        series: cashflowAccTypeGraph && cashflowAccTypeGraph.reserve_cashflow,
        credits: false,
        exporting: {
            buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadPDF'],
                },
            },
            filename: 'Cashflow-Based on Reserve Rate Type Chart',
            // chartOptions: {
            //   title: {
            //     text: Object.keys(filter).map((keyParam, index) => 
            //     (filterMenu && filterMenu[keyParam] && filterMenu[keyParam].inputType && filterMenu[keyParam].inputType === 'text') ? `${filterMenu[keyParam].title}: ${filter[keyParam]}` :
            //     (filterMenu && filterMenu[keyParam] && filterMenu[keyParam].inputType && filterMenu[keyParam].inputType === 'dropdown') ? filterMenu[keyParam].multiple ? optionMulti.map((item,index)=> item.map((value, index)=>`${value.name ? value.name : value.label}`)) : option.map((item,index)=> `${item.name ? item.name : item.label}`) : 
            //     (filterMenu && filterMenu[keyParam] && filterMenu[keyParam].inputType && filterMenu[keyParam].inputType === 'date') ? `${filterMenu[keyParam].title}: ${filter[keyParam][filterMenu[keyParam].firstKey]} To ${filter[keyParam][filterMenu[keyParam].lastKey]}` : 
            //     (filterMenu && filterMenu[keyParam] && filterMenu[keyParam].inputType && filterMenu[keyParam].inputType === 'year') ? `${filterMenu[keyParam].title}: ${filter[keyParam][filterMenu[keyParam].firstKey]} To ${filter[keyParam][filterMenu[keyParam].lastKey]}` : '' ),
            //     style: {
            //         fontFamily: 'Conv_IBMPlexSans-Regular',
            //         fontSize: '8px'
            //     }
            //   }
            // }
         }
    }

    return (
        skeletonLoader ? <Skeleton variant="rect" height={440} width='100%' /> : 
        <Paper>
            <div style={{padding:'0 5px'}}>
                <FilterComponent 
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => getResponseBack(applyFilter)}
                />
            </div>
            <Divider />
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </Paper>
    )
}
export default CashflowAcTypeGraph
