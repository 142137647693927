import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router';
import arrowIcon from '../assets/img/arrow_icon.png';
import '../assets/styles/assetSwitcher.scss';
class AppCard extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const {label, content, icon, tag} = this.props;
    return(
        <Col md="4" className="flex-not-centered" style={this.props.enabled ? {opacity: '0.9'} : {opacity: '0.8'}} onClick={() => {
            if(this.props.enabled){
              window.open(this.props.url, '_blank').focus();
            }
          }}>
            <Link className="app-card" to="" target="_blank">
                <ul className="list-inline top-blk">
                    <li className="list-inline-item">
                        {icon}
                    </li>
                    <li className="list-inline-item">
                        <h4 className="card-heading">{label}{tag}</h4>
                    </li>
                </ul>
                <p className="card-details">
                    {content}
                </p>
                <ul className="list-inline bottom-blk">
                  {
                    this.props.enabled ?
                    <>
                      <li className="list-inline-item">
                        ENTER
                      </li>
                      <li className="list-inline-item">
                        <img width="10" src={arrowIcon} alt="Icon"/>
                      </li>
                    </> : 
                    <li>
                      <p className="restrict-access">Please contact support@sparta.aero to access this application</p>
                    </li>
                  }
                </ul>
            </Link>
        </Col>
    )
  }
}
export default withRouter(AppCard);
