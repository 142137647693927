import FleetDashborad from './containers/FleetDashborad';
import ContractsFleetDb from './containers/ContractsFleetDb';
import RecordsFleetDb from './containers/RecordsFleetDb';
import MaintenanceFleetDb from './containers/MaintenanceFleetDb';
import InspectionFleetDb from './containers/InspectionFleetDb';
import DashboardReducer from './reducers';
export {
    FleetDashborad,
    ContractsFleetDb,
    RecordsFleetDb,
    MaintenanceFleetDb,
    InspectionFleetDb,
    DashboardReducer
}
let months=[]
for (let i=0;i<24;i++){
    months[i]={ label: i+1, value: i+1 };
} 
export default months