import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
var colors = ['#beed87', '#ee7c26','#729cf1', '#d7d7d7', '#000000', '#99FF99', '#B34D4D']
const AssetsDistribustion = ({assetDistribustion, loader}) => {
  var colors = ['#89BFFF','#beed87', '#ffb98d', '#d7d7d7','#729cf1'];
  const options = {
    chart: {
        type: 'column'
    },
    title: {
        text: 'Assets Fleet Distribution',
        align: 'left',
        style: {
            fontFamily: 'Conv_IBMPlexSans-Regular',
            fontSize:'16px'
        }
    },
    xAxis:{
      categories:assetDistribustion.categories,
    },
    colors: colors,
    yAxis: {
      min: 0,
      title: {
          text: 'Number of Assets'
      }
    },
    series:assetDistribustion.series,
    credits: {
      enabled: false
    },
    exporting: {
      buttons: {
          contextButton: { menuItems: ['downloadPNG', 'downloadPDF'],
          },
      },
      filename: 'Dashboard-Assets Fleet Distribution Chart',
    }
  }
  return(
    <div>
        <HighchartsReact
        highcharts={Highcharts}
        options={options}
        />
    </div>
  )
}
export default AssetsDistribustion;
