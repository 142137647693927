import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal, ModalBody, } from 'reactstrap';
import { withRouter, browserHistory, Link } from 'react-router';
import { EditSidebar, ContractSideBar } from '../../Elements'
import Header from '../../Elements/Header';
import { HorizontalInfoCard, LessorCard, LesseeCard, EditLesseeType, EditLeaseType, EditLessorType } from "../components";
import { contrHdlineInfoAc, contrEditHdlineInfoAc } from '../actionCreators';
import { sLesseeListAc, sOperatorListAc, sGlobalConstantAc, sExportContractsAc, sAuditLogAc } from '../../../../shared/actionCreators';
import { ListLoader } from '../../../../shared';
import '../../assets/styles/Headline.scss';
import { CLEAR_CONTRACT_VIEW } from '../actions';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import NoRecordFound from '../../../../shared/components/NoRecordFound';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { LeaseDetialTab } from '../../../../lease_management/Elements'
import { toastFlashMessage, getLocalStorageInfo } from '../../../../utils/';
import { checkPermission } from '../../../../utils_v2/';
import { trackActivity } from '../../../../utils/mixpanel';
import { assetsType } from '../../../../constants';
import { getOwnerAc, getOwnerDropdownListAc } from '../../../../phase2/ConfigureSettings/actionCreators';
import revisionHistoryIcon from '../../../../shared/assets/img/revision_history_icon.svg';
import backArrowIcon from '../../../../shared/assets/img/back_arrow.svg';
import { globalGetService } from '../../../../globalServices';
import {getAutoInvoiceOn} from '../../../../lease_management/invoices/apiServices';
import SuplmntandAmndmnt from '../components/SuplmntandAmndmnt';
class HeadLines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editLeaseSideBar: false,
      editLessorSideBar: false,
      editLesseeSideBar: false,
      synMajorAssemblyModal: false,
      autoInvoiceOn:{}
    }
    this.getAutoInvoiceOn= getAutoInvoiceOn.bind(this)
  }
  componentDidMount() {
    this.getAutoInvoiceOn(this.props)
    this.props.fetchHdline();
    this.props.fetchTechConstant({ constant_types: ['lease_type_accounting', 'lease_type'] });
    if (!this.props.lessees.length) {
      this.props.fetchLesseeList();
    }
    if (!this.props.operators.length) {
      this.props.fetchOperatorList();
    }
    this.props.getOwnerList();
    this.props.getLessorList();
  }

  changeMsn = (evt) => {
    let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
  }

  componentWillUnmount() {
    this.props.clearContractInfo();
  }
  toggleEditSideBar = (type) => {
    this.setState(prevState => ({
      ...prevState,
      [type]: !prevState[type],
      hdInfoOverlay: !prevState[type]
    }));
  }

  syncMajorAssemblyApi = () => {
    this.setState({ syncMajorAssemblyLoader: true })
    globalGetService(`contracts/${this.props.params.contract_slug}/sync-contract/`)
      .then(response => {
        this.setState({ syncMajorAssemblyLoader: false })
        if (response.data.statusCode === 200) {
          toastFlashMessage(response.data.message, 'success')
          this.setState({ synMajorAssemblyModal: false })
          setTimeout(() => { this.componentDidMount() }, 800)
        }
      })
  }

  render() {
    const { contrHdlineInfo, assetViewId, lessees, operators, portfolioList, hdLineLoader, techConstants, auditLogView, params, contractSummaryLoader } = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" pageTitle="Headlines" params={this.props.params} changeMsn={this.changeMsn} exportContract={this.props.exportContract} assetStatus={contrHdlineInfo?.hdlineInfo?.asset?.status} />
        <ContractSideBar application="Headlines" type="brief" params={this.props.params} />
        {hdLineLoader ? <ListLoader /> : null}
        {contractSummaryLoader ? <ListLoader /> : null}
        <div className="contract-content-blk">
          {Object.keys(contrHdlineInfo.hdlineInfo).length ?
            <div className="contract-headline-block">
              <LeaseDetialTab url='headlines' primaryTab='basic-betails' secondaryTab='' />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom:'15px' }}>
                <div></div>
                <ul className='list-inline' style={{ display: 'flex', flexDirection: 'row' }}>
                  <li className='list-inline-item'>
                    <Link
                      className="auditlog-btn"
                      style={{ 'marginRight': '2px', 'display': 'inline-block' }}
                      onClick={() => { this.props.toggleAuditlogFn('contracts', 'ctcontracts', '0,1', contrHdlineInfo.hdlineInfo.id, 'update', 'headlines', 'params'); trackActivity('Revision History', { page_title: 'Headlines', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3, 4, 5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`, contract_slug: `${contrHdlineInfo.slug}`, item_type: 'Headlines' }) }}
                    >
                      <img className="audit-img" width="15" src={revisionHistoryIcon} alt="img" />
                      Revision History
                    </Link>
                  </li>
                  { checkPermission('contracts','lease_details', 'U') && contrHdlineInfo?.hdlineInfo?.asset?.status !=5 ?
                    (contrHdlineInfo.hdlineInfo.lease_status && contrHdlineInfo.hdlineInfo.lease_status.value == 1) && (contrHdlineInfo.hdlineInfo && contrHdlineInfo.hdlineInfo.asset_type == 1) ?
                      <li className='list-inline-item'>
                        <Link className="btn btn-primary btn-sm add-new" onClick={() => this.setState({ synMajorAssemblyModal: true })}>
                          Sync Major Assemblies
                        </Link>
                      </li> : null
                      :  null
                  }

                </ul>
              </div>
              <Fragment>
                <Row className="flex-not-centered">
                  <LessorCard toggleEditSideBar={() => this.toggleEditSideBar('editLessorSideBar')} lessorInfo={contrHdlineInfo.hdlineInfo} />
                  <LesseeCard toggleEditSideBar={() => this.toggleEditSideBar('editLesseeSideBar')} lesseeInfo={contrHdlineInfo.hdlineInfo} />
                </Row>
                <HorizontalInfoCard toggleEditSideBar={() => this.toggleEditSideBar('editLeaseSideBar')} leaseInfo={contrHdlineInfo.hdlineInfo}   autoInvoiceOn={this.state.autoInvoiceOn} />
                <SuplmntandAmndmnt props={this.props} leaseInfo={contrHdlineInfo.hdlineInfo}/>
              </Fragment>
            </div>
            : null
          }
        </div>
        {Object.keys(contrHdlineInfo.hdlineInfo).length ?
          <EditSidebar
            title="Edit Lessor Information"
            editSideBar={this.state.editLessorSideBar}
            toggleEditSideBar={() => this.toggleEditSideBar('editLessorSideBar')}
          >
            {this.state.editLessorSideBar ?
              <EditLessorType
                type="edit"
                portfolioList={portfolioList}
                contract={contrHdlineInfo.hdlineInfo}
                updateHdlineInfo={(data) => this.props.updateHdlineInfo(data, 'Lessor', assetViewId, () => { this.toggleEditSideBar('editLessorSideBar') })}
                toggleEditSideBar={() => this.toggleEditSideBar('editLessorSideBar')}
                lessorList={this.props.lessorList}
                ownerList={this.props.ownerList}
              /> : null
            }
          </EditSidebar> : null
        }
        {Object.keys(contrHdlineInfo.hdlineInfo).length ?
          <EditSidebar
            title="Edit Lessee Information"
            editSideBar={this.state.editLesseeSideBar}
            toggleEditSideBar={() => this.toggleEditSideBar('editLesseeSideBar')}
          >
            {this.state.editLesseeSideBar ?
              <EditLesseeType
                type="edit"
                lessees={lessees}
                operators={operators}
                contract={contrHdlineInfo.hdlineInfo}
                updateHdlineInfo={(data) => this.props.updateHdlineInfo(data, 'Lessee', assetViewId, () => { this.toggleEditSideBar('editLesseeSideBar') })}
                toggleEditSideBar={() => this.toggleEditSideBar('editLesseeSideBar')}
                hdLineLoader={this.props.hdLineLoader}
              /> : null
            }

          </EditSidebar> : null
        }
        {Object.keys(contrHdlineInfo.hdlineInfo).length ?
          <EditSidebar
            title="Edit Lease Information"/*  */
            editSideBar={this.state.editLeaseSideBar}
            toggleEditSideBar={() => this.toggleEditSideBar('editLeaseSideBar')}>
            {this.state.editLeaseSideBar ?
              <div>
                <EditLeaseType
                  type="edit"
                  contract={contrHdlineInfo.hdlineInfo}
                  techConstants={techConstants}
                  updateHdlineInfo={(data) => this.props.updateHdlineInfo(data, 'Lease', assetViewId, () => { this.toggleEditSideBar('editLeaseSideBar') })}
                  toggleEditSideBar={() => this.toggleEditSideBar('editLeaseSideBar')}
                  hdLineLoader={this.props.hdLineLoader}
                  bankInfo={this.props.contrHdlineInfo.hdlineInfo}
                  autoInvoiceOn={this.state.autoInvoiceOn}
                />
              </div>
              : null
            }
          </EditSidebar> : null
        }

        <EditSidebar
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({ type: 'add', data: {}, flag: false })}
        >
          {Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
              <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '20px', padding: '5px 22px 5px 25px' }}>
                {auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                    <AddDeleteAudit auditLogView={item} key={index} />
                  ) :
                  auditLogView.data.map((item, index) =>
                    <UpdateAuditLog auditLogView={item} key={index} />
                  )
                }
              </div>
            </div>
            : <NoRecordFound title="No Revision History Found" />
          }
        </EditSidebar>
        <Modal isOpen={this.state.synMajorAssemblyModal} toggle={() => this.setState({ synMajorAssemblyModal: false })} className="modal-dialog-centered" style={{ padding: '10px' }}>
          <ModalBody>
            <Row className="transfer-modal-body" style={{ padding: '10px 0px 30px 10px' }}>
              <Row className="apps-container" style={{ float: 'right', width: '100%', textAlign: 'center' }}>
                <Col md="12">
                  <p>
                    Are you sure you want to sync the major assemblies?
                  </p>
                  <span className='warning-msg'>Please make sure Invoices are raised for older Utilizations before clicking on "Sync Major Assemblies" and also update the assets in MR account after clicking on "Sync Major Assemblies"
                  </span>
                </Col>
              </Row>
            </Row>
            <Row className="transfer-modal-footer" >
              <Col md="12" style={{ textAlign: 'center' }}>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <button type="button" onClick={() => this.syncMajorAssemblyApi()} className="btn btn-primary btn-sm add-new" >Yes</button>
                  </li>
                  <li className="list-inline-item">
                    <button type="button" className="btn btn-primary btn-sm add-new" onClick={() => this.setState({ synMajorAssemblyModal: false })}>No</button>
                  </li>

                </ul>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contrHdlineInfo: state.ContrHdlineReducer.contrHdlineInfo,
  hdLineLoader: state.ContrHdlineReducer.hdLineLoader,
  portfolioList: state.ContrHdlineReducer.portfolioList,
  operators: state.sharedReducers.sOperatorList,
  lessees: state.sharedReducers.sLesseeList,
  techConstants: state.sharedReducers.techConstants,
  auditLogView: state.sharedReducers.auditLogView,
  contractSummaryLoader: state.sharedReducers.contractSummaryLoader,
  lessorList: state.confReducer.ownerList,
  ownerList: state.confReducer.ownerDropdownList,
  assetViewId: state.userAssetReducer.assetViewId,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchHdline: () => dispatch(contrHdlineInfoAc(ownProps.params)),
    fetchLesseeList: () => dispatch(sLesseeListAc()),
    fetchOperatorList: () => dispatch(sOperatorListAc()),
    updateHdlineInfo: (data, type, assetViewId, toggleEditSideBar) => dispatch(contrEditHdlineInfoAc(ownProps.params, data, type, assetViewId, toggleEditSideBar)),
    fetchTechConstant: (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    toggleAuditlogFn: (application, content_type, action, objectId, type, cntrctType, paramKey) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, cntrctType, paramKey)),
    clearContractInfo: () => dispatch({
      type: CLEAR_CONTRACT_VIEW,
      payload: {}
    }),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    }),
    exportContract: (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    getLessorList: () => dispatch(getOwnerAc()),
    getOwnerList: () => dispatch(getOwnerDropdownListAc())
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(HeadLines, ['contracts', 'lease_details', 'R'])));
