import React from 'react';
import { TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { removeEmptyKey } from '../../utils_v2';
const FleetGenericFilter = ({lessorOptions, processingLoader, ownerOptions, lesseeOptions, assetTypeOptions, assetModelOptions, assetsOptions, genericFilter, changeGenericFilter}) => {
    return(
        <div style={{position:'relative'}}>
            { processingLoader?
                <div style={{position:'absolute', top:'0', left:'0', width:'100%', height:'100%', zIndex:10, background:'rgba(255,255,255,.8)'}}>
                    <p style={{textAlign:'center', fontSize:'15px',padding:'7px 0'}}><span style={{padding:'4px', background:'#fff', fontWeight:'500', fontFamily:'Conv_IBMPlexSans-Medium'}}>Processing the Graph Data...</span></p>
                </div>:null
            }
            <ul className='list-inline flex-centred'>
                <li className='list-inline-item'>
                    <Autocomplete
                        options= {lessorOptions}
                        getOptionLabel={option => option.name}
                        value={lessorOptions.length && genericFilter.lessor_id ? lessorOptions.find((item) => item.id === genericFilter.lessor_id): null}
                        style={{ width: 175 }}
                        id="lessor"
                        onChange={(e, value) => changeGenericFilter(e, 'lessor_id', value)}
                        renderInput={params => <TextField {...params} label="Lessor" placeholder="Select Lessor" variant="outlined" />}
                    />
                </li>
                <li className='list-inline-item'>
                    <Autocomplete
                        options= {ownerOptions}
                        getOptionLabel={option => option.name}
                        value={ownerOptions.length && genericFilter.owner_id ? ownerOptions.find((item) => item.id === genericFilter.owner_id): null}
                        style={{ width: 175 }}
                        id="owner"
                        onChange={(e, value) => changeGenericFilter(e, 'owner_id', value)}
                        renderInput={params => <TextField {...params} label="Owner" placeholder="Select Owner" variant="outlined" />}
                    />
                </li>
                <li className='list-inline-item'>
                    <Autocomplete
                        options= {lesseeOptions}
                        getOptionLabel={option => option.name}
                        value={lesseeOptions.length && genericFilter.lessee_id ? lesseeOptions.find((item) => item.id === genericFilter.lessee_id): null}
                        style={{ width: 175 }}
                        id="lessee_id"
                        onChange={(e, value) => changeGenericFilter(e, 'lessee_id', value)}
                        renderInput={params => <TextField {...params} label="Lessee" placeholder="Select Lessee" variant="outlined" />}
                    />
                </li>
                <li className='list-inline-item'>
                    <Autocomplete
                        options= {assetTypeOptions}
                        getOptionLabel={option => option.name}
                        value={assetTypeOptions.length && genericFilter.asset_type ? assetTypeOptions.find((item) => item.id === genericFilter.asset_type): null}
                        style={{ width: 175 }}
                        id="asset_type"
                        onChange={(e, value) => changeGenericFilter(e, 'asset_type', value)}
                        renderInput={params => <TextField {...params} label="Asset Type" placeholder="Select Asset Type" variant="outlined" />}
                    />
                </li>
                <li className='list-inline-item'>
                    <Autocomplete
                        options= {assetModelOptions}
                        getOptionLabel={option => option.name}
                        value={assetModelOptions.length && genericFilter.asset_model ? assetModelOptions.find((item) => item.id === genericFilter.asset_model): null}
                        style={{ width: 175 }}
                        id="asset_model"
                        onChange={(e, value) => changeGenericFilter(e, 'asset_model', value)}
                        renderInput={params => <TextField {...params} label="Asset Model" placeholder="Select Asset Model" variant="outlined" />}
                    />
                </li>
                <li className='list-inline-item'>
                    <Autocomplete
                        options= {assetsOptions?assetsOptions:[]}
                        getOptionLabel={option => option.serial_number}
                        value={assetsOptions.length && genericFilter.serial_number ? assetsOptions.find((item) => item.id === genericFilter.serial_number): null}
                        style={{ width: 175 }}
                        id="serial_number"
                        onChange={(e, value) => changeGenericFilter(e, 'serial_number', value)}
                        renderInput={params => <TextField {...params} label="Serial No." placeholder="Select Serial No." variant="outlined" />}
                    />
                </li>
            </ul>
        </div>
    )
}
export default FleetGenericFilter;