import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import { Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import { globalPostService } from '../../globalServices'
import PopoverNote from './PopoverNote';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../applications/contracts/Elements';
import moment from "moment";
import { checkString, checkValidDate, checkIntNumber, checkNumber } from '../../formValidator';
import { getLocalStorageInfo,toastFlashMessage } from '../../utils'
import Select , { Creatable } from 'react-select';
import { uploadLesseeLogoAc } from '../../shared/actionCreators';
import { imgStoragePath } from '../../constants';
import {leaseStatus} from '../../shared/components';
export default class AddEditEngine extends Component {
  constructor(props){
    super(props);
    this.state = {
      engine: {ownership_type: 1},
      error:{},
      disabled: false,
      assetTransferModal: false,
      owner: {},
      newLessee:false
    }
    this.uploadLesseeLogoAc = uploadLesseeLogoAc.bind(this)
  }

  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.engineObj));
    this.setState({
      engine:data
    });
    this.setState({
      engine: {...this.state.engine, ownership_type: 1}
    })
    if(getLocalStorageInfo().defaultLessor.lessor_type != 3){
      //engine: {...this.state.engine, lessor_name: getLocalStorageInfo().defaultLessor.name, lessor_domain: getLocalStorageInfo().defaultLessor.company_domain}
      this.setState({
        disabled: true,
      })
    }
  }

  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
      engine: {
        ...prevState.engine,
        location : address
      }
    }));
  };

  handleSelect = address => {
    let location =  geocodeByAddress(address);

    location.then(results =>
         getLatLng(results[0])
         )
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          engine: {
            ...prevState.engine,
            location : address,
            latitude: latLng['lat'],
            longitude: latLng['lng'],
          }
        })))
      .catch(error => console.error('Error', error));

      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
          this.setState(prevState => ({
            ...prevState,
						engine: {
              ...prevState.engine,
                country: place.length ? place[0]['long_name']:address
              }
            }))
        }

        )
        .catch(error => console.error('Error', error));

        location.then(results =>
          results[0]
          ).then(country => {
            let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
            this.setState(prevState => ({
              ...prevState,
              engine: {
                ...prevState.engine,
                  country_code: place.length ? place[0]['short_name']:address
                }
              }))
          }

          )
          .catch(error => console.error('Error', error));
    };

  updateForm = (type, value) => {
    if(type == "status" && value != 2){
      this.setState(prevState => ({
        ...prevState,
        engine:{
          ...prevState.engine,
          off_lease_status:null,
          [type]: value,
        },
        error:{
          ...prevState.error,
          [type]:''
        }
      }))
    }else {
      this.setState(prevState => ({
        ...prevState,
        engine: {
          ...prevState.engine,
          [type]: value
        },
        error:{
          ...prevState.error,
          [type]:''
        }

      }));
    }
    // setTimeout(() => {
    //     if(this.state.engine.esn){
    //       globalPostService('/console/check-aircraft/engine/',{esn: this.state.engine.esn,engine_type: this.state.engine.engine_type_id ? this.state.engine.engine_type_id : null})
    //       .then(response => {
    //           if(response.data.data && response.data.data.exist_lessor_slug && getLocalStorageInfo().defaultLessor.slug != response.data.data.exist_lessor_slug){
    //             this.setState({
    //               disabled: true,
    //               engine: {...this.state.engine,lessor_type: response.data.data.lessor_type.value, exist_lessor_slug: response.data.data.exist_lessor_slug, lessor_name: response.data.data.lessor_name, lessor_domain: response.data.data.lessor_domain},
    //               owner: response.data.data
    //             })
    //           }else if(getLocalStorageInfo().defaultLessor.lessor_type == 2){
    //             this.setState({
    //               disabled: this.state.disabled,
    //               engine: {...this.state.engine, lessor_type: null, lessor_name: getLocalStorageInfo().defaultLessor.name, lessor_domain: getLocalStorageInfo().defaultLessor.company_domain, exist_lessor_slug: getLocalStorageInfo().defaultLessor.slug},
    //               owner: {}
    //             })
    //           }else{
    //             this.setState({
    //               disabled: false,
    //               engine: {...this.state.engine, lessor_type: null},
    //               owner: {}
    //             })
    //           }
    //       })
    //     }
    // }, 300)
  }
  updateLesseeForm = (value) => {
    if(value){
      if(typeof(value.id) == "string"){
        this.setState(prevState => ({
          ...prevState,
          engine:{
            ...prevState.engine,
            lessee_id:{
              name:value.name,
              logo:'',
              contact_address:'',
              country:''
            }
          },
          newLessee:true
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          engine:{
            ...prevState.engine,
            lessee_id:value.id
          },
          newLessee:false
        }))
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        engine:{
          ...prevState.engine,
          lessee_id:''
        },
        newLessee:false
      }))
    }
  }
  updateNewLessee = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      engine:{
        ...prevState.engine,
        lessee_id:{
          ...prevState.engine.lessee_id,
          [type]:value
        }
      }
    }))
  }
  uploadLogo = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    this.uploadLesseeLogoAc(formData)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          engine:{
            ...prevState.engine,
            lessee_id:{
              ...prevState.engine.lessee_id,
              logo:response.data.data.path
            }
          }
        }))
        toastFlashMessage(response.data.message, 'success')
      }else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
  }

  createEngine = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.engine);
    if(!data.location && window.google){
      data = {
        ...data,
        latitude: '',
        longitude: '',
        country: ''
      }
    }


		let validateNewInput = {
      engine_type_id: checkString({value: this.state.engine.engine_type_id, required: true, minLength: '', maxLength: '', message: 'Please select Engine Type'}),
      status: checkString({value: this.state.engine.status, required: true, minLength: '', maxLength: '', message: 'Please select Engine Lease status'}),
      manufacturing_date: checkValidDate({value: this.state.engine.manufacturing_date, required: true, minLength: '', maxLength: '', message: 'Please enter Date of Manufacture'}),
			date_of_installation: checkValidDate({value: this.state.engine.date_of_installation, required: '', minLength: '', maxLength: '', message: 'Please enter Date of Installation'}),
			esn: checkString({value: this.state.engine.esn, required: true, minLength: '', maxLength: '', message: 'Please enter Engine Serial Number'}),
      position: checkString({value: this.state.engine.position, required: this.state.engine.status!=1 ? false: true, minLength: '', maxLength: '', message: 'Please select Engine Position'}),
      operating_thrust: checkNumber({value: this.state.engine.operating_thrust, required: '', minLength: '', maxLength: '', message: 'Please enter Purchase Thrust in numbers'}),
      purchase_thrust: checkNumber({value: this.state.engine.purchase_thrust, required: '', minLength: '', maxLength: '', message: 'Please enter Operating Thrust in numbers'}),
      tsn: checkNumber({value: this.state.engine.tsn, required: '', minLength: '', maxLength: '', message: 'Please enter TSN in numbers'}),
      csn: checkNumber({value: this.state.engine.csn, required: '', minLength: '', maxLength: '', message: 'Please enter CSN in numbers'}),
      average_monthly_hours: checkNumber({value: this.state.engine.average_monthly_hours, required: '', minLength: '', maxLength: '', message: 'Please enter Avg. Monthly Hours in numbers'}),
      average_monthly_cycles: checkNumber({value: this.state.engine.average_monthly_cycles, required: '', minLength: '', maxLength: '', message: 'Please enter Avg. Monthly Cycles in numbers'}),
      region: checkString({value: this.state.engine.region, required: this.state.engine.status!= 1 ? false: true, minLength: '', maxLength: '', message: 'Please select Region of Operation'}),
      lessee_id:  checkString({value: this.state.engine.lessee_id, required:this.state.engine.status && this.state.engine.status==1?true:false, minLength: '', maxLength: '', message: 'Please select Lessee'}),
    };


    if(getLocalStorageInfo().defaultLessor.lessor_type == 3 && !this.state.engine.own_asset && (this.state.engine.exist_lessor_slug == undefined || this.state.engine.exist_lessor_slug == null || this.state.engine.exist_lessor_slug == '')){
      validateNewInput = {...validateNewInput, exist_lessor_slug: 'Lessor is required'}
    }
    if(getLocalStorageInfo().defaultLessor.lessor_type == 3 && !this.state.engine.own_asset && (this.state.engine.lessor_domain == undefined || this.state.engine.lessor_domain == null || this.state.engine.lessor_domain == '') && !this.state.disabled && this.state.engine.exist_lessor_slug && this.state.engine.exist_lessor_slug == 'newlessor'){
      validateNewInput = {...validateNewInput, lessor_domain: 'Lessor email is required'}
    }
    if(getLocalStorageInfo().defaultLessor.lessor_type == 3 && !this.state.engine.own_asset && (this.state.engine.lessor_name == undefined || this.state.engine.lessor_name == null || this.state.engine.lessor_name == '') && !this.state.disabled && this.state.engine.exist_lessor_slug && this.state.engine.exist_lessor_slug == 'newlessor'){
      validateNewInput = {...validateNewInput, lessor_domain: 'Lessor name is required'}
    }

    if(this.state.engine.ownership_type == 2 && ( this.state.engine.owner_portfolio == undefined || this.state.engine.owner_portfolio == null || this.state.engine.owner_portfolio == ''|| this.state.engine.owner_portfolio == undefined || this.state.engine.owner_portfolio == null )){
      validateNewInput = {...validateNewInput, portfolio_id: 'Portfolio is required'}
    }
    // if(this.state.engine.ownership_type == 2 && ( this.state.engine.portfolio == undefined || this.state.engine.portfolio == null || this.state.engine.portfolio.id == '' || this.state.engine.portfolio.id == undefined || this.state.engine.portfolio.id == null)){
    //   validateNewInput = {...validateNewInput, portfolio: 'Portfolio is required'}
    // }

    if(this.state.engine.date_of_installation && this.state.engine.date_of_installation.trim() != '' && parseInt(moment(this.state.engine.date_of_installation).diff(moment(this.state.engine.manufacturing_date), 'days')) < 0){
      validateNewInput = {
        ...validateNewInput,
        date_of_installation: 'Date of installation can not be before date of manufacture'
      }
    }

    if(data.same_operator_lessee){
      data = {
        ...data,
        operator_id: data.lessee_id
      };
    }
    data = {
      ...data,
      asset_slug: this.props.slug,
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.state.engine.own_asset != undefined && !this.state.engine.own_asset && this.state.disabled){
        this.setState({
          assetTransferModal: true
        })
        return
      }
      this.props.toggleEditSideBar('addEngine');
      const userInfo = getLocalStorageInfo()
      if(data.exist_lessor_slug == 'newlessor'){
        delete data.exist_lessor_slug
      }
      if(userInfo.defaultLessor.lessor_type != 1){
        this.props.createEngine(data.own_asset ? {...data,from_scratch: true} : data.exist_lessor_slug ? {...data,request_for_access: true} : data);
      }else{
        this.props.createEngine(data, this.props.activeTab);
      }
    }else{

      this.setState({
        error: validateNewInput
      });
    }
  }

  addNewAndSave = () =>{
    this.props.toggleEditSideBar();
    let data = this.state.engine
    if(data.exist_lessor_slug == 'newlessor'){
      delete data.exist_lessor_slug
    }
    this.props.createEngine({...data,from_scratch: true});
  }

  requesteAccess = () => {
    this.props.toggleEditSideBar();
    let data = this.state.engine
    if(data.exist_lessor_slug == 'newlessor'){
      delete data.exist_lessor_slug
    }
    if(getLocalStorageInfo().defaultLessor.lessor_type == 2){
      this.props.createEngine({...data, notify: true});
    }else{
      this.props.createEngine({...data, request_for_access: true});
    }

  }
  render(){
    const { lessorList, clients, techConstants, type, sRegionList, operators, engineTypes } = this.props;
		const { engine, error, newLessee } =  this.state;
    const userInfo = getLocalStorageInfo()
    var showTabs = true
    const {jacLeaseStatus,defaultLeaseStatus,airAsiaLeaseStatus} = leaseStatus
    // Object.keys(userInfo.user.permission).map(key => {
    //   showTabs = userInfo.user.permission[key].is_saas ? false : showTabs
    //   return key
    // })
    return(
      <form onSubmit={(e) => this.createEngine(e)}>
         <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
				 		<Row>
						 <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">ESN<sup className="reqStar"> * </sup></FieldLabel>
                <FieldInput
                  value={engine.esn}
                  type={type}
                  keyParam="esn"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.esn}</h6>
              </FieldGroup>
            </FieldCol>

						 <FieldCol md="12">
              <FieldGroup className="form-group">
							<FieldLabel className="label">
                  Engine Type
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Engine Type'
                  keyParam="engine_type_id"
                  value={engine.engine_type_id}
                  type={type}
                  options={engineTypes}
                  updateField={this.updateForm}
                  labelKey='name'
                  valueKey='id'
                  name={engine.engine_name}
                />
              <h6 className="error-msg">{error.engine_type_id}</h6>
              </FieldGroup>
            </FieldCol>
						 <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Date of Manufacture<sup className="reqStar"> * </sup>
                <PopoverNote
                     placement= 'top'
                     tagetId={'Popover-asset-aircraft'}
                    />
                </FieldLabel>
								<FieldDate
                  value={engine.manufacturing_date}
                  type={type}
                  keyParam="manufacturing_date"
									updateField={this.updateForm}
                  maxDate={moment().subtract(1, 'day')}
                  readOnly={true}

                />
              <h6 className="error-msg">{error.manufacturing_date}</h6>
              </FieldGroup>
            </FieldCol>

              <FieldCol md="12">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">Position
                 { engine.status!=1? null:  <sup className="reqStar"> * </sup>}
                 </FieldLabel>
                <FieldSelect
                   placeholder='Select position'
                   keyParam="position"
                   value={engine.position}
                   type={type}
                   options={techConstants.filter(item => item.type=="engine_position")}
                   updateField={this.updateForm}
                   labelKey='label'
                   valueKey='value'
                   name={engine.position_name}
                 />
               <h6 className="error-msg">{error.position}</h6>
               </FieldGroup>
             </FieldCol>


              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Status<sup className="reqStar"> * </sup></FieldLabel>
                  <FieldSelect
                    placeholder='Select Status'
                    keyParam="status"
                    value={engine.status}
                    type={type}
                    options = {getLocalStorageInfo().defaultLessor.id === 348 ? airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 ? jacLeaseStatus : defaultLeaseStatus}
                    updateField={this.updateForm}
                    labelKey='label'
                    valueKey='value'
                    name={engine.position_name}
                  />
                <h6 className="error-msg">{error.status}</h6>
                </FieldGroup>
              </FieldCol>

            { engine.status == 2 ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Off Lease Status</FieldLabel>
                  <FieldSelect
                    placeholder='Select Off Lease Status'
                    keyParam="off_lease_status"
                    value={engine.off_lease_status}
                    type={type}
                    options={techConstants.filter(item => item.type=="off_lease_status")}
                    updateField={this.updateForm}
                    labelKey='label'
                    valueKey='value'
                    name={engine.position_name}
                  />
                </FieldGroup>
              </FieldCol>
              :null
            }

              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Region of Operation
                 { engine.status!=1? null:  <sup className="reqStar"> * </sup>}
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select region'
                    keyParam="region"
                    value={engine.region}
                    type={type}
                    options={sRegionList}
                    updateField={this.updateForm}
                    labelKey='name'
                    valueKey='id'
                    name={engine.region_label}
                  />

                <h6 className="error-msg">{error.region}</h6>
                </FieldGroup>
              </FieldCol>

              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Location</FieldLabel>
                  {
                    window.google !== undefined && window.google !== null ?
                    <PlacesAutocomplete
                      value={engine.location}
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                    >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div style={{position: 'relative'}}>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Location',
                            className: 'location-search-input',
                          })}
                        />
                        {
                          engine.location ?
                          <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
                          {loading && <div>Loading...</div>}
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div> :  null
                        }

                      </div>
                    )}
                  </PlacesAutocomplete>
                    :<FieldInput
                      value={engine.country}
                      type={type}
                      keyParam="country"
                      updateField={this.updateForm}
                    />
                  }
              </FieldGroup>
            </FieldCol>

            {
              showTabs ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Ownership</FieldLabel>
                  <div>
                    <label className="label" style={{ cursor: 'pointer', marginRight: '10px'}}>
                       <input type="radio"
                        value={engine.ownership_type}
                        onChange = {(e) => this.updateForm('ownership_type', 1)}
                        style={{ marginRight: '4px'}}
                        checked = {engine.ownership_type === 1 ? true : false}
                       />
                     <span>Owned</span>
                    </label>
                    <label className="label" style={{ cursor: 'pointer'}}>
                       <input type="radio"
                        value={engine.ownership_type}
                        onChange = {(e) => this.updateForm('ownership_type', 2)}
                        style={{ marginRight: '4px'}}
                        checked = {engine.ownership_type === 2 ? true : false}
                       />
                     <span>Managed</span>
                    </label>
                  </div>
                </FieldGroup>
              </FieldCol>
              :null
            }
            {
              engine.ownership_type == 2 ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Portfolio { type != 'view' ? <sup className="reqStar"> * </sup>:null}</FieldLabel>
                    <FieldSelect
                      placeholder='Select Portfolio'
                      keyParam="owner_portfolio"
                      value={engine.owner_portfolio}
                      type={type}
                      options = {this.props.ownersList}
                      updateField={this.updateForm}
                      labelKey= 'name'
                      valueKey='id'
                    />
                  <h6 className="error-msg">{error.portfolio_id}</h6>
                </FieldGroup>
              </FieldCol>
              :null
            }

            <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                   Credit Classification
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Credit Classification'
                    keyParam="credit_classification"
                    value={engine.credit_classification}
                    type={type}
                    options = {techConstants.filter(item => item.type == "credit_classification")}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                  />
                </FieldGroup>
              </FieldCol>

              <FieldCol md="12">
              <FieldGroup className="form-group">

                <FieldLabel className="label">  Date of Installation</FieldLabel>
                <FieldDate
                  value={engine.date_of_installation}
                  type={type}
                  minDate={moment(engine.manufacturing_date)}
                  keyParam="date_of_installation"
                  updateField={this.updateForm}
                />

              <h6 className="error-msg">{error.date_of_installation	}</h6>
              </FieldGroup>
            </FieldCol>

              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">  TSN</FieldLabel>
                  <FieldInput
                    value={engine.tsn}
                    type={type}
                    keyParam="tsn"
                    updateField={this.updateForm}

                  />
                <h6 className="error-msg">{error.tsn	}</h6>

                </FieldGroup>
              </FieldCol>

              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">  CSN</FieldLabel>
                  <FieldInput
                    value={engine.csn}
                    type={type}
                    keyParam="csn"
                    updateField={this.updateForm}
                  />
            <h6 className="error-msg">{error.csn	}</h6>

                </FieldGroup>
              </FieldCol>

              <FieldCol md="12">
                <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    Avg. Monthly Hours
                    </FieldLabel>
                    <FieldInput
                      value={engine.average_monthly_hours}
                      type={type}
                      keyParam="average_monthly_hours"
                      updateField={this.updateForm}
                    />
                    <h6 className="error-msg">{error.average_monthly_hours}</h6>
                  </FieldGroup>
              </FieldCol>

              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                  Avg. Monthly Cycles
                  </FieldLabel>

                  <FieldInput
                    value={engine.average_monthly_cycles}
                    type={type}
                    keyParam="average_monthly_cycles"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.average_monthly_cycles}</h6>
                </FieldGroup>
              </FieldCol>
            <div style={newLessee ? {width: '100%', background: '#f3f3f3', margin: '45px', paddingTop: '20px'} : {width: '100%'}}>

              <FieldCol md="12">
                <FieldGroup className="form-group">
                    <FieldLabel className="label">
                       Lessee
                       { type != 'view' && engine.status== 1 ? <sup className="reqStar"> * </sup>:null}
                    </FieldLabel>
                    <Creatable
                      value={engine.lessee_id}
                      options={clients}
                      isClearable={true}
                      onChange={this.updateLesseeForm}
                      className="custom-select-block"
                      placeholder='Add or Select Lessee'
                      valueKey='id'
                      labelKey='name'
                      type={type}
                      disabled={engine && engine.status != 1 ? true : false}

                    />
                     <h6 className="error-msg">{error.lessee_id}</h6>
                  </FieldGroup>
                </FieldCol>

            {
              newLessee ?
              <Fragment>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Contact Address
                    </FieldLabel>
                    <FieldInput
                      value={engine.lessee_id ? engine.lessee_id.contact_address:'' }
                      type={type}
                      keyParam="contact_address"
                      updateField={this.updateNewLessee}
                    />
                  <h6 className="error-msg">{error.lease_term}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Country
                    </FieldLabel>
                    <FieldInput
                      value={engine.lessee_id ? engine.lessee_id.country:'' }
                      type={type}
                      keyParam="country"
                      updateField={this.updateNewLessee}
                    />
                  <h6 className="error-msg">{error.lease_term}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label" id="upload-image" style={{position:'relative', cursor:'pointer'}}>
                      Upload Logo <img src={imgStoragePath+'phase-2/upload_template.png'} width={20} />
                      <input type="file" accept="image/*" onChange={(e)=>this.uploadLogo(e.target.files[0])} style={{position:'absolute', left:'0px', opacity:'0'}}/>
                    </FieldLabel>
                  <h6 className="error-msg">{error.lease_term}</h6>
                  </FieldGroup>
                </FieldCol>
              </Fragment> : null
            }
          </div>

              <Col md="12">
                <div className="form-group">
                 <label className="label" style={{ cursor: 'pointer'}}>
                    <input type="checkbox"
                     value={engine.same_operator_lessee}
                     onChange = {(e) => this.updateForm('same_operator_lessee', e.target.checked)}
                     style={{ marginRight: '4px'}}
                     disabled= {engine.lessee_id ? false : true}
                     checked = {engine.same_operator_lessee}
                    />
                  <span>Operator is same as Lessee</span>
                 </label>
                </div>
              </Col>

         { !engine.same_operator_lessee ?
					<FieldCol md="12">
						<FieldGroup className="form-group">
                <FieldLabel className="label">
              		Operator
                </FieldLabel>

								<FieldSelect
                  placeholder='Select Operator'
                  keyParam="operator_id"
                  value={engine.operator_id}
                  type={type}
                  options={operators}
                  updateField={this.updateForm}
                  labelKey='name'
                  valueKey='id'
                />
              </FieldGroup>
            </FieldCol> : null }



              <Col md="12">
                 <div className="form-group" style={{ margin: "0px 0px 20px 0px" }}>
                     <label className="label">
                       <input
                         type="checkbox"
                         checked={engine.match_csn_to_llp}
                         onChange={(e) => this.updateForm('match_csn_to_llp', e.target.checked)}
                       />
                       <span>Match Engine's TSN & CSN to LLP</span>
                     </label>
                 </div>

               </Col>

              <FieldCol md="12">
               <FieldGroup className="form-group">
                 <FieldLabel className="label">
                   Operating Thrust (Lbs)
                 </FieldLabel>
                 <FieldInput
                   value={engine.operating_thrust}
                   type={type}
                   keyParam="operating_thrust"
                   updateField={this.updateForm}
                 />
               <h6 className="error-msg">{error.operating_thrust}</h6>

               </FieldGroup>
             </FieldCol>

              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Purchased Thrust (Lbs)
                  </FieldLabel>
                  <FieldInput
                    value={engine.purchase_thrust}
                    type={type}
                    keyParam="purchase_thrust"
                    updateField={this.updateForm}
                  />
                 <h6 className="error-msg">{error.purchase_thrust}</h6>

                </FieldGroup>
              </FieldCol>

            { this.state.engine.esn && this.state.engine.engine_type_id ?
              Object.keys(this.state.owner).length ?
              null:
              userInfo.defaultLessor.lessor_type == 3 && !this.state.disabled ?
              <div style={{padding: '0 40px', width: '100%'}}>
                <div style={{width: '100%', padding: '10px 20px', background: '#f2f2f2', marginBottom: '50px'}}>
                  {
                    userInfo.defaultLessor.lessor_type != 1 ?
                    <Col md="12" style={{borderBottom: '1px solid #d3d3d3', marginBottom: '20px',paddingLeft:'0px'}}>
                      <div className="form-group" style={{marginBottom: '0px',padding:'0px'}}>
                       <label className="label" style={{ cursor: 'pointer'}}>
                        <span style={{color:'#9439e4',display:'block',padding:'5px 0px'}}>Ownership Details</span>
                       </label>
                      </div>
                    </Col>
                    :null
                  }
                  {
                  userInfo.defaultLessor.lessor_type == 3 && !this.state.disabled ?
                    <Col md="12" style={{padding:'0px'}}>
                      <div className="form-group" style={{padding:'0px'}}>
                       <label className="label" style={{ cursor: 'pointer'}}>
                          <input type="checkbox"
                           value={engine.own_asset}
                           onChange = {(e) => this.updateForm('own_asset', e.target.checked)}
                           style={{ marginRight: '4px'}}
                           checked = {engine.own_asset}
                           disabled={this.state.disabled}
                          />
                        <span>I would like to continue without notifying/inviting the lessor</span>
                       </label>
                      </div>
                    </Col>
                    :null
                  }
                  {!engine.own_asset && !this.state.disabled && userInfo.defaultLessor.lessor_type == 3 ? <p className="text-center"><span>Or, Select the lessor by entering details below</span></p>:null}

                  { !engine.own_asset ?
                    <div>
                      {!engine.own_asset && userInfo.defaultLessor.lessor_type == 3 ?
                        <FieldCol md="12" style={{padding:'0px'}}>
                          <FieldGroup className="form-group" style={{padding:'0px'}}>
                            <FieldLabel className="label">Lessor<sup className="reqStar"> * </sup></FieldLabel>
                            {
                              this.state.disabled ?
                              <FieldInput
                                value={engine.lessor_name}
                                type={type}
                                keyParam="lessor_name"
                                updateField={this.updateForm}
                                disable={this.state.disabled}
                              />
                              :<FieldSelect
                                placeholder='Select Lessor'
                                keyParam="exist_lessor_slug"
                                value={engine.exist_lessor_slug}
                                type={type}
                                options={lessorList.filter(item => item.lessor_type.value != 3)}
                                updateField={this.updateForm}
                                labelKey="name"
                                valueKey="slug"
                                styleName="custom-select-block custom-select-block-white"
                              />
                            }
                          <h6 className="error-msg">{error.exist_lessor_slug}</h6>
                          </FieldGroup>
                        </FieldCol> : null
                      }
                      { !engine.own_asset && userInfo.defaultLessor.lessor_type == 3 && !this.state.disabled && !this.state.disabled && engine.exist_lessor_slug && engine.exist_lessor_slug == 'newlessor' ?
                        <FieldCol md="12" style={{padding:'0px'}}>
                          <FieldGroup className="form-group" style={{padding:'0px'}}>
                            <FieldLabel className="label">Lessor Name<sup className="reqStar"> * </sup></FieldLabel>
                            <FieldInput
                              value={engine.lessor_name}
                              type={type}
                              keyParam="lessor_name"
                              updateField={this.updateForm}
                              disable={this.state.disabled}
                            />
                          <h6 className="error-msg">{error.lessor_name}</h6>
                          </FieldGroup>
                        </FieldCol>
                        :null
                      }
                      { !engine.own_asset && userInfo.defaultLessor.lessor_type == 3 && !this.state.disabled && engine.exist_lessor_slug && engine.exist_lessor_slug == 'newlessor' ?
                        <FieldCol md="12" style={{padding:'0px'}}>
                          <FieldGroup className="form-group" style={{padding:'0px'}}>
                            <FieldLabel className="label">Lessor Email<sup className="reqStar"> * </sup></FieldLabel>
                            <FieldInput
                              value={engine.lessor_domain}
                              type={type}
                              keyParam="lessor_domain"
                              updateField={this.updateForm}
                              disable={this.state.disabled}
                            />
                          <h6 className="error-msg">{error.lessor_domain}</h6>
                          </FieldGroup>
                        </FieldCol>
                        :null
                      }
                    </div>:null
                  }

                </div>
              </div>
              :null
              :null
            }
					</Row>
        </div>
        <div className="submit-block">
          <input type="submit" className="primary-btn" value="Save Changes"/>
          <Link onClick={() => this.props.toggleEditSideBar('addEngine')} >Cancel</Link>
        </div>
      </form>
    )
  }
}
