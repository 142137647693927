import React from 'react';
import DateInputField from './DateInputField';
import NumberInputField from './NumberInputField';
import FloatInputField from './FloatInputField';
import TextInputField from './TextInputField';
import DropDownField from './DropDownField';
import MultiDropDown from './MultiDropDown';
import TextNumberField from './TextNumberField';
import TextareaField from './TextareaField';
function GetInputField(component, label, props){
    if(component == 'string'){
      return(<TextInputField {...props}>{label}</TextInputField>)
    }
    if(component == 'number'){
      return(<TextNumberField {...props}>{label}</TextNumberField>)
    }
    if(component == 'integer'){
      return(<NumberInputField {...props}>{label}</NumberInputField>)
    }
    if(component == 'date'){
      return(<DateInputField {...props}>{label}</DateInputField>)
    }
    if(component == 'float'){
      return(<FloatInputField {...props}>{label}</FloatInputField>)
    }
    if(component == 'dropdown'){
      return(<DropDownField {...props}>{label}</DropDownField>)
    }
    if(component == 'multiDropDown'){
      return(<MultiDropDown {...props}>{label}</MultiDropDown>)
    }
    if(component == 'textarea'){
      return(<TextareaField {...props}>{label}</TextareaField>)
    }
}
export default GetInputField;
