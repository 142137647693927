import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const LeaseDistribustion = ({ leaseDistribustion }) => {
var colors = leaseDistribustion.color;
  const options = {
    chart: {
      type: 'pie',
      options3d: {
          enabled: true,
          alpha: 25
      },
      styledMode: true
  },
  title: {
      text: 'Lease Fleet Distribution',
      align: 'left',
      style: {
          fontFamily: 'Conv_IBMPlexSans-Regular',
          fontSize:'16px'
      }
  },
  colors:colors,
  plotOptions: {
      pie: {
          innerSize: 100,
          depth: 60,
          dataLabels: {
              enabled: true,
              softConnector: false
          },
          showInLegend: false,
          allowPointSelect: true,
          cursor: 'pointer',
          animation: false
      }
  },
  tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> <br/>'
  },
  series: [{
      name:'Count',
      data: leaseDistribustion && leaseDistribustion.lease_type && leaseDistribustion.lease_type,
      colorByPoint: true,
      dataLabels: {
        formatter: function () {
            return this.point.name + ':</b> ' +
                '('+this.y+')';
        }
      }
    }
  ],
    credits: false,
    exporting: {
      buttons: {
          contextButton: { menuItems: ['downloadPNG', 'downloadPDF'],
          },
      },
      filename: 'Dashboard-Lease Fleet Distribution Chart',
    }
  }
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export default LeaseDistribustion;
