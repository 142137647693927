import React, { useEffect, useState } from 'react'
import { Header, ContractHeadertext, ContractSideBar } from '../../../applications/contracts/Elements';
import { ContractWrapper } from '../../Elements';
import { assetId, assetsType, backendDateFormat } from '../../../constants';
import { browserHistory } from 'react-router';
import AssemblyLeaseCRU from '../components/AssemblyLeaseCRU';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus, toastFlashMessage } from '../../../utils_v2';
import { PageLoader, TableListComp, EmptyCollection } from '../../../shared_elements';
import { assemblyListHd } from '..';
import AssemblyLeaseList from '../components/AssemblyLeaseList';
import { checkString, checkValidDate, checkDateDiffText } from '../../../formValidator';
import moment from 'moment';
import RoleBasedAccess from '../../../utils/RoleBasedAccess';
const AssemblyListing = (props) => {
    const [assemblyList, setAssemblyList] = useState([])
    const [assignmentmsnList, setAssignmentmsnList] = useState([])
    const [bankDropdownList, setBankDropdownList] = useState([])
    const [modal, setModal] = useState(false)
    const [assemblyLeaseDetails, setAssemblyLeaseDetails] =useState({})
    const setDefaultState =()=>{
        setAssemblyLeaseDetails({
            assembly_swap: '1',
            utilization_unit: '2',
            assignment_msn: null,
            lease_status: null,
            removal_date: null,
            removal_tsn: null,
            removal_csn: null,
            installation_date: null,
            installation_tsn: null,
            installation_csn: null,
            last_overhaul_date: null,
            last_overhaul_tsn: null,
            last_overhaul_csn: null,
            lease_start_date: null,
            lease_end_date: null,
            daily_rate: null,
            utilization_rate: null,
            termination_date: null,
            sale_date: null,
            sale_tsn: null,
            sale_csn: null,
            sale_price: null,
            sold_to: null,
            default_bank_id: null,
        })
    }
    const [majorAssembliesList, setMajorAssembliesList] = useState([])
    const [attatchedDoc, setAttatchedDoc] = useState([])
    const [positionList, setPositionList] = useState([])
    const [validationMsgs, setValidationMsgs] = useState({})
    const [pageLoader, setPageLoader] = useState(false)
    const [attachmentLoader, setAttachmentLoader] = useState(false)
    useEffect(() => {
        getAssemblyListFn()
        getAssignmentMsnListFn()
        getmajorAssembliesListFn()
        getBanksListFn()
        getEnginePositionFn()
    }, [])
   const getEnginePositionFn=()=>{
        globalGetService(`console/get-constants/?constant_type=engine_position`)
        .then(response=>{
            setPositionList(response.data.data)
        })
    }
    const getAssemblyListFn = () => {
        setPageLoader(true)
        globalGetService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease/`)
            .then(response => {
                setPageLoader(false)
                if(checkApiStatus(response)){
                    setAssemblyList(response.data.data)
                }
                else{
                    toastFlashMessage(response.data.message, 'error')
                }
            })
    }
    const addAssemblyLeaseDetailsFn = () => {
        let data = assemblyLeaseDetails
        let validateNewInput = {
            assignment_msn: checkString({ value: data.assignment_msn, required: true, minLength: '', maxLength: '', message: 'Please Select Assignment MSN' }),
            position: checkString({ value: data.position, required: true, minLength: '', maxLength: '', message: 'Please Select Position' }),
            major_assembly: checkString({ value: data.major_assembly, required: true, minLength: '', maxLength: '', message: 'Please Select Major Assembly ' }),
            removal_tsn: checkString({ value: data.removal_tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Removal TSN ' }),
            installation_tsn: checkString({ value: data.installation_tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Installation TSN ' }),
            removal_csn: checkString({ value: data.removal_csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Removal CSN ' }),
            installation_csn: checkString({ value: data.installation_csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Installation CSN ' }),
            removal_date: checkValidDate({ value: data.removal_date, required: true, minLength: '', maxLength: '', message: 'Please Select Removal Date ' }) ?  'Please Select Removal Date ' : checkDateDiffText({ value: data.removal_date, required: true }, { value: moment().format(backendDateFormat), required: true }, 'Removal Date', "Today's date"),
            installation_date: checkValidDate({ value: data.installation_date, required: true, minLength: '', maxLength: '', message: 'Please Select Installation Date ' }) ? 'Please Select Installation Date ' : checkDateDiffText({ value: data.installation_date, required: true }, { value: moment().format(backendDateFormat), required: true }, 'Installation Date', "Today's date"),
            last_overhaul_date: checkValidDate({ value: data.last_overhaul_date, required: true, minLength: '', maxLength: '', message: 'Please Select Last Overhaul Date ' }) ? 'Please Select Last Overhaul Date ' : checkDateDiffText({ value: data.last_overhaul_date, required: true }, { value: moment().format(backendDateFormat), required: true }, 'Last Overhaul Date', "Today's date"),
            last_overhaul_csn: checkString({ value: data.last_overhaul_csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Last Overhaul CSN ' }),
            last_overhaul_tsn: checkString({ value: data.last_overhaul_tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Last Overhaul TSN ' }),
            emails: checkString({ value: data.emails, required: true, minLength: '', maxLength: '', message: 'Please Enter the Email Ids' }),
        }
        if( !attatchedDoc.length){
            validateNewInput={
                ...validateNewInput,
                attatchedDoc:'Please attach atleast one attachments'
            }
        }
        if (data?.assembly_swap == '1') {
            validateNewInput = {
                ...validateNewInput,
                daily_rate: checkString({ value: data.daily_rate, required: true, minLength: '', maxLength: '', message: 'Please Enter Daily Rate ' }),
                utilization_rate: checkString({ value: data.utilization_rate, required: true, minLength: '', maxLength: '', message: 'Please Enter Utilization Rate ' }),
                lease_start_date: checkValidDate({ value: data.lease_start_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Lease Start Date ' }),
                // lease_end_date: checkValidDate({ value: data.lease_end_date, required: true, minLength: '', maxLength: '', message: 'Please Select Lease End Date ' }),
                lease_end_date: checkDateDiffText({ value: data.lease_start_date, required: true }, { value: data.lease_end_date, required: true }, 'Lease Start Date', 'Lease End Date'),
            }
        }
        if (data?.assembly_swap == '0') {
            validateNewInput = {
                ...validateNewInput,
                sale_tsn: checkString({ value: data.sale_tsn, required: true, minLength: '', maxLength: '', message: 'Please Enter Sale TSN ' }),
                bank_details: checkString({ value: data.bank_details, required: true, minLength: '', maxLength: '', message: 'Please Select Bank ' }),
                sale_csn: checkString({ value: data.sale_csn, required: true, minLength: '', maxLength: '', message: 'Please Enter Sale CSN ' }),
                sale_price: checkString({ value: data.sale_price, required: true, minLength: '', maxLength: '', message: 'Please Enter Sale Price ' }),
                sold_to: checkString({ value: data.sold_to, required: true, minLength: '', maxLength: '', message: 'Please Select Sold To ' }),
                sale_date: checkValidDate({ value: data.sale_date, required: true, minLength: '', maxLength: '', message: 'Please Select Sale Date ' }) ? 'Please Select Sale Date ' : checkDateDiffText({ value: data.sale_date, required: true }, { value: moment().format(backendDateFormat), required: true }, 'Sale Date', "Today's date"),
            
            }
        }
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
            setPageLoader(true)
            globalPostService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease/`, data)
                .then(response => {
                    setPageLoader(false)
                    if (checkApiStatus(response)) {
                        if (attatchedDoc.length) {
                            let formdata = new FormData()
                            attatchedDoc.map(item => {
                                formdata.append('files', item)
                            })
                            formdata.append('asset_type', assetId[props.params?.type])
                            formdata.append('asset_slug', props.params.aircraft_slug)
                            formdata.append('assembly_lease_slug', response.data.data.slug)
                            formdata.append('model_type', '4')
                            setAttachmentLoader(true)
                            globalPostService(`contracts/attachments/`, formdata)
                                .then(response => {
                                    setAttachmentLoader(false)
                                    if (response.data.statusCode == 200) {
                                        toastFlashMessage(`Lease added and ${response.data.message}`, 'success')
                                    }
                                    else {
                                        toastFlashMessage(response.data.message, 'error')
                                    }
                                })
                        }
                        setModal(false)
                        if(!attatchedDoc.length ){
                            toastFlashMessage(response.data.message, 'success')
                        }
                    }
                    else {
                        // setModal(false)
                        toastFlashMessage(response.data.message, 'error')
                    }
                    getAssemblyListFn()
                })
        }
        else {
            setValidationMsgs(validateNewInput)
        }
    }
    const getAssignmentMsnListFn = () => {
        globalGetService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease-assets/assignment-msn/`)
            .then(response => {
                setAssignmentmsnList(response.data.data.list)
            })
    }
    const updateErrorField = (key) => {
        setValidationMsgs({ ...validationMsgs, [key]: '' })
    }
    const getmajorAssembliesListFn = () => {
        globalGetService(`contracts/${props.params.type}/${props.params.aircraft_slug}/assembly-lease-assets/major-assemblies/`)
            .then(response => {
                setMajorAssembliesList(response.data.data.list)
            })
    }
    const onLeaseDetailsChange = (key, leaseData) => {
        setAssemblyLeaseDetails({ ...assemblyLeaseDetails, [key]: leaseData })
        if(key =='assignment_msn' ){
            setAssemblyLeaseDetails({...assemblyLeaseDetails, sold_to:leaseData?.lessor_name, [key]: leaseData})
        }
        if(key =='major_assembly' ){
            setAssemblyLeaseDetails({
                ...assemblyLeaseDetails,
                removal_tsn: leaseData?.tech_specs?.tsn.toString()||'',
                removal_csn:leaseData?.tech_specs?.csn.toString()||'',
                last_overhaul_csn:leaseData?.maintenance?.tsn.toString()||'',
                last_overhaul_tsn:leaseData?.maintenance?.csn.toString()||'',
                installation_tsn:leaseData?.tech_specs?.tsn.toString()||'',
                installation_csn:leaseData?.tech_specs?.csn.toString()||'',
                [key]: leaseData
            })
        }
    }
    const redirectToDetails = (data) => {
        browserHistory.push(`/${props.params.type}/${props.params.aircraft_slug}/assembly-details/${data.slug}`)
    }
    const getBanksListFn = () => {
        globalGetService(`console/contract/aircraft/${props.params?.aircraft_slug}/`)
        .then(response => {
            let lessor_id = response?.data?.data?.lessor_name?.id
            let lessor_level = response?.data?.data?.lessor_name?.lessor_level
            globalGetService(`console/bank-details/`,{lessor_id:lessor_id,lessor_level:lessor_level })
            .then(response => {
                setBankDropdownList(response.data.data)
            })
        })
    }
    const changeMsn = (evt) => {
        let url = '/' + assetsType[evt.asset_type] + '/' + evt.slug + '/contracts';
        browserHistory.push(url);
        window.location.reload(false)
        props.params.type = assetsType[evt.asset_type];
        props.params.aircraft_slug = evt.slug;
    }
    return (
        <div className="contract-container">
            {
                pageLoader || attachmentLoader ? <PageLoader /> : null
            }
            <Header headingText="Lease Management" type="short" changeMsn={changeMsn} params={props.params} />
            <ContractSideBar application={`Lease History`} type="shorter" params={props.params} />
            <div className="contract-content-blk">
                <AssemblyLeaseCRU
                    setDefaultState={setDefaultState}
                    bankDropdownList={bankDropdownList}
                    positionList={positionList}
                    setValidationMsgs={setValidationMsgs}
                    setModal={setModal}
                    modal={modal}
                    setAttatchedDoc={setAttatchedDoc}
                    updateErrorField={updateErrorField}
                    validationMsgs={validationMsgs}
                    majorAssembliesList={majorAssembliesList}
                    addAssemblyLeaseDetailsFn={addAssemblyLeaseDetailsFn}
                    assemblyLeaseDetails={assemblyLeaseDetails}
                    assignmentmsnList={assignmentmsnList}
                    onLeaseDetailsChange={onLeaseDetailsChange}

                />
                <TableListComp
                    heads={assemblyListHd}
                    data={assemblyList?.list?.map((item, index) =>
                        <AssemblyLeaseList
                            redirectToDetails={redirectToDetails}
                            item={item}
                        />
                    )}
                    noRecord={ assemblyList?.list?.length ? null:
                        pageLoader ? null :
                        <EmptyCollection
                          title="No records found"
                        />
                      }

                />
            </div>
        </div>
    )
}
export default RoleBasedAccess(AssemblyListing,['assembly_lease','assembly_lease_details','R']);
