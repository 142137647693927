import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { removeEmptyKey } from '../../utils'
import { assetsListAc } from '../actionCreators';
import { ASSET_FILTER_UPDATE, LIST_LOADER } from '../actions';
import { browserHistory } from 'react-router';
class AssetFilterField extends Component {
  constructor(props){
    super(props);
    this.state = {
      fieldValue: '',
      fieldFocus : false,
    };
  }

  handleFocus = () => {
    this.setState({
      fieldFocus: true
    })

  }

  handleBlur = () => {
    this.setState({
      fieldFocus: false
    })
  }
  componentDidMount(){
    this.updateInitalFilter(this.props);
  }
  componentWillReceiveProps(nextProps){
    this.updateInitalFilter(nextProps);
  }
  updateInitalFilter = (data) => {
    const value = (data.filterData[data.fieldObj.keyParam] ? data.filterData[data.fieldObj.keyParam]: '');
    this.setState({
      fieldValue: value
    })
  }

  changeFilterVal = (value) => {
    if(this.props.fieldObj.inputType == 'text'){
      this.setState({
        fieldValue: value
      })
    }else{
      this.setState({
        fieldValue: value.split('')
      })
    }
  }

  assetFilterChange = (selectdVal) => {
    let array = [];
    selectdVal.map(value => {
      array.push(value[this.props.fieldObj.valueKey]);
    });
    this.setState({
      fieldValue: array
    })
  }

  updateAssetFilter = (e) => {
    e.preventDefault();
    let queryFilter = Object.assign({}, this.props.assetFilter);
    queryFilter = {
      ...queryFilter,
      [this.props.fieldObj.keyParam]: (this.props.fieldObj.inputType == 'text' ? this.state.fieldValue : this.state.fieldValue.join()),
      page: 1
    };
    const filterParams = removeEmptyKey(queryFilter);
    this.props.setLoader();
    this.props.updateAssetFilter((this.props.fieldObj.inputType == 'text' ? this.state.fieldValue : this.state.fieldValue.join()), this.props.fieldObj.keyParam)
    this.props.fetchAssetLists(filterParams, false);
    this.props.updateSlectedItem('');
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    location.query= filterParams;
    browserHistory.push(location);
  }

  handleFocus = () => {
    this.setState({
      fieldFocus: true
    })

  }

  handleBlur = () => {
    this.setState({
      fieldFocus: false
    })
  }

  render() {
    const { fieldObj } = this.props;
    if( fieldObj.inputType == 'text' ){
      return(
        <form onSubmit= {this.updateAssetFilter}>
          <div className="match-body">
            <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'} }>Matches</label>
            <input type="text" value={this.state.fieldValue}
            onChange={(e) => this.changeFilterVal(e.target.value)}
            onFocus={() => this.handleFocus()}
            onBlur={() => this.handleBlur()}
            />
          </div>
          <div className="match-footer">
            <input className="primary-btn" type="submit" value="Apply"
                  onFocus={() => this.handleFocus()}
                  onBlur={() => this.handleBlur()}
            />
          </div>
        </form>
      )
    }

    if(fieldObj.inputType == 'dropdown'){
      return(
        <form onSubmit= {this.updateAssetFilter}>
          <div className="match-body">
            <label style={ this.state.fieldFocus ? {color:'#2f8cff'}  : {color:'#828282'} }>Matches</label>
            <Select
                name="form-field-name"
                value={this.state.fieldValue}
                onChange={this.assetFilterChange}
                options={fieldObj.options}
                placeholder = {fieldObj.placeholder}
                matchProp = "any"
                labelKey = {fieldObj.labelKey}
                valueKey	= {fieldObj.valueKey}
                multi = {true}
                onFocus={() => this.handleFocus()}
                onBlur={() => this.handleBlur()}
            />
          </div>
          <div className="match-footer">
            <input className="primary-btn"  type="submit" value="Apply" />
          </div>
        </form>
      )
    }

  }
}
const mapStateToProps = state => ({
  assetFilter: state.userAssetReducer.assetFilter
})
const mapDispatchToProps = dispatch => {
  return {
    setLoader: () => dispatch({
      type: LIST_LOADER,
      payload: true
    }),
    updateAssetFilter: (value, type) => dispatch({
      type: ASSET_FILTER_UPDATE,
      payload: {value, type},
    }),
    fetchAssetLists: (filterParams, allTypeFlag) => dispatch(assetsListAc(filterParams, allTypeFlag))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetFilterField);
