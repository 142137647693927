import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core'
import React, { Fragment } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { Autocomplete } from '@material-ui/lab';
import { checkPermission } from '../../../utils_v2';
export default function MouAddEdit({ validationMsgs, updateErrorField, setModal, modal, addEditMou, lesseeList, lessorList, mouDetails, onChangeMouDetails, setEditModal, setValidationMsgs }) {
    return (
        <Fragment>
            {checkPermission('contracts', 'mou_list', 'C')?
                <Button style={{ float: 'right', marginBottom: '13px' }} variant="contained" color="primary" onClick={() => { setModal(true); setEditModal({}); setValidationMsgs({}) }}><AddIcon fontSize='small' />Add MOU</Button>
                : null
            }
            {modal ?
                <Dialog open='true' onClose={() => setModal(false)} maxWidth={'md'} className='mou-dialog'>
                    <DialogTitle> {mouDetails?.id ? ' Edit ' : 'Add '} MOU Details </DialogTitle>
                    <DialogContent dividers style={{ width: "900px", height: '250px' }}>
                        <Grid container spacing='3'>
                            <Grid item sm='4'>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        placeholder='DD-MM-YYYY'
                                        style={{ position: "relative", top: '16px' }}
                                        inputVariant="outlined"
                                        required
                                        variant='outlined'
                                        margin="none"
                                        id="date"
                                        label="MOU Date"
                                        value={mouDetails?.mou_date ? mouDetails?.mou_date : null}
                                        format={fieldDateFormat}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        error={validationMsgs.mou_date ? true : false}
                                        helperText={validationMsgs.mou_date ? validationMsgs.mou_date : ''}
                                        onFocus={() => updateErrorField('mou_date')}
                                        onChange={(data, value) => { onChangeMouDetails('mou_date', data ? moment(data).format(backendDateFormat) : null) }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    options={lesseeList}
                                    getOptionLabel={option => option.name}
                                    id="application"
                                    value={mouDetails.lessee ? mouDetails.lessee : null}
                                    onChange={(e, value) => { onChangeMouDetails('lessee', value) }}
                                    renderInput={params => <TextField required {...params} error={validationMsgs.lessee ? true : false} onFocus={() => updateErrorField('lessee')} helperText={validationMsgs.lessee ? validationMsgs.lessee : ''} label="Lessee" margin="normal" placeholder='Please Select Lessee' fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={4}>
                                <Autocomplete
                                    options={lessorList}
                                    getOptionLabel={option => option.name}
                                    id="application"
                                    value={mouDetails.lessor_name ? mouDetails.lessor_name : null}
                                    onChange={(e, value) => { onChangeMouDetails('lessor_name', value) }}
                                    renderInput={params => <TextField required {...params} error={validationMsgs.lessor_name ? true : false} onFocus={() => updateErrorField('lessor_name')} helperText={validationMsgs.lessor_name ? validationMsgs.lessor_name : ''} label="Lessor" margin="normal" placeholder='Please Select Lessor' fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item sm='4'>
                                <TextField
                                    required
                                    label='Managed'
                                    id="tsn"
                                    fullWidth
                                    placeholder='Please Enter Managed Information'
                                    margin="normal"
                                    value={mouDetails.managed ? mouDetails.managed : ''}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 25 }}
                                    error={validationMsgs.managed ? true : false}
                                    onFocus={() => updateErrorField('managed')}
                                    helperText={validationMsgs.managed ? validationMsgs.managed : ''}
                                    onChange={(e, data) => onChangeMouDetails('managed', e.target.value)}
                                />
                            </Grid>
                            <Grid item sm='4'>
                                <TextField
                                    required
                                    label='Linked Asset'
                                    id="asset"
                                    fullWidth
                                    placeholder='Please Enter Asset'
                                    margin="normal"
                                    value={mouDetails.tied_asset ? mouDetails.tied_asset : ''}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 25 }}
                                    error={validationMsgs.tied_asset ? true : false}
                                    onFocus={() => updateErrorField('tied_asset')}
                                    helperText={validationMsgs.tied_asset ? validationMsgs.tied_asset : ''}
                                    onChange={(e, data) => onChangeMouDetails('tied_asset', e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions dividers>
                        <Button style={{ marginRight: '10px' }} color='primary' variant='outlined' size='small' onClick={() => setModal(false)} >
                            Cancel
                        </Button>
                        <Button color='primary' variant='contained' size='small' onClick={() => addEditMou()} >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                : null
            }
        </Fragment>
    )
}
