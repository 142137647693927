import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
import HDLabelCard from './HDLabelCard';
import { ToolTipHover, InfoIcon } from '../../../../shared'
import { getLocalStorageInfo } from '../../../../utils';
import { checkPermission } from '../../../../utils_v2';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
export default class HorizontalInfoCard extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { leaseInfo ,autoInvoiceOn} = this.props;
    return (
      <div className="horizontal-card card-block">
        <h5 className="para"> Lease Information
          {leaseInfo?.archived_asset == false?
          checkPermission('contracts','lease_details', 'U') ?
            <img onClick={this.props.toggleEditSideBar} src={editIcon} alt="img" className="blue-img" width="16" /> :
            null: null
          }
        </h5>
        <Row>
          <HDLabelCard col="4" label="Lease Type" value={leaseInfo.lease_type && leaseInfo.lease_type.label ? leaseInfo.lease_type.label : '--'} />
          <HDLabelCard col="4" label="Lease Type ( Accounting )" value={leaseInfo.lease_type_accounting && leaseInfo.lease_type_accounting.label ? leaseInfo.lease_type_accounting.label : '--'} />
          <HDLabelCard col="4" label="Lease Number" value={leaseInfo.contract_number ? leaseInfo.contract_number : '--'} />
          <HDLabelCard col="4" label="Lease Definition of Lease Period" value={leaseInfo.contractual_definition_of_lease_period ? leaseInfo.contractual_definition_of_lease_period : '--'} />
          {leaseInfo.rolling_lease_status && <HDLabelCard col="4" label="Rolling Lease (Enabled)" value={ leaseInfo.rolling_lease_span === 1 ? leaseInfo.rolling_lease_duration + ' ' + 'Days' : leaseInfo.rolling_lease_span === 2 ? leaseInfo.rolling_lease_duration +  ' ' + 'Month' : '--'} />}
          <HDLabelCard col="4" label="Lease Duration Type" value={leaseInfo?.lease_duration_type?.label ? leaseInfo.lease_duration_type.label : '--'} />
          <HDLabelCard col="4" label="Lease Start Date" value={leaseInfo?.contractual_start_date ? moment(leaseInfo?.contractual_start_date).format(displayDateFormat) : '--'} />
          {
           leaseInfo.lease_duration_type.value == 1?
           <>
          <HDLabelCard col="4" label="Lease End Date" value={leaseInfo.contractual_end_date ? moment(leaseInfo.contractual_end_date).format(displayDateFormat) : '--'} />
          <HDLabelCard col="4" label="Lease Term" value={leaseInfo.lease_term ? leaseInfo.lease_term + ' Months' : '--'} />
          </>
          :
          leaseInfo.lease_duration_type.value == 2 ?
          <>
          <HDLabelCard col="4" label="Start TSN" value={leaseInfo.tsn ? leaseInfo.tsn : '--'} />
          <HDLabelCard col="4" label="Lease End Date" value={leaseInfo.contractual_end_date ? moment(leaseInfo.contractual_end_date).format(displayDateFormat) : '--'} />
          <HDLabelCard col="4" label="Lease Term" value={leaseInfo.lease_term ? leaseInfo.lease_term + ' Months' : '--'} />
          <HDLabelCard col="4" label="Lease Duration Hours" value={leaseInfo.lease_duration_hours ? leaseInfo.lease_duration_hours + ' Hours' : '--'} />
          </>
          :
          <>
          <HDLabelCard col="4" label="Start TSN" value={leaseInfo.tsn ? leaseInfo.tsn : '--'} />
          <HDLabelCard col="4" label="Lease Duration Hours" value={leaseInfo.lease_duration_hours ? leaseInfo.lease_duration_hours + ' Hours' : '--'} />
          <HDLabelCard col="4" label="Time between Overhaul" value={leaseInfo.time_between_overhaul ? leaseInfo.time_between_overhaul : '--'} />
          <HDLabelCard col="4" label="Last Overhaul TSN" value={leaseInfo.last_overhaul_tsn ? leaseInfo.last_overhaul_tsn : '--'} />
          </>
         }
          <HDLabelCard col="4" label="Conditions Precedent" value={leaseInfo.conditions_precedence ? leaseInfo.conditions_precedence : '--'} />
          <HDLabelCard col="4" label="Alternate Dispute Redressal" value={leaseInfo.alternate_dispute_redressal ? leaseInfo.alternate_dispute_redressal : '--'} />
          <HDLabelCard spanStyle={{ wordBreak: 'break-all' }} col="4" label="Rental Account Number" value={leaseInfo.rental_account_number ? leaseInfo.rental_account_number : '--'} />
          <HDLabelCard spanStyle={{ wordBreak: 'break-all' }} col="4" label="MR Account Number" value={leaseInfo.mr_account_number ? leaseInfo.mr_account_number : '--'} />
          {/* <HDLabelCard spanStyle={{ wordBreak: 'break-all' }} col="4" label="Supplement Date" value={leaseInfo.supplement_date ? moment(leaseInfo.supplement_date).format(displayDateFormat) : '--'} /> */}
          <Col md="4" className="horizontal-inner-block">
           <h6>Insurance Type <InfoIcon id={"insurance"} />
           <ToolTipHover placement="top" tagetId={"insurance"}>
             Contingent or Possessed
           </ToolTipHover>
           </h6>
           <span style={{ wordBreak: 'break-all' }}>{leaseInfo.insurance_type ? leaseInfo.insurance_type : '--'}</span>
          </Col>
          {autoInvoiceOn.auto_invoice_generate ? <HDLabelCard spanStyle={{ wordBreak: 'break-all' }} col="4" label="Default Bank" value={leaseInfo.default_bank ? leaseInfo.default_bank.name : '--'} /> : null}

          {
            leaseInfo.actual_lease_date ?
              <HDLabelCard col="4" label="Actual Lease End Date" value={leaseInfo.actual_lease_date ? moment(leaseInfo.actual_lease_date).format(displayDateFormat) : '--'} />
              : null
          }
          <Col md='4' className="horizontal-inner-block">
            <h6>{"Insurance Type"}
              <InfoIcon id={"insurance_type"} />
              <ToolTipHover placement="top" tagetId={"insurance_type"}>
                Contingent or Possessed
              </ToolTipHover></h6>
            <span >{leaseInfo.insurance_type ? leaseInfo.insurance_type : '--'}</span>
          </Col>
        </Row>
      </div>
    )
  }
}
