import React, { Component } from 'react'
import { sendCredsToDb } from '../apiServices';
import { Paper } from '@material-ui/core';
import { browserHistory } from 'react-router';
const style = {
    homePage: {
        marginLeft: "60px"
    }
}
export default class ThirdPartyLanding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            remaining: 5
        }
        this.sendCredsToDb = sendCredsToDb.bind(this)
    }
    componentDidMount() {
        let code = window.location?.search.split('code=')[1].split('&')[0]
        let realmId = window.location?.search.split('realmId=')[1]
        let state = window.location?.search.split('state=')[1].split('&')[0]
        setTimeout(() => {
            browserHistory.push(`assets-listing`)
        }, 5000)
        let i = 5
        setInterval(() => {
            i--;
            this.setState({ remaining: i })
        }, 1000);
        if (code == 'error') {
            console.log('Error');
        }
         else{
             sendCredsToDb({auth_code:code,realm_id:realmId, state:state })
         }
    }
    render() {
        let code = window.location?.search?.split('code=')[1].split('&')[0]
        return (
            <div style={style.homePage}>
                <Paper>
                    <div style={{ height: '92vh', textAlign: 'center' }}>
                        {
                            code == 'error' ?
                                <div style={{ fontSize: '40px' }} > Oops, Something went wrong</div>
                                :
                                <div style={{ fontSize: '40px' }} >
                                    Authentication successful with QuickBooks
                                </div>
                        }
                        <div>
                            Redirecting back to Asset listing in {this.state.remaining} seconds.
                        </div>
                    </div>
                </Paper>
            </div>
        )
    }
}
