import React from 'react';
import { Paper,Divider, Grid } from '@material-ui/core';
import { FilterComponent } from '../../../shared_elements';
import Skeleton from '@material-ui/lab/Skeleton';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import { leasesTypeGraphFilterOps } from '../';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const LeaseTypeGraph = ({ pltConstantsLeaseStatus, skeletonLoader, leaseTypeGraph, filter, getResponseBack, callExternalFunction, leases }) => {
    let filterOptions = Object.assign({}, leasesTypeGraphFilterOps);
    filterOptions = {
        ...filterOptions,
        lease_status: {
            ...filterOptions.lease_status,
            options: pltConstantsLeaseStatus
        }
    }
    var colors = leaseTypeGraph && leaseTypeGraph.color;
    const options = {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 25
            },
            styledMode: true
        },
        title: {
            text: 'Based on Type',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        subtitle: {
            text: `${leaseTypeGraph.title ? leaseTypeGraph.title : ''}`,
            align: 'left'
        },
        xAxis: {
            categories: leaseTypeGraph && leaseTypeGraph.lease_type && leaseTypeGraph.lease_type.x_axis && leaseTypeGraph.lease_type.x_axis.categories
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Number of Leases'
            }
        },
        colors:colors,
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 60,
                dataLabels: {
                    enabled: true,
                    softConnector: false
                },
                point: {
                    events: {
                      click: function(oEvent) {
                        callExternalFunction(oEvent.point.name, '', 'basedOnType');
                      }
                    }
                  },
                showInLegend: false,
                allowPointSelect: true,
                cursor: 'pointer',
                animation:false
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> <br/>'
        },
        series: [{
            name:'Count',
            data: leaseTypeGraph && leaseTypeGraph.lease_type && leaseTypeGraph.lease_type,
            colorByPoint: true,
            dataLabels: {
                formatter: function () {
                    return this.point.name + ':</b> ' +
                        '('+this.y+')';
                }
            }
        }],
        credits: false,
        exporting: {
            buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadPDF'],
                },
            }
        }
    }

    return (
        skeletonLoader ? <Skeleton variant="rect" height={700} width='100%' /> : 
        <Paper>
            <div style={{padding:'0 5px'}}>
                <FilterComponent 
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => getResponseBack(applyFilter)}
                />
            </div>
            <Divider />
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
            
        </Paper>
    )
}
export default LeaseTypeGraph
