import React, { Component } from 'react';
import { Collapse,  Card, CardBody} from 'reactstrap';
import { imgStoragePath } from '../../../constants';
import arrowIcon from '../../../shared/assets/img/down_arrow.svg';
export default class CollapseWrapper extends Component {
    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
             collapse: false,
             alphabet:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
             };
    }
    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }
    render(){
        const{ section ,subSection,index} = this.props;
        return(
            <div className="form-collapse-wrapper">
                <h6 className="form-collapse-title"  onClick={this.toggle}>
                    {subSection? subSection==1? this.state.alphabet[index]+'. '+section.name:index+1+'. '+section.name :section.name}
                   <img  style={this.state.collapse ? { transform: 'rotate(180deg)'} : { transform: 'rotate(0deg)' }} src={arrowIcon} alt="img"/>
                </h6>
                <Collapse isOpen={this.state.collapse}>
                    <Card>
                        <CardBody>
                            {this.props.children}
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        )
    }
}
