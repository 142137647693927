import React, { Fragment, useState } from 'react'
import AddIcon from '@material-ui/icons/Add';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Tooltip } from '@material-ui/core';
import { checkPermission, getLocalStorageInfo } from '../../../utils_v2';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort, invoiceStatusDrpdwn } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import { Info } from '@material-ui/icons';
export default function AssemblyLeaseCRU({ archived_asset, setModal, modal, setAttatchedDoc, setDefaultState, setValidationMsgs, validationMsgs, updateErrorField, positionList, majorAssembliesList, assignmentmsnList, assemblyLeaseDetails, onLeaseDetailsChange, addAssemblyLeaseDetailsFn, assemblyList, bankDropdownList , setAssemblyLeaseDetails}) {

    return (
        <Fragment>
            <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                {checkPermission('assembly_lease','assembly_lease_details','C') ?
                    <Button variant="contained" onClick={() => { setModal(true); setValidationMsgs({}) ; setDefaultState() }} color="primary"><AddIcon fontSize='small' />Add Assembly Lease</Button> : null
                }
            </div>
            {
                modal ?
                    <Dialog
                        open='true'
                        maxWidth='lg'
                        className='assembly-lease-modal'
                        onClose={() => setModal(false)}
                    >
                        <DialogTitle dividers={true} >
                            Add Assembly Lease
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <Grid container spacing='2'>
                                <Grid item sm='9' >
                                    <Grid container spacing='2'>
                                        <Grid item sm='4' xs='12'>
                                            <Autocomplete
                                                style={{ marginTop: '16px' }}
                                                options={assignmentmsnList}
                                                getOptionLabel={option => option?.lessor_name?.name ? option.unique_name + ' (' + option?.lessor_name?.name + ')' : option.unique_name}
                                                value={assignmentmsnList.find((item) => item?.unique_name === assemblyLeaseDetails?.assignment_msn)}
                                                id="assignment_msn"
                                                onChange={(e, value) => onLeaseDetailsChange('assignment_msn', value)}
                                                renderInput={params => <TextField required error={validationMsgs.assignment_msn ? true : false} helperText={validationMsgs?.assignment_msn ? validationMsgs?.assignment_msn : ''} onFocus={() => updateErrorField('assignment_msn')} style={{ padding: '0px' }} {...params} label="Assignment MSN" placeholder="Select Assignment MSN" InputLabelProps={{ shrink: true }} variant="outlined" />}
                                            />
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <Autocomplete
                                                style={{ marginTop: '16px' }}
                                                options={majorAssembliesList}
                                                getOptionLabel={option => option?.lessor?.name ? option.unique_name + ' (' + option?.lessor?.name + ')' : option.unique_name}
                                                value={assignmentmsnList.find((item) => item?.unique_name === assemblyLeaseDetails?.major_assembly)}
                                                id="major_assembly"
                                                onChange={(e, value) => onLeaseDetailsChange('major_assembly', value)}
                                                renderInput={params => <TextField required {...params} error={validationMsgs.major_assembly ? true : false} helperText={validationMsgs?.major_assembly ? validationMsgs?.major_assembly : ''} onFocus={() => updateErrorField('major_assembly')} label="Major Assembly" placeholder="Select Major Assembly" InputLabelProps={{ shrink: true }} variant="outlined" />}
                                            />
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <Autocomplete
                                                style={{ marginTop: '16px' }}
                                                options={positionList}
                                                getOptionLabel={option => option.label}
                                                value={assemblyLeaseDetails?.position}
                                                id="position"
                                                onChange={(e, value) => onLeaseDetailsChange('position', value)}
                                                renderInput={params => <TextField required {...params} error={validationMsgs.position ? true : false} helperText={validationMsgs?.position ? validationMsgs?.position : ''} onFocus={() => updateErrorField('position')} label="Position" placeholder="Select Position" InputLabelProps={{ shrink: true }} variant="outlined" />}
                                            />
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder='DD-MM-YYYY'
                                                    style={{ position: "relative", top: '16px' }}
                                                    inputVariant="outlined"
                                                    required
                                                    variant='outlined'
                                                    margin="none"
                                                    id="date"
                                                    maxDate={moment()}
                                                    label="Removal Date"
                                                    value={assemblyLeaseDetails?.removal_date ? assemblyLeaseDetails?.removal_date : null}
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    error={validationMsgs.removal_date ? true : false}
                                                    helperText={validationMsgs.removal_date ? validationMsgs.removal_date : ''}
                                                    onFocus={() => updateErrorField('removal_date')}
                                                    onChange={(data, value) => { onLeaseDetailsChange('removal_date', moment(data).format(backendDateFormat)) }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <TextField
                                                required
                                                label='Removal TSN'
                                                placeholder='Please Enter Removal TSN'
                                                id="removaltsn"
                                                fullWidth
                                                margin="normal"
                                                value={assemblyLeaseDetails?.removal_tsn ? assemblyLeaseDetails?.removal_tsn : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                                error={validationMsgs.removal_tsn ? true : false}
                                                helperText={validationMsgs.removal_tsn ? validationMsgs.removal_tsn : ''}
                                                onFocus={() => updateErrorField('removal_tsn')}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('removal_tsn', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <TextField
                                                required
                                                label='Removal CSN'
                                                id="removaltsn"
                                                placeholder='Please Enter Removal CSN'
                                                fullWidth
                                                margin="normal"
                                                value={assemblyLeaseDetails?.removal_csn ? assemblyLeaseDetails?.removal_csn : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                                error={validationMsgs.removal_csn ? true : false}
                                                helperText={validationMsgs.removal_csn ? validationMsgs.removal_csn : ''}
                                                onFocus={() => updateErrorField('removal_csn')}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('removal_csn', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder='DD-MM-YYYY'
                                                    style={{ position: "relative", top: '16px' }}
                                                    inputVariant="outlined"
                                                    required
                                                    variant='outlined'
                                                    margin="none"
                                                    id="date"
                                                    maxDate={moment()}
                                                    label="Installation Date"
                                                    value={assemblyLeaseDetails?.installation_date ? assemblyLeaseDetails?.installation_date : null}
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    error={validationMsgs.installation_date ? true : false}
                                                    onFocus={() => updateErrorField('installation_date')}
                                                    helperText={validationMsgs.installation_date ? validationMsgs.installation_date : ''}
                                                    onChange={(data, value) => { onLeaseDetailsChange('installation_date', moment(data).format(backendDateFormat)) }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <TextField
                                                required
                                                label='Installation TSN'
                                                id="removaltsn"
                                                placeholder='Please Enter Installation TSN'
                                                fullWidth
                                                margin="normal"
                                                value={assemblyLeaseDetails?.installation_tsn ? assemblyLeaseDetails?.installation_tsn : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                                error={validationMsgs.installation_tsn ? true : false}
                                                onFocus={() => updateErrorField('installation_tsn')}
                                                helperText={validationMsgs.installation_tsn ? validationMsgs.installation_tsn : ''}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('installation_tsn', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <TextField
                                                required
                                                label='Installation CSN'
                                                id="removaltsn"
                                                placeholder='Please Enter Installation CSN'
                                                fullWidth
                                                margin="normal"
                                                value={assemblyLeaseDetails?.installation_csn ? assemblyLeaseDetails?.installation_csn : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                                error={validationMsgs.installation_csn ? true : false}
                                                onFocus={() => updateErrorField('installation_csn')}
                                                helperText={validationMsgs.installation_csn ? validationMsgs.installation_csn : ''}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('installation_csn', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder='DD-MM-YYYY'
                                                    style={{ position: "relative", top: '16px' }}
                                                    inputVariant="outlined"
                                                    required
                                                    variant='outlined'
                                                    margin="none"
                                                    id="date"
                                                    maxDate={moment()}
                                                    label="Last Overhaul Date"
                                                    value={assemblyLeaseDetails?.last_overhaul_date ? assemblyLeaseDetails?.last_overhaul_date : null}
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    error={validationMsgs.last_overhaul_date ? true : false}
                                                    onFocus={() => updateErrorField('last_overhaul_date')}
                                                    helperText={validationMsgs.last_overhaul_date ? validationMsgs.last_overhaul_date : ''}
                                                    onChange={(data, value) => { onLeaseDetailsChange('last_overhaul_date', moment(data).format(backendDateFormat)) }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <TextField
                                                required
                                                label='Last Overhaul TSN'
                                                id="removaltsn"
                                                placeholder='Please Enter Last Overhaul TSN'
                                                fullWidth
                                                margin="normal"
                                                value={assemblyLeaseDetails?.last_overhaul_tsn ? assemblyLeaseDetails?.last_overhaul_tsn : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                                error={validationMsgs.last_overhaul_tsn ? true : false}
                                                onFocus={() => updateErrorField('last_overhaul_tsn')}
                                                helperText={validationMsgs.last_overhaul_tsn ? validationMsgs.last_overhaul_tsn : ''}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('last_overhaul_tsn', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='4' xs='12'>
                                            <TextField
                                                required
                                                label='Last Overhaul CSN'
                                                id="removaltsn"
                                                placeholder='Please Enter Last Overhaul CSN'
                                                fullWidth
                                                margin="normal"
                                                value={assemblyLeaseDetails?.last_overhaul_csn ? assemblyLeaseDetails?.last_overhaul_csn : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                                error={validationMsgs.last_overhaul_csn ? true : false}
                                                onFocus={() => updateErrorField('last_overhaul_csn')}
                                                helperText={validationMsgs.last_overhaul_csn ? validationMsgs.last_overhaul_csn : ''}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('last_overhaul_csn', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item xs='12'>
                                            <TextField
                                                label={<div style={{ display: 'flex', alignItems: 'center' }}>
                                                    Emails *
                                                    <Tooltip title='To enter multiple Emails seperate them with comma (,) operator' placement='right' arrow>
                                                        <Info />
                                                    </Tooltip>
                                                </div>}
                                                id="emails"
                                                fullWidth
                                                placeholder='Please Enter Emails'
                                                margin="normal"
                                                value={assemblyLeaseDetails?.emails ? assemblyLeaseDetails?.emails : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
                                                error={validationMsgs.emails ? true : false}
                                                onFocus={() => updateErrorField('emails')}
                                                helperText={validationMsgs.emails ? validationMsgs.emails : ''}
                                                onChange={(e) => onLeaseDetailsChange('emails', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid xs='12'>
                                            <FormControl style={{ marginTop: '16px', marginLeft: '5px' }}>
                                                <FormLabel style={{ fontSize: '13px' }}>Lease Type</FormLabel>
                                                <RadioGroup row aria-label="gender" name="gender1" value={assemblyLeaseDetails?.assembly_swap} onChange={(e, value) => onLeaseDetailsChange('assembly_swap', value)}>
                                                    <FormControlLabel value={'1'} control={<Radio color="primary" />} label="Temporary Lease" />
                                                    <FormControlLabel value={'0'} control={<Radio color="primary" />} label="Permanent Swap" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm='3' >
                                    <div style={{height:'500px', overflow:'scroll'}}>
                                    <FormLabel style={{ fontSize: '13px', margin: '4px' }} error={validationMsgs?.attatchedDoc ? true : false} > Attachments *</FormLabel>
                                    <p className='error-msg'> {validationMsgs?.attatchedDoc}</p>
                                    <DropzoneArea
                                        filesLimit={10}
                                        acceptedFiles={['.xls', '.csv', '.xlsx', '.docx', '.pdf', '.jpg', '.jpeg', '.png']}
                                        showPreviewsInDropzone={false}
                                        showPreviews={true}
                                        maxFileSize={5000000000000}
                                        useChipsForPreview
                                        dropzoneText={<p>Drag & Drop Documents<br /> OR <br /> Click Here</p>}
                                        dropzoneClass="drag-drop-cnt"
                                        maxWidth="sm"
                                        showAlerts={['error', 'info']}
                                        onChange={(files) => { setAttatchedDoc(files); updateErrorField('attatchedDoc') }}
                                        // onDrop={(files) => addAttachment('attatchedDoc', files)}
                                        alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                    >
                                    </DropzoneArea>
                                    </div>
                                </Grid>

                                {assemblyLeaseDetails?.assembly_swap == '1' ?
                                    <>
                                        <Grid item sm='3' xs='12'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder='DD-MM-YYYY'
                                                    style={{ position: "relative", top: '16px' }}
                                                    inputVariant="outlined"
                                                    required
                                                    variant='outlined'
                                                    margin="none"
                                                    id="date"
                                                    label="Lease Start Date"
                                                    value={assemblyLeaseDetails?.lease_start_date ? assemblyLeaseDetails?.lease_start_date : null}
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    error={validationMsgs.lease_start_date ? true : false}
                                                    onFocus={() => updateErrorField('lease_start_date')}
                                                    helperText={validationMsgs.lease_start_date ? validationMsgs.lease_start_date : ''}
                                                    onChange={(data, value) => { onLeaseDetailsChange('lease_start_date', moment(data).format(backendDateFormat)) }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder='DD-MM-YYYY'
                                                    style={{ position: "relative", top: '16px' }}
                                                    inputVariant="outlined"
                                                    required
                                                    variant='outlined'
                                                    margin="none"
                                                    id="date"
                                                    label="Lease End Date"
                                                    value={assemblyLeaseDetails?.lease_end_date ? assemblyLeaseDetails?.lease_end_date : null}
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    error={validationMsgs.lease_end_date ? true : false}
                                                    onFocus={() => updateErrorField('lease_end_date')}
                                                    helperText={validationMsgs.lease_end_date ? validationMsgs.lease_end_date : ''}
                                                    onChange={(data, value) => { onLeaseDetailsChange('lease_end_date', moment(data).format(backendDateFormat)) }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <TextField
                                                required
                                                label='Daily Rate'
                                                id="daily_rate"
                                                placeholder='Enter Rates in USD'
                                                fullWidth
                                                margin="normal"
                                                value={assemblyLeaseDetails?.daily_rate ? assemblyLeaseDetails?.daily_rate : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                error={validationMsgs.daily_rate ? true : false}
                                                onFocus={() => updateErrorField('daily_rate')}
                                                helperText={validationMsgs.daily_rate ? validationMsgs.daily_rate : ''}
                                                onChange={(e) => onLeaseDetailsChange('daily_rate', e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item sm='3' xs='12'>

                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <TextField
                                                required
                                                label='Utilization Rate'
                                                placeholder='Enter Rates in USD'
                                                id="utilization_rate"
                                                fullWidth
                                                margin="normal"
                                                value={assemblyLeaseDetails?.utilization_rate ? assemblyLeaseDetails?.utilization_rate : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                error={validationMsgs.utilization_rate ? true : false}
                                                onFocus={() => updateErrorField('utilization_rate')}
                                                helperText={validationMsgs.utilization_rate ? validationMsgs.utilization_rate : ''}
                                                inputProps={{ maxLength: 255 }}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('utilization_rate', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='2' xs='12'>
                                            <FormControl style={{ marginTop: '16px', marginLeft: '5px' }}>
                                                <FormLabel style={{ fontSize: '13px' }}>Utilization Unit</FormLabel>
                                                <RadioGroup row aria-label="gender" name="gender1" value={assemblyLeaseDetails?.utilization_unit} onChange={(e, value) => onLeaseDetailsChange('utilization_unit', value)}>
                                                    <FormControlLabel value="2" control={<Radio color="primary" />} label="FH" />
                                                    <FormControlLabel value="3" control={<Radio color="primary" />} label="FC" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <TextField
                                                label='Lease Extension (Days)'
                                                id="lease_extension"
                                                fullWidth
                                                margin="normal"
                                                placeholder='Lease Extension (Days)'
                                                value={assemblyLeaseDetails?.lease_extension ? assemblyLeaseDetails?.lease_extension : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                                onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? onLeaseDetailsChange('lease_extension', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder='DD-MM-YYYY'
                                                    style={{ position: "relative", top: '16px' }}
                                                    inputVariant="outlined"
                                                    variant='outlined'
                                                    margin="none"
                                                    id="date"
                                                    maxDate={moment()}
                                                    label="Lease Termination"
                                                    value={assemblyLeaseDetails?.termination_date ? assemblyLeaseDetails?.termination_date : null}
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(data, value) => { onLeaseDetailsChange('termination_date', moment(data).format(backendDateFormat)) }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item sm='3' xs='12'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder='DD-MM-YYYY'
                                                    style={{ position: "relative", top: '16px' }}
                                                    inputVariant="outlined"
                                                    required
                                                    variant='outlined'
                                                    margin="none"
                                                    id="date"
                                                    maxDate={moment()}
                                                    label="Sale Date"
                                                    value={assemblyLeaseDetails?.sale_date ? assemblyLeaseDetails?.sale_date : null}
                                                    format={fieldDateFormat}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    error={validationMsgs.sale_date ? true : false}
                                                    onFocus={() => updateErrorField('sale_date')}
                                                    helperText={validationMsgs.sale_date ? validationMsgs.sale_date : ''}
                                                    inputProps={{ maxLength: 255 }}
                                                    onChange={(data, value) => { onLeaseDetailsChange('sale_date', moment(data).format(backendDateFormat)) }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <TextField
                                                required
                                                label=' TSN'
                                                id="tsn"
                                                placeholder='Please Enter TSN'
                                                fullWidth
                                                margin="normal"
                                                value={assemblyLeaseDetails?.sale_tsn ? assemblyLeaseDetails?.sale_tsn : null}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                                error={validationMsgs.sale_tsn ? true : false}
                                                onFocus={() => updateErrorField('sale_tsn')}
                                                helperText={validationMsgs.sale_tsn ? validationMsgs.sale_tsn : ''}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('sale_tsn', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <TextField
                                                label=' CSN'
                                                required
                                                id="csn"
                                                fullWidth
                                                placeholder='Please Enter CSN'
                                                margin="normal"
                                                value={assemblyLeaseDetails?.sale_csn ? assemblyLeaseDetails?.sale_csn : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                                error={validationMsgs.sale_csn ? true : false}
                                                onFocus={() => updateErrorField('sale_csn')}
                                                helperText={validationMsgs.sale_csn ? validationMsgs.sale_csn : ''}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('sale_csn', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <TextField
                                                required
                                                label=' Sale Price'
                                                id="sale_price"
                                                fullWidth
                                                placeholder='Enter Sale Price in USD'
                                                margin="normal"
                                                value={assemblyLeaseDetails?.sale_price ? assemblyLeaseDetails?.sale_price : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                                error={validationMsgs.sale_price ? true : false}
                                                onFocus={() => updateErrorField('sale_price')}
                                                helperText={validationMsgs.sale_price ? validationMsgs.sale_price : ''}
                                                onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onLeaseDetailsChange('sale_price', e.target.value) : e.preventDefault()}
                                            />
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <TextField
                                                label='Sold To'
                                                id="sold_to"
                                                fullWidth
                                                margin="normal"
                                                disabled={true}
                                                value={assemblyLeaseDetails?.sold_to?.name ? assemblyLeaseDetails?.sold_to.name : ''}
                                                variant='outlined'
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ maxLength: 255 }}
                                            />
                                        </Grid>
                                        <Grid item sm='3' xs='12'>
                                            <Autocomplete
                                            style={{ marginTop: '16px' }}
                                            options={bankDropdownList}
                                            getOptionLabel={option => option.name + ' (' + option?.account_number + ')'}
                                            value={assemblyLeaseDetails?.bank_details}
                                            id="bank_details"
                                            onChange={(e, value) => onLeaseDetailsChange('bank_details', value)}
                                            renderInput={params => <TextField required error={validationMsgs.bank_details ? true : false} helperText={validationMsgs?.bank_details ? validationMsgs?.bank_details : ''} onFocus={() => updateErrorField('bank_details')} {...params} label="Select Bank" placeholder="Select Bank name" InputLabelProps={{ shrink: true }} variant="outlined" />}
                                        />
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button color='primary' variant='outlined' size='small' onClick={() => setModal(false)} >
                                Cancel
                            </Button>
                            <Button color='primary' variant='contained' size='small' onClick={() => addAssemblyLeaseDetailsFn()} >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog> : null
            }
        </Fragment>
    )
}
