import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService, globalExportService, globalDeleteService } from '../../../../globalServices/';
import { toastFlashMessage, downloadFileType } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel'
import moment from 'moment';
import { browserHistory } from 'react-router';
import { CTR_SHORT_INFO } from '../../Listing/actions';
import { getLocalStorageInfo } from '../../../../utils_v2';
let assetTypeNames = {
  3: 'APU',
  4: 'LG',
  5: 'PROPELLER'
}
export const invoiceDatesAc = (contract_slug,value, security_deposit_type, key) => {
  return (dispatch) => {
    dispatch({
      type: actions.INV_STR_LOADER,
      payload: true
    });
    dispatch({
      type: actions.GEN_INVOICE,
      payload: {type: 'dates', value: []}
    });
    if(value){
      globalGetService(`contracts/${contract_slug}/invoices/initialize/`, {invoice_type: value, [key]: security_deposit_type})
      .then(response => {
        dispatch({
          type: actions.INV_STR_LOADER,
          payload: false
        });
        if(response.data.data && response.data.data.bank_details && response.data.data.bank_details.length === 0){
          toastFlashMessage('No Banking Detail available. To generate invoice add atleast one banking detail.', 'error',15000);
        }else{
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'bank_details', value: response.data.data.bank_details}
          });
        }
        if(response.data.statusCode == 200){
          let dates = [];
          if(parseInt(value) === 6){
            dispatch({
              type: actions.GEN_INVOICE,
              payload: {type: 'security_deposit', value: response.data.data.security_deposit}
            });
          }else if(parseInt(value) == 1 || parseInt(value) == 2){
          response.data.data.months.map(month => {
            dates.push({label: moment(month).format('MMM-YYYY'), value: month})
          });
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'dates', value: dates}
          });
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'security_deposit', value: []}
          });
        }else if(parseInt(value) == 7){
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'dates', value: dates}
          });
          let data = []
          response.data.data.insurance.map(item => {
            data.push({
              id: item.insurance_id,
              type: item.type + ' (' + item.insurance_number + ')'
            })
          })
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'insurances', value: data}
          });
        }else if(parseInt(value) == 8){
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'dates', value: dates}
          });
          let data = []
          response.data.data.insurance.map(item => {
            data.push({
              id: item.insurance_id,
              type: item.type + ' (' + item.insurance_number + ')'
            })
          })
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'insurance_claim', value: data}
          });
        }else if(parseInt(value) === 9){
          response.data.data.months.map(month => {
            dates.push({label:  `${month.invoice_number}  ( ${moment(month.invoice_date).format('MMM, YYYY')} )`, value: month.id})
          });
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'dates', value: dates}
          });
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'security_deposit', value: []}
          });
        }else{
            response.data.data.months.map(month => {
              dates.push({label:  `${month.invoice_number}`, value: month.id})
            });
            dispatch({
              type: actions.GEN_INVOICE,
              payload: {type: 'dates', value: dates}
            });
            dispatch({
              type: actions.GEN_INVOICE,
              payload: {type: 'security_deposit', value: []}
            });
          }
        }else{
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'dates', value: []}
          });
          dispatch({
            type: actions.GEN_INVOICE,
            payload: {type: 'security_deposit', value: []}
          });
        }
      })
    }else{
      dispatch({
        type: actions.GEN_INVOICE,
        payload: {type: 'dates', value: []}
      });
      dispatch({
        type: actions.GEN_INVOICE,
        payload: {type: 'security_deposit', value: []}
      });
      dispatch({
        type: actions.INV_STR_LOADER,
        payload: false
      });
    }
  }
}
export const genInvoiceStrAc = (params, contract_slug, data, assetViewId) => {
  return (dispatch) => {
    dispatch({
      type: actions.INV_STR_LOADER,
      payload: true
    });
    let newData = Object.assign({}, data);
    if(data.invoice_type == '3'||data.invoice_type == '4'||data.invoice_type == '5' || data.invoice_type == '6'){
      newData = {
        ...newData,
        id: data.month
      }
      delete newData['month']
    }
    globalPostService(`contracts/${contract_slug}/invoices/structure/`, newData)
    .then(response => {
      dispatch({
        type: actions.INV_STR_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        trackActivity('Item Added', {
          page_title: `Invoice List`,
          application_module: `Contracts`,
          asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
          contract_slug: assetViewId.contract.contract_slug,
          item_type: `Invoice`,
          item_id: response.data.data.id
        })
        if(response.data.data.is_libor){
          toastFlashMessage('Please note: LIBOR value is picked up from last month', 'success',15000);
        }
        browserHistory.push(`/${params.type}/${params.aircraft_slug}/contract/${contract_slug}/invoice/view/${response.data.data.id}`);
        dispatch({
          type: actions.GEN_INVOICE,
          payload: {type: 'flag', value: false}
        });

      }
    })
  }
}
export const InvoiceAssetViewAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.INVOICE_LOADER,
      payload: true
    });
    globalGetService(`console/contract/${params.type}/${params.aircraft_slug}/`, {contract_slug :  params.contract_slug})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: CTR_SHORT_INFO,
          payload: response.data.data
        })
        let assetViewId = response.data.data
        trackActivity('Page Visited',{
          page_title: 'Invoice List',
          application_module: 'Contracts',
          asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
        })
        dispatch(invoiceListAc(params.contract_slug, {}));
        dispatch(invoiceWidgetAc(params.contract_slug));
      }
    });
  }
}
export const invoiceListAc = (contract_slug, queryParams) => {
  return (dispatch) => {
    if(Object.keys(queryParams).length){
      dispatch({
        type: actions.INVOICE_LOADER,
        payload: true
      });
    }
    globalGetService(`contracts/${contract_slug}/invoice/`, queryParams)
    .then(response => {
      dispatch({
        type: actions.INVOICE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.INVOICE_LIST,
          payload: response.data.data
        })
      }
    })
  }
}
export const invoiceViewAc = (params) => {
  return (dispatch) => {
    globalGetService('contracts/' + params.contract_slug + '/invoice/'+ params.id + '/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.INVOICE_VIEW_SUCCESS,
          payload: response.data.data
        })
        trackActivity('Page Visited',{
          page_title: 'Invoice View',
          application_module: 'Contracts',
          item_id: params.id,
          asset: response.data.data.invoice_number
        })
      }
    })
  }
}
export const deleteInvoiceViewAc = (params,invoiceId) => {
  return (dispatch) => {
    dispatch({
      type: actions.INVOICE_LOADER,
      payload: true
    });
    globalDeleteService('contracts/' +params.contract_slug + '/invoice/'+ invoiceId+ '/')
    .then(response => {
      dispatch({
        type: actions.INVOICE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        trackActivity('Item Deleted', {
          item_type: 'Invoice',
          item_id: invoiceId,
          application_module: 'Contracts',
          page_title: 'Invoice View'
        })
        toastFlashMessage(response.data.message, 'success');
        browserHistory.push('/' + params.type + '/' + params.aircraft_slug + '/contract/' + params.contract_slug + '/invoice/');
      }
    })
  }
}
export const invoiceEditAc = (params, data, assetViewId) => {
  return (dispatch) => {
    globalPutService('contracts/'+params.contract_slug+'/invoice/' + params.id + '/',data)
    .then(response => {

      if(response.data.statusCode == 200 ){
        browserHistory.push('/'+params.type+'/' + params.aircraft_slug + '/contract/' + params.contract_slug + '/invoice/view/' + params.id);
        dispatch({
          type: actions.INVOICE_EDIT_SUCCESS,
          payload: response.data.data
        });
        trackActivity('Item Edited', {
          page_title: `Invoice View`,
          application_module: `Contracts`,
          asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,
          contract_slug: params.contract_slug,
          item_type: `Invoice`,
          item_id: data.id
        })
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }
}
export const invoiceWidgetAc = (contract_slug) => {
  return (dispatch) => {
    globalGetService('contracts/' + contract_slug + '/invoices/widget/')
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.INVOICE_WIDGET,
          payload: response.data.data
        })
      }
    })
  }
}
export const changeInvoiceStatusAc = (params, data, contractSlug, invoiceId, type) => {
  return (dispatch) => {
    dispatch({
      type: actions.INVOICE_LOADER,
      payload: true
    });
    globalPostService('contracts/' + contractSlug + '/invoices/'+invoiceId+'/status/',data)
    .then(response => {
      dispatch({
        type: actions.INVOICE_LOADER,
        payload: false
      })
      if(response.data.statusCode == 200){
        trackActivity('Item Status Changed',{
          item_type: 'File',
          page_title: 'Invoice',
          application_module: 'Contracts',
          file_id: invoiceId,
          status: {
            1: 'Draft',
            2: 'Sent',
            3: 'Paid',
            4: 'Cancelled',
            5: 'Partially Paid'
          }[data.status]
        })
        toastFlashMessage(response.data.message, 'success');
        if(type == 'view'){
          dispatch({
            type: actions.INVOICE_VIEW_SUCCESS,
            payload: response.data.data
          });
        }else if(type== 'list'){
          dispatch(InvoiceAssetViewAc(params))
        }
        window.location.reload()
      }
    })
  }
}
export const detailInvExportAc = (params, ctrShortInfo, invoiceNumber, fileType, invoiceInfo) => {
  debugger
  return (dispatch) => {
    dispatch({
      type: actions.INVOICE_EXPORT_LOADER,
      payload: true
    });
    globalExportService('/contracts/'+ctrShortInfo.contract.slug+'/invoice/'+params.id+'/',{download: fileType})
    .then(response => {
      dispatch({
        type: actions.INVOICE_EXPORT_LOADER,
        payload: false
      });
      if(response && response.size > 0){
        getLocalStorageInfo().defaultLessor.id === 442 ?
        downloadFileType(response, `${ctrShortInfo?.contract?.lessor?.name} ${invoiceNumber} ${ctrShortInfo?.contract?.lessee?.name ||'_'} ${invoiceInfo?.rental_type == 1 ?`Rent ${invoiceInfo?.invoice_for_date ? moment(invoiceInfo?.invoice_for_date).format('MMM-YYYY') : ''}`:'' }.`, fileType)
        : downloadFileType(response, invoiceNumber+'_Invoice.', fileType)
        let assetInfo = ctrShortInfo;
        let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
        trackActivity('Report Exported',{
          page_title: 'Invoices',
          application_module: 'Contracts',
          asset: asset_serial_number,
          file_name: 'Invoice',
          file_type: fileType.toUpperCase(),
        })
      }
    });
  }
}
export const exportContractCashflowAc = (props, contract, fileType, queryFilter) => {
 let filterParam = {
   ...queryFilter,
   download : fileType
 }
  return (dispatch) => {
    dispatch({
      type: actions.INVOICE_LOADER,
      payload: true
    });
    globalExportService(`/contracts/${props.params.contract_slug}/invoice/`, filterParam )
    .then(response => {
      dispatch({
        type: actions.INVOICE_LOADER,
        payload: false
      });
      if(response && response.size > 0){
        if (contract.asset_type === 2){
          downloadFileType(response, 'ESN'+contract.esn+'_'+contract.contract.lessee.name+'_Invoice_List.', fileType)
        }else if(contract.asset_type === 3){
          downloadFileType(response, 'APU'+contract.serial_number+'_'+contract.contract.lessee.name+'_Invoice_List.', fileType)
        }else if(contract.asset_type === 4){
          downloadFileType(response, 'LG'+contract.serial_number+'_'+contract.contract.lessee.name+'_Invoice_List.', fileType)
        }else if(contract.asset_type === 5){
          downloadFileType(response, 'Propeller'+contract.serial_number+'_'+contract.contract.lessee.name+'_Invoice_List.', fileType)
        }else if(contract.asset_type === 1){
          downloadFileType(response, 'MSN'+contract.msn+'_'+contract.contract.lessee.name+'_Invoice_List.', fileType)
        }else if (contract.asset_type === 6) {
          downloadFileType(response, 'FAN' + contract.esn + '_' + contract.contract.lessee.name + '_Invoice_List.', fileType)
        }else if (contract.asset_type === 7) {
          downloadFileType(response, 'HPC' + contract.esn + '_' + contract.contract.lessee.name + '_Invoice_List.', fileType)
        }else if (contract.asset_type === 8) {
          downloadFileType(response, 'HPT' + contract.esn + '_' + contract.contract.lessee.name + '_Invoice_List.', fileType)
        }else if (contract.asset_type === 9) {
          downloadFileType(response, 'LPT' + contract.esn + '_' + contract.contract.lessee.name + '_Invoice_List.', fileType)
        }
        let assetInfo = contract;
        let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
        trackActivity('Report Exported',{
          page_title: 'Invoices',
          application_module: 'Contracts',
          asset: asset_serial_number,
          file_name: 'Invoice List',
          file_type: fileType.toUpperCase(),
        })
      }
    });
  }
}
export const getShareInvoiceInfoAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.INVOICE_LOADER,
      payload: true
    });
    globalGetService('contracts/'+params.contract_slug+'/invoices/'+params.id+'/initiate-mail/', {})
    .then(response => {
      dispatch({
        type: actions.INVOICE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.INVOICE_SHARE,
          payload: {flag: true, data: response.data.data}
        })
      }
    })
  }
}
export const updateShareInvFnAc = (params,data) => {
  return (dispatch) => {
    let postData = Object.assign({}, data);
    let emails = data.to_address.reduce((email, arr) =>  [...email, arr.value],[]);
    postData = {
      ...postData,
      to_address: emails
    }
    dispatch({
      type: actions.INVOICE_LOADER,
      payload: true
    });
    globalPostService('contracts/'+params.contract_slug+'/invoices/'+params.id+'/send-mail/', postData)
    .then(response => {
      dispatch({
        type: actions.INVOICE_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success')
        dispatch({
          type: actions.INVOICE_SHARE,
          payload: {
            flag: false,
            data: {}
          }
        })
        dispatch(invoiceViewAc(params))
      }
    })
  }
}
