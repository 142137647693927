import React from 'react';
import { Paper,Divider, Grid } from '@material-ui/core';
import { FilterComponent } from '../../../shared_elements';
import Skeleton from '@material-ui/lab/Skeleton';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { utilsGraphFilterOps } from '../'
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const UtilsMonthWiseGraph = ({ monthWiseGraphData, skeletonLoader, filter, getResponseBack, callExternalFunction }) => {
    let filterOptions = Object.assign({}, utilsGraphFilterOps);
    const options = {
        title: {
            text: monthWiseGraphData.start_year && monthWiseGraphData.end_year ? `Monthly Trend from ${monthWiseGraphData.start_year ? monthWiseGraphData.start_year : ''} to ${monthWiseGraphData.end_year ? monthWiseGraphData.end_year : ''}` : 'Monthly Trend',
            align: 'left',
            style: {
                fontFamily: 'Conv_IBMPlexSans-Regular',
                fontSize:'16px'
            }
        },
        subtitle: {
            text: `${monthWiseGraphData.title ? monthWiseGraphData.title : ''}`,
            align: 'left'
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Utilization'
            }
        },
        xAxis: {
            categories: monthWiseGraphData && monthWiseGraphData.annual_categories && monthWiseGraphData.annual_categories.x_axis && monthWiseGraphData.annual_categories.x_axis.categories
        },
        legend: {
            enabled: true
        },
        plotOptions:{
            series:{
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        callExternalFunction(event.point.series.name, event.point.category, 'basedOnMonthly');
                    }
                }
            }
        },
        series: monthWiseGraphData && monthWiseGraphData.utilization_annual,
        credits: false,
        exporting: {
            buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadPDF'],
                },
            }
         }
    }
    return (
        skeletonLoader ? <Skeleton variant="rect" height={440} width='100%' /> : 
        <Paper>
            <div style={{padding:'0 5px'}}>
                <FilterComponent 
                    filter={filter}
                    filterMenu={filterOptions}
                    getResponseBack={(applyFilter) => getResponseBack(applyFilter)}
                />
            </div>
            <Divider/>
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        </Paper>
    )
}
export default UtilsMonthWiseGraph
