import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router';
import { Row, Col } from 'reactstrap';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../applications/contracts/Elements';
import { checkString, checkNumber, checkValidDate } from '../../formValidator';
import moment from 'moment';
import {leaseStatus} from '../../shared/components';
import { getLocalStorageInfo, toastFlashMessage } from '../../utils'
import { ToolTipHover, InfoIcon } from '../../shared'
import PlacesAutocomplete from 'react-places-autocomplete';
import {geocodeByAddress,geocodeByPlaceId,getLatLng} from 'react-places-autocomplete';
import Select , { Creatable } from 'react-select';
import { uploadLesseeLogoAc } from '../../shared/actionCreators';
import { imgStoragePath } from '../../constants';
class AddEditPropeller extends Component {
  constructor(props){
    super(props);
    this.state = {
      apuSummery: {ownership_type: 1},
      error: {},
      newLessee:false
    }
    this.uploadLesseeLogoAc = uploadLesseeLogoAc.bind(this)
  }
  componentDidMount(){
    if(this.props.apuSummeryObj && Object.keys(this.props.apuSummeryObj).length){
      let data = {...this.props.apuSummeryObj}
      // delete data['lessor_name'];
      delete data['owner'];
      this.setState({
        apuSummery: {
          ...data,
          lessor_name_id: data.lessor_name && data.lessor_name.id  ? data.lessor_name.id:getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.id : '',
          lessor_level: data.lessor_name && data.lessor_name.lessor_level ? data.lessor_name.lessor_level:1,
          owner_id: this.props.apuSummeryObj.owner && this.props.apuSummeryObj.owner.id ? this.props.apuSummeryObj.owner.id : getLocalStorageInfo().defaultLessor ? getLocalStorageInfo().defaultLessor.id : '',
          owner_level: this.props.apuSummeryObj.owner && this.props.apuSummeryObj.owner.lessor_level? this.props.apuSummeryObj.owner.lessor_level : getLocalStorageInfo().defaultLessor ? 1 : '',
          lessee_id:data.lessee && data.lessee.id ? data.lessee.id : null,
          status: data.status ? data.status.value : null,
          region: data.region ? data.region.id : null,
          lessee: data.lessee ? data.lessee.id : null,
          operator:data.operator ? data.operator.id : null,
          off_lease_status: data.off_lease_status && data.off_lease_status.value ? data.off_lease_status.value : null
        }
      })
    }
  }
  updateForm = (type, value) => {
    if(type == "status" && value != 2){
      this.setState(prevState => ({
        ...prevState,
        apuSummery:{
          ...prevState.apuSummery,
          off_lease_status:null
        }
      }))
    }
    if(type == "lessor_name_id"){
      let data = this.props.ownerList.find(item => item.id == value)
      let error = this.state.error
      delete error.lessor_name_id
      this.setState(prevState =>({
        ...prevState,
        apuSummery: {
          ...prevState.apuSummery,
          lessor_name_id:data && data.id ? data.id : '',
          lessor_level:data && data.lessor_level ? data.lessor_level : '',
        },
        error: error
      }))
    }
    if(type == "owner_id"){
      let data= this.props.ownerList.find(item => item.id == value )
      this.setState(prevState => ({
        ...prevState,
        apuSummery:{
          ...prevState.apuSummery,
          owner_id:data && data.id ? data.id : '',
          owner_level:data && data.lessor_level ? data.lessor_level : ''
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        apuSummery: {
          ...prevState.apuSummery,
          [type] : value
        }
      }));
    }
  }
  updateLesseeForm = (value) => {
    if(value){
      if(typeof(value.id) == "string"){
        this.setState(prevState => ({
          ...prevState,
          apuSummery:{
            ...prevState.apuSummery,
            lessee_id:{
              name:value.name,
              logo:'',
              contact_address:'',
              country:''
            }
          },
          newLessee:true
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          apuSummery:{
            ...prevState.apuSummery,
            lessee_id:value.id
          },
          newLessee:false
        }))
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        apuSummery:{
          ...prevState.apuSummery,
          lessee_id:''
        },
        newLessee:false
      }))
    }
  }
  updateNewLessee = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      apuSummery:{
        ...prevState.apuSummery,
        lessee_id:{
          ...prevState.apuSummery.lessee_id,
          [type]:value
        }
      }
    }))
  }
  uploadLogo = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    this.uploadLesseeLogoAc(formData)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          apuSummery:{
            ...prevState.apuSummery,
            lessee_id:{
              ...prevState.apuSummery.lessee_id,
              logo:response.data.data.path
            }
          }
        }))
        toastFlashMessage(response.data.message, 'success')
      }else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
  }

  updateDetailData = (type, value) => {

  }

  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
      apuSummery: {
        ...prevState.apuSummery,
        location : address
      }
    }));
  };

  handleSelect = address => {
    let location =  geocodeByAddress(address);

    location.then(results =>
         getLatLng(results[0])
         )
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          apuSummery: {
            ...prevState.apuSummery,
            location : address,
            latitude: latLng['lat'],
            longitude: latLng['lng'],
          }
        })))
      .catch(error => console.error('Error', error));

      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
          this.setState(prevState => ({
            ...prevState,
              apuSummery: {
              ...prevState.apuSummery,
                country: place.length ? place[0]['long_name']:address
              }
            }))
        }

        )
        .catch(error => console.error('Error', error));

        location.then(results =>
          results[0]
          ).then(country => {
            let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
            this.setState(prevState => ({
              ...prevState,
                apuSummery: {
                ...prevState.apuSummery,
                  country_code: place.length ? place[0]['short_name']:address
                }
              }))
          }

          )
          .catch(error => console.error('Error', error));

    };

  addEditApuSummery = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.apuSummery);
    if(!data.location){
      data = {
        ...data,
        latitude: '',
        longitude: '',
        country: ''
      }
    }
    let validateNewInput = {
      part_number: checkString({value: this.state.apuSummery.part_number, required: true, minLength: '', maxLength: '', message: 'Please enter Part Number'}),
      serial_number: checkString({value: this.state.apuSummery.serial_number, required: true, minLength: '', maxLength: '', message: 'Please enter Serial Number'}),
      lessor_name_id: checkString({value: this.state.apuSummery.lessor_name, required: this.props.standAlone &&  parseInt(this.state.apuSummery.status) !== 4 ? true : false, minLength: '', maxLength: '', message: 'Please enter Lessor Name'}),
      date_of_manufacture: checkValidDate({value: this.state.apuSummery.date_of_manufacture, required: true, minLength: '', maxLength: '', message: 'Please enter Date of Manufacture'}),
      status: checkString({value: this.state.apuSummery.status, required: true, minLength: '', maxLength: '', message: 'Please select Lease Status'}),
      lessee_id:  checkString({value: this.state.apuSummery.lessee_id, required: this.state.apuSummery.status == 1 ? true : false, minLength: '', maxLength: '', message: 'Please select Lessee'}),
    };
    if(data.same_operator_lessee){
      data = {
        ...data,
        operator_id: data.lessee_id
      };
    }
    if(data.ownership_type == 2 && ( data.owner_portfolio == undefined || data.owner_portfolio == null || data.owner_portfolio == '' || data.owner_portfolio == undefined || data.owner_portfolio == null)){
      validateNewInput = {...validateNewInput, portfolio: 'Portfolio is required'}
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.toggleEditSideBar();
      let payload = Object.assign({}, this.state.apuSummery)
      if(this.state.apuSummery.off_lease_status == "" || this.state.apuSummery.off_lease_status == {}){
        payload = {
          ...payload,
          off_lease_status:null
        }
      }
      this.props.addEditApu(payload);
    }else{
      this.setState({
        error: validateNewInput
      });
    }

  }


  render() {
    const { apuSummery, error, newLessee } = this.state;
    const { manufacturerList, type, techConstants, operators, clients, sRegionList, ownerList } = this.props;
    const {jacLeaseStatus,defaultLeaseStatus,airAsiaLeaseStatus} = leaseStatus
    const userInfo = getLocalStorageInfo()
    var showTabs = true
    // Object.keys(userInfo.user.permission).map(key => {
    //   showTabs = userInfo.user.permission[key].is_saas ? false : showTabs
    //   return key
    // })
    return (
      <form onSubmit={(e) => this.addEditApuSummery(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
        <Row>
          <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
                Manufacturer
              </FieldLabel>
              <FieldInput
                value={apuSummery.manufacturer}
                type={type}
                keyParam="manufacturer"
                updateField={this.updateForm}
              />
            <h6 className="error-msg">{error.manufacturer}</h6>
            </FieldGroup>
          </FieldCol>
          <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Date of Manufacture <sup className="reqStar"> * </sup></FieldLabel>
              <FieldDate
                value={apuSummery.date_of_manufacture}
                type={type}
                keyParam="date_of_manufacture"
                maxDate={moment()}
                updateField={this.updateForm}
                readOnly={true}
              />
              <h6 className="error-msg">{error.date_of_manufacture}</h6>
            </FieldGroup>
          </FieldCol>
          {
            this.props.location.pathname.includes('technical-specification') ?
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Date of Purchase</FieldLabel>
                <FieldDate
                  value={apuSummery.purchase_date}
                  type={type}
                  keyParam="purchase_date"
                  maxDate={moment()}
                  minDate={moment(apuSummery.date_of_manufacture)}
                  updateField={this.updateForm}
                  readOnly={true}
                />
              <h6 className="error-msg">{error.purchase_date}</h6>
              </FieldGroup>
            </FieldCol> : null
          }
            <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
                Model
              </FieldLabel>
              <FieldInput
                value={apuSummery.model}
                type={type}
                keyParam="model"
                updateField={this.updateForm}
              />
            </FieldGroup>
          </FieldCol>
          <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">
                Serial Number
                { type != 'view' ? <sup className="reqStar"> * </sup>:null}
              </FieldLabel>
              <FieldInput
                value={apuSummery.serial_number}
                type={type}
                keyParam="serial_number"
                updateField={this.updateForm}
              />
            <h6 className="error-msg">{error.serial_number}</h6>
            </FieldGroup>
          </FieldCol>

          <FieldCol md="4">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Part Number
              { type != 'view' ? <sup className="reqStar"> * </sup>:null}
              </FieldLabel>
              <FieldInput
                value={apuSummery.part_number}
                type={type}
                keyParam="part_number"
                updateField={this.updateForm}
              />
            <h6 className="error-msg">{error.part_number}</h6>
            </FieldGroup>
          </FieldCol>
          {
            this.props.location.pathname.includes('technical-specification') &&  parseInt(apuSummery.status) !== 4 ?
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Lessor {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Lessor'
                  keyParam="lessor_name_id"
                  value={apuSummery.lessor_name_id}
                  type={type}
                  options = {ownerList}
                  updateField={this.updateForm}
                  labelKey='name'
                  valueKey='id'
                />
              <h6 className="error-msg">{error.lessor_name_id}</h6>
              </FieldGroup>
            </FieldCol> : null
          }
          {
            this.props.location.pathname.includes('technical-specification') &&  parseInt(apuSummery.status) !== 4 ?
            <FieldCol md="4">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Owner
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Owner'
                  keyParam="owner_id"
                  value={apuSummery.owner_id}
                  type={type}
                  options = {ownerList}
                  updateField={this.updateForm}
                  labelKey='name'
                  valueKey='id'
                />
                {/* <h6 className="error-msg">{error.owner}</h6> */}
              </FieldGroup>
            </FieldCol> : null
          }


          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Region of Operation
              </FieldLabel>
              <FieldSelect
                placeholder='Select region'
                keyParam="region"
                value={apuSummery.region}
                type={type}
                options={sRegionList}
                updateField={this.updateForm}
                labelKey='name'
                valueKey='id'
                name={apuSummery.region_label}
              />

            <h6 className="error-msg">{error.region}</h6>
            </FieldGroup>
          </FieldCol>
          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Location</FieldLabel>
                {
                  window.google !== undefined && window.google !== null ?
                  <PlacesAutocomplete
                    value={apuSummery.location}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                  >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{position: 'relative'}}>
                      <input
                        {...getInputProps({
                          placeholder: 'Search Location',
                          className: 'location-search-input',
                        })}
                      />
                      {
                        apuSummery.location ?
                        <div className="autocomplete-dropdown-container" style={{position: 'absolute', top: '38px', width: '100%', zIndex: 100}}>
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div> :  null
                      }

                    </div>
                  )}
                </PlacesAutocomplete>
                  :<FieldInput
                    value={apuSummery.location}
                    type={type}
                    keyParam="location"
                    updateField={this.updateForm}
                  />
                }
          </FieldGroup>
        </FieldCol>
        {
          showTabs ?
          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Ownership</FieldLabel>
              <div>
                <label className="label" style={{ cursor: 'pointer', marginRight: '10px'}}>
                   <input type="radio"
                    value={apuSummery.ownership_type}
                    onChange = {(e) => this.updateForm('ownership_type', 1)}
                    style={{ marginRight: '4px'}}
                    checked = {apuSummery.ownership_type === 1 ? true : false}
                   />
                 <span>Owned</span>
                </label>
                <label className="label" style={{ cursor: 'pointer'}}>
                   <input type="radio"
                    value={apuSummery.ownership_type}
                    onChange = {(e) => this.updateForm('ownership_type', 2)}
                    style={{ marginRight: '4px'}}
                    checked = {apuSummery.ownership_type === 2 ? true : false}
                   />
                 <span>Managed</span>
                </label>
              </div>
            </FieldGroup>
          </FieldCol>
          :null
        }
        {
          apuSummery.ownership_type == 2 ?
          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Portfolio { type != 'view' ? <sup className="reqStar"> * </sup>:null}</FieldLabel>
                <FieldSelect
                  placeholder='Select Portfolio'
                  keyParam="owner_portfolio"
                  value={apuSummery.owner_portfolio}
                  type={type}
                  options = {this.props.ownersList}
                  updateField={this.updateForm}
                  labelKey= 'name'
                  valueKey='id'
                />
              <h6 className="error-msg">{error.portfolio}</h6>
            </FieldGroup>
          </FieldCol>
          :null
        }
        <FieldCol md="12">
          <FieldGroup className="form-group">
            <FieldLabel className="label">
             Credit Classification
            </FieldLabel>
            <FieldSelect
              placeholder='Select Credit Classification'
              keyParam="credit_classification"
              value={apuSummery.credit_classification}
              type={type}
              options = {techConstants.filter(item => item.type == "credit_classification")}
              updateField={this.updateForm}
              labelKey= 'label'
              valueKey='value'
            />
            <h6 className="error-msg">{error.credit_classification}</h6>
          </FieldGroup>
        </FieldCol>
        <FieldCol md="12">
          <FieldGroup className="form-group">
              <FieldLabel className="label">
              Lease Status
              { type != 'view' ? <sup className="reqStar"> * </sup>:null}
              </FieldLabel>
              <FieldSelect
                placeholder='Select Lease Status'
                keyParam="status"
                value={apuSummery.status}
                type={type}
                options = {getLocalStorageInfo().defaultLessor.id === 348 ? airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 ? jacLeaseStatus : defaultLeaseStatus}
                updateField={this.updateForm}
                labelKey= 'label'
                valueKey='value'
              />
              <h6 className="error-msg">{error.status}</h6>
          </FieldGroup>
        </FieldCol>
        {
          apuSummery.status == 2 ?
          <FieldCol md="12">
            <FieldGroup className="form-group">
                <FieldLabel className="label">
                Off Lease Status
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Off Lease Status'
                  keyParam="off_lease_status"
                  value={apuSummery.off_lease_status}
                  type={type}
                  options = {techConstants.filter(item => item.type == "off_lease_status")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                />
            </FieldGroup>
          </FieldCol>: null
        }
          <div style={newLessee ? {width: '100%', background: '#f3f3f3', margin: '45px', paddingTop: '20px'} : {width: '100%'}}>
        <FieldCol md="12">
          <FieldGroup className="form-group">
            <FieldLabel className="label">Lessee
            { type != 'view' && apuSummery.status== 1 ? <sup className="reqStar"> * </sup>:null}
            </FieldLabel>
            <Creatable
              value={apuSummery.lessee_id}
              options={clients}
              isClearable={true}
              onChange={this.updateLesseeForm}
              className="custom-select-block"
              placeholder='Add or Select Lessee'
              valueKey="id"
              labelKey="name"
              type={type}
              disabled={apuSummery && apuSummery.status != 1 ? true : false}
              
            />
             <h6 className="error-msg">{error.lessee_id}</h6>
          </FieldGroup>
        </FieldCol>
        {
          newLessee ?
          <Fragment>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Contact Address
                </FieldLabel>
                <FieldInput
                  value={apuSummery.lessee_id ? apuSummery.lessee_id.contact_address:'' }
                  type={type}
                  keyParam="contact_address"
                  updateField={this.updateNewLessee}
                />
              <h6 className="error-msg">{error.lease_term}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Country
                </FieldLabel>
                <FieldInput
                  value={apuSummery.lessee_id ? apuSummery.lessee_id.country:'' }
                  type={type}
                  keyParam="country"
                  updateField={this.updateNewLessee}
                />
              <h6 className="error-msg">{error.lease_term}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label" id="upload-image" style={{position:'relative', cursor:'pointer'}}>
                  Upload Logo <img src={imgStoragePath+'phase-2/upload_template.png'} width={20} />
                  <input type="file" accept="image/*" onChange={(e)=>this.uploadLogo(e.target.files[0])} style={{position:'absolute', left:'0px', opacity:'0'}}/>
                </FieldLabel>
              <h6 className="error-msg">{error.lease_term}</h6>
              </FieldGroup>
            </FieldCol>
          </Fragment> : null
        }
      </div>
        <Col md="12">
          <div className="form-group">
           <label className="label" style={{ cursor: 'pointer'}}>
              <input type="checkbox"
               value={apuSummery.same_operator_lessee}
               onChange = {(e) => this.updateForm('same_operator_lessee', e.target.checked)}
               style={{ marginRight: '4px'}}
               checked = {apuSummery.same_operator_lessee}
              />
            <span>Operator is same as Lessee</span>
           </label>
          </div>
        </Col>
        {
          !apuSummery.same_operator_lessee?
          <FieldCol md="12">
            <FieldGroup className="form-group">
              <FieldLabel className="label">Operator</FieldLabel>
              <FieldSelect
                placeholder='Select Operator'
                keyParam="operator_id"
                value={apuSummery.operator_id}
                type={type}
                options={operators}
                updateField={this.updateForm}
                labelKey="name"
                valueKey="id"
              />
            </FieldGroup>
          </FieldCol> : null
        }
        </Row>
        </div>
        { type !== 'view' ?
        <div className="submit-block">
          <input type="submit" className="primary-btn" disabled={this.props.loader} value={this.props.loader ? "Processing..." : "Save Changes"}/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div> : null }
      </form>
    )
  }
}
export default withRouter(AddEditPropeller);
